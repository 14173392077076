const biography = {
    "id": -1,
    "title": "Enter your biography article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Biography", 'id': 'biography'},
        "language": "English",
        "styles": ["Informative", "Narrative", "Descriptive"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the subject and provide a brief overview of their life and achievements.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Early Life",
            "content": "Discuss the subject's early life, including their upbringing and any formative experiences.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Career",
            "content": "Chronicle the subject's professional achievements and major milestones.",
            "context": "",
            "min_words": 150,
            "max_words": 300,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Personal Life",
            "content": "Discuss the subject's personal life, relationships, and any significant events.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Legacy",
            "content": "Examine the subject's impact, legacy, and influence in their field or on society.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "6": {
            "id": "6.",
            "name": "Conclusion",
            "content": "Summarize the key points of the biography and offer a final reflection on the subject's life.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default biography;