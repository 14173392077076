import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { renderToString } from 'react-dom/server';
import './styles/singleBlogPage.css';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { ReactSVG } from 'react-svg';
import Garden_party from './resources/blog/Garden_party.svg';

const SingleBlogPage = () => {
  const location = useLocation();
  const font_title = '#291852';

  const { category, img, title, content, readingTime, author, date, categoryColor } = location.state;

  const backgroundColor = (category === 'Announcement') ? "#fcdfd9" : (category === 'How To') ? '#dbd9fc' : (category === 'Learn') ? "#9df9ef" : (category === 'Free Tools') ? "#C7ffb0" : "violet";
  const categoryColorr = (category === 'Announcement') ? "#e99887" : (category === 'How To') ? '#7473b0' : (category === 'Learn') ? "#015d92" : (category === 'Free Tools') ? "#03ad59" : "violet";

  const [isHovered, setHovered] = useState(false);
  const [imgSrc, setImgSrc] = useState('');

  const hoveredStyle = {
    filter: 'brightness(1.9)',
  };

  const notHoveredStyle = {
    filter: 'initial',
  };

  return (
    <div style={{ backgroundColor: backgroundColor, textAlign: 'center', paddingTop: '132px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <h1 style={{ fontSize: 'calc(27px + 0.7vw)', color: font_title }}>{title}</h1>
    <div style={{display: 'flex', flexDirection: "column", alignItems:'center'}}>
        <div style={{width: "100%"}}>
            <img src={img} style={{width:"50%"}} alt="dsa"></img>
        </div>
    </div>
      <h2 style={{color: categoryColorr, fontSize: 'calc(21px + 0.7vw)'}}>{category}</h2>
      <h3 style={{width: '80%', textAlign: 'left', color:font_title,}}>{content}</h3>
      <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%'}}>
      <HourglassEmptyIcon style={{filter: 'blur(0.45px)', color: "transparent", marginRight: '3px'}}/>
        <h3 style={{color: "#0bc3ba", textShadow: '2px 3px 1.5px rgba(0, 0, 0, 0.08)'}}>Reading Time: {readingTime} 
        </h3>
        {readingTime < 5 && <HourglassEmptyIcon style={{filter: 'blur(0.45px)', color: "#0a525c"}}/>}
        {readingTime >= 5 && readingTime <= 10 && <HourglassBottomIcon style={{filter: 'blur(0.45px)', color: "#0a525c"}}/>}
        {readingTime > 10 && <HourglassFullIcon style={{filter: 'blur(0.45px)', color: "#0a525c"}}/>}
      </div>
      <h4>{author}</h4>
      <h5>{date}</h5>
      <div  className='go-back-button'
            style={{ position: 'absolute',
            top: '370px',
            left: '30%',
            transform: 'scale(3)',
            cursor: 'pointer',
            color: font_title,
            borderRadius: '50%',
            overflow: 'hidden',
            transition: 'filter 0.4s ease-out', ...(isHovered ? hoveredStyle : notHoveredStyle) }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        onClick={() => {
            window.history.back(); // Go back action of the browser
        }}
        >
        <ReplyAllIcon />
        </div>
    </div>
  );
};

export default SingleBlogPage;