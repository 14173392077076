const blog_post = {
    "id": -1,
    "title": "Enter your blog post title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Blog Post", 'id': 'blog_post'},
        "language": "English",
        "styles": ["Conversational", "Narrative", "Informative"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Provide a brief overview of the topic and set the context for the rest of the blog post.",
            "context": "",
            "min_words": 75,
            "max_words": 150,
            "subpoints": {
                "1": {
                    "id": "1.1.",
                    "name": "Hook",
                    "content": "Start with an interesting statement, anecdote, or question to engage the reader.",
                    "context": "",
                    "min_words": 25,
                    "max_words": 50,
                    "subpoints": {}
                },
                "2": {
                    "id": "1.2.",
                    "name": "Background",
                    "content": "Give a brief background of the topic to help readers understand the context and set the stage for the blog post.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "2": {
            "id": "2.",
            "name": "Main Body",
            "content": "Discuss the key points, concepts, or arguments in detail.",
            "context": "",
            "min_words": 400,
            "max_words": 800,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Point 1",
                    "content": "Describe the first key point, concept, or argument.",
                    "context": "",
                    "min_words": 100,
                    "max_words": 200,
                    "subpoints": {}
                },
                "2": {
                    "id": "2.2.",
                    "name": "Point 2",
                    "content": "Describe the second key point, concept, or argument.",
                    "context": "",
                    "min_words": 100,
                    "max_words": 200,
                    "subpoints": {}
                },
                "3": {
                    "id": "2.3.",
                    "name": "Point 3",
                    "content": "Describe the third key point, concept, or argument.",
                    "context": "",
                    "min_words": 100,
                    "max_words": 200,
                    "subpoints": {}
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Conclusion",
            "content": "Summarize the key points discussed in the blog post and offer final thoughts, recommendations, or potential next steps.",
            "context": "",
            "min_words": 75,
            "max_words": 150,
            "subpoints": {
                "1": {
                    "id": "3.1.",
                    "name": "Summary",
                    "content": "Briefly recap the main points, concepts, or arguments presented in the blog post.",
                    "context": "",
                    "min_words": 40,
                    "max_words": 80
                },
                "2": {
                    "id": "3.2.",
                    "name": "Final Thoughts",
                    "content": "Provide recommendations, potential next steps, or concluding thoughts on the topic.",
                    "context": "",
                    "min_words": 35,
                    "max_words": 70
                }
            }
        },
        "4": {
            "id": "4.",
            "name": "Optional Sections",
            "content": "Include additional sections if needed, such as case studies, personal experiences, or resources.",
            "context": "",
            "min_words": 100,
            "max_words": 300,
            "subpoints": {
                "1": {
                    "id": "4.1.",
                    "name": "Case Study or Personal Experience",
                    "content": "Share a relevant case study or personal experience to support the key points.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 150,
                    "subpoints": {}
                },
                "2": {
                    "id": "4.2.",
                    "name": "Resources or References",
                    "content": "Provide a list of resources, references, or further reading for readers who want to learn more about the topic.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 150,
                    "subpoints": {}
                }
            }
        }
    }
}

export default blog_post;