const articleTypes = [
    {
        "type": "Blog Post",
        "id": "blog_post",
        "description": "An informal, regularly updated online journal or informational website displaying information in reverse chronological order.",
        "prompt_description": "Introduce the topic or theme, share personal insights or experiences, and engage with the audience.",
        "recommended_styles": ["Conversational", "Narrative", "Informative"]
    },
    {
        "type": "Social Media Post",
        "id": "social_media_post",
        "description": "A brief, engaging piece of content designed to be shared on social media platforms.",
        "prompt_description": "Create a concise and attention-grabbing message, include relevant hashtags, and use a conversational tone.",
        "recommended_styles": ["Conversational", "Informative", "Persuasive"]
    },
    {
        "type": "How-to Guide",
        "id": "how_to_guide",
        "description": "A step-by-step guide to help the reader complete a task or achieve a goal.",
        "prompt_description": "Describe the task or goal the reader wants to achieve, and provide a detailed outline of the steps required.",
        "recommended_styles": ["Informative", "Expository", "Conversational"]
    },
    {
        "type": "Listicle",
        "id": "listicle",
        "description": "A list-based article that presents information in an easily digestible format.",
        "prompt_description": "Provide a topic and a number of list items, then briefly describe each item.",
        "recommended_styles": ["Informative", "Conversational", "Narrative"]
    },
    {
        "type": "News Article",
        "id": "news_article",
        "description": "A report on a current event or recent development.",
        "prompt_description": "Introduce the event or development, provide background information, and include relevant quotes or expert opinions.",
        "recommended_styles": ["News reporting", "Informative", "Expository"]
    },
    {
        "type": "Opinion Piece",
        "id": "opinion_piece",
        "description": "An article that presents the author's viewpoint on a specific topic.",
        "prompt_description": "State the topic and the author's opinion, then present arguments and evidence to support the viewpoint.",
        "recommended_styles": ["Argumentative", "Persuasive", "Conversational"]
    },
    {
        "type": "Product Review",
        "id": "product_review",
        "description": "An evaluation of a product, detailing its features, benefits, and drawbacks.",
        "prompt_description": "Describe the product, its key features, and any pros and cons, and provide a final recommendation.",
        "recommended_styles": ["Informative", "Descriptive", "Analytical"]
    },
    {
        "type": "Interview",
        "id": "interview",
        "description": "A conversation with a notable individual, featuring their insights and opinions.",
        "prompt_description": "Introduce the interviewee and the topic, then present a series of questions and answers.",
        "recommended_styles": ["Interview style", "Conversational", "Narrative"]
    },
    {
        "type": "Profile",
        "id": "profile",
        "description": "An in-depth look at an individual, organization, or event.",
        "prompt_description": "Provide background information, relevant achievements, and interesting anecdotes about the subject.",
        "recommended_styles": ["Informative", "Narrative", "Descriptive"]
    },
    {
        "type": "Case Study",
        "id": "case_study",
        "description": "An in-depth analysis of a specific situation, often used to illustrate a broader concept.",
        "prompt_description": "Provide an overview of the situation, analyze the key factors, and explain the outcomes or lessons learned.",
        "recommended_styles": ["Analytical", "Informative", "Expository"]
    },
    {
        "type": "Editorial",
        "id": "editorial",
        "description": "An opinionated article that represents the views of an editor or publication.",
        "prompt_description": "Introduce the topic and the publication's stance, and provide arguments and evidence to support the viewpoint.",
        "recommended_styles": ["Persuasive", "Argumentative", "Conversational"]
    },
    {
        "type": "Travelogue",
        "id": "travelogue",
        "description": "A narrative account of a trip or journey, featuring personal experiences and insights.",
        "prompt_description": "Describe the destination, recount notable experiences or encounters, and share any personal insights or recommendations.",
        "recommended_styles": ["Narrative", "Descriptive", "Conversational"]
    },
    {
        "type": "Personal Essay",
        "id": "personal_essay",
        "description": "A reflective piece that explores the author's thoughts, feelings, and experiences.",
        "prompt_description": "Share a personal story, emotion, or experience, and describe the lessons learned or insights gained.",
        "recommended_styles": ["Narrative", "Confessional", "Conversational"]
    },
    {
        "type": "Book Review",
        "id": "book_review",
        "description": "An evaluation of a book, discussing its themes, strengths, and weaknesses.",
        "prompt_description": "Introduce the book and its author, summarize the plot or main ideas, and provide an analysis and recommendation.",
        "recommended_styles": ["Informative", "Analytical", "Conversational"]
    },
    {
        "type": "Film Review",
        "id": "film_review",
        "description": "A critique of a movie, discussing its plot, characters, and technical aspects.",
        "prompt_description": "Introduce the film and its creators, summarize the plot, and analyze the film's strengths and weaknesses.",
        "recommended_styles": ["Informative", "Analytical", "Descriptive"]
    },
    {
        "type": "Satire",
        "id": "satire",
        "description": "A humorous article that uses irony and exaggeration to critique a subject or issue.",
        "prompt_description": "Introduce the subject or issue, and use humor and irony to highlight its flaws or absurdities.",
        "recommended_styles": ["Satirical", "Humorous", "Conversational"]
    },
    {
        "type": "Historical Article",
        "id": "historical_article",
        "description": "An informative piece that explores a historical event or period.",
        "prompt_description": "Introduce the historical event or period, provide relevant context, and discuss its significance or impact.",
        "recommended_styles": ["Informative", "Narrative", "Expository"]
    },
    {
        "type": "Trend Analysis",
        "id": "trend_analysis",
        "description": "An examination of a current or emerging trend, often in a specific industry.",
        "prompt_description": "Introduce the trend, discuss its origins and implications, and predict its future impact.",
        "recommended_styles": ["Informative", "Analytical", "Expository"]
    },
    {
        "type": "Comparison",
        "id": "comparison",
        "description": "An article that compares and contrasts two or more subjects or concepts.",
        "recommended_styles": ["Informative", "Analytical", "Expository"]
    },
    {
        "type": "Biography",
        "id": "biography",
        "description": "A detailed account of a person's life, achievements, and legacy.",
        "prompt_description": "Introduce the subject, chronicle their life and accomplishments, and discuss their impact or legacy.",
        "recommended_styles": ["Informative", "Narrative", "Descriptive"]
    },
    {
        "type": "Tech Review",
        "id": "tech_review",
        "description": "An evaluation of a technological product or innovation, discussing its features, benefits, and potential drawbacks.",
        "prompt_description": "Introduce the technology, describe its key features and applications, and discuss any pros and cons.",
        "recommended_styles": ["Informative", "Descriptive", "Analytical"]
    }
]

export default articleTypes;