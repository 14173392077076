import React, {useEffect, useState} from "react";
import {Auth} from "@aws-amplify/auth";
import {Collapse, Box, Button, Divider, Drawer, List, ListItem, Typography} from "@mui/material";
import {styled} from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {Scrollbars} from "react-custom-scrollbars";
import axios from 'axios'; // Import axios for HTTP requests
import {CircularProgress} from '@mui/material'; // Import the Spinner for showing loading
import IconButton from '@mui/material/IconButton';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DropzoneArea from "./DropzoneArea";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ViewPdf from "./ViewPdf";
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import getDocument from "./integrations/getDocument";
import deleteDocument from "./integrations/deleteDocument";
import listArticles from "./integrations/listArticles";
import listPhraiserDocuments from "./integrations/listPhraiserDocuments";
import listMendeleyDocuments from "./integrations/listMendeleyDocuments";
import getMendeleyAccessToken from "./integrations/getMendeleyAccessToken";
import {API} from "aws-amplify";


const StyledScrollbars = styled(Scrollbars)({
    position: "relative",
    "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: 50, // You can adjust this value to increase/decrease the fade effect
        background: "linear-gradient(transparent, #fff)", // Adjust the color to match your background
        pointerEvents: "none",
    },
});

const DrawerContainer = styled(Drawer)({
    display: "flex",
    flexDirection: "column",
});

const ReferencesMenu = ({
                            references,
                            setReferences,
                            onUploadReferences,
                            loggedIn,
                            setLoggedIn,
                            mendeleyAccessToken,
                            setMendeleyAccessToken
                        }) => {
    const [phraiserDocuments, setPhraiserDocuments] = useState([]);
    const [mendeleyDocuments, setMendeleyDocuments] = useState([]);
    const [mendeleyView, setMendeleyView] = useState(false);

    const [selectedPhraiserReferences, setSelectedPhraiserReferences] = useState({});
    const [selectedMendeleyReferences, setSelectedMendeleyReferences] = useState({});
    const [open, setOpen] = useState(false); // State variable for opening and closing the Dialog// State variable for showing and hiding the spinner for each fil
    const [searchQuery, setSearchQuery] = useState('');  // define a new piece of state for the search query
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRefId, setCurrentRefId] = useState(null);
    const [currentRef, setCurrentRef] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [expandedGroup, setExpandedGroup] = useState(null);

    const onUploadComplete = () => {
        listPhraiserDocuments(setPhraiserDocuments);
    }


    useEffect(() => {
        if (loggedIn) {
            listPhraiserDocuments(setPhraiserDocuments);
            getMendeleyAccessToken(setMendeleyAccessToken);
        }
    }, [loggedIn]);

    useEffect(() => {
        if (mendeleyAccessToken) {
            listMendeleyDocuments(setMendeleyDocuments, mendeleyAccessToken);
        }
    }, [mendeleyAccessToken]);

    const handlePhraiserReferenceButtonClick = (referenceId) => {
        const newSelectedReferences = {...selectedPhraiserReferences};
        newSelectedReferences[referenceId] = !newSelectedReferences[referenceId];
        setSelectedPhraiserReferences(newSelectedReferences);

        const updatedReferences = phraiserDocuments.filter(ref => newSelectedReferences[ref.id]);
        setReferences(updatedReferences);
    };

    let singleClickTimer;

    const handleMendeleyReferenceButtonClick = (referenceId, group) => {
        // Clear the previous timer if it exists
        clearTimeout(singleClickTimer);

        // Set a new timer
        singleClickTimer = setTimeout(() => {
            const newSelectedReferences = {...selectedMendeleyReferences};
            newSelectedReferences[referenceId] = !newSelectedReferences[referenceId];
            setSelectedMendeleyReferences(newSelectedReferences);

            const updatedReferences = mendeleyDocuments[group].documents.filter(ref => newSelectedReferences[ref.id]);
            setReferences(updatedReferences);
        }, 200); // 200 milliseconds delay
    };

    const handleCheckboxDoubleClick = () => {
        // Clear the timer to ensure single click doesn't get triggered
        clearTimeout(singleClickTimer);

        const allChecked = Object.values(selectedMendeleyReferences).every(val => val);
        const newSelectedRefs = {};
        Object.keys(selectedMendeleyReferences).forEach(referenceId => {
            newSelectedRefs[referenceId] = !allChecked;
        });
        setSelectedMendeleyReferences(newSelectedRefs);
    };


    const handleSearchChange = (event) => {  // define the function that will handle the search input
        setSearchQuery(event.target.value);
    };

    const handleContextMenu = (event, referenceId) => {
        event.preventDefault();
        const selectedReference = phraiserDocuments.find(ref => ref.id === referenceId);
        setCurrentRefId(referenceId);
        setCurrentRef(selectedReference);
        setAnchorEl({
            vertical: 'top',
            horizontal: 'left',
            x: event.clientX,
            y: event.clientY
        });
        setMenuOpen(true); // Open the menu
    };


    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false); // Close the menu
    };


    const handleView = () => {
        setViewDialogOpen(true); // Open the view dialog
        handleClose();
    };

    const closeViewDialog = () => {
        setViewDialogOpen(false);
    };

    const handleDelete = () => {
        const performDelete = async () => {
            const result = await deleteDocument(currentRefId);
            const newPhraiserDocuments = phraiserDocuments.filter(ref => ref.id !== currentRefId);
            setPhraiserDocuments(newPhraiserDocuments);
            const newReferences = references.filter(ref => ref.id !== currentRefId);
            setReferences(newReferences);
        };
        performDelete();
        handleClose();
    };

    const handleMendeleyButton = () => {
        if (!mendeleyAccessToken) {
            const YOUR_CLIENT_ID = '16410';
            const YOUR_REDIRECT_URI = 'https://www.phraiser.io/mendeley-authorization-successful';
            const MENDELEY_AUTH_URL = `https://api.mendeley.com/oauth/authorize?client_id=${YOUR_CLIENT_ID}&redirect_uri=${YOUR_REDIRECT_URI}&response_type=code&scope=all`;
            window.location.href = MENDELEY_AUTH_URL;
        } else {
            setMendeleyView(prevState => !prevState);
        }
    }

    return (
        <DrawerContainer variant="permanent" anchor="right">
            <Box sx={{
                position: 'fixed',
                top: 0,
                right: 285,
                padding: 1,
                borderTop: 'none',
                borderBottom: '1px solid #e5e5e5',
                borderLeft: '1px solid #e5e5e5',
                borderRight: 'none',
                borderRadius: 0,      // Optional, adds rounded corners to the border
            }}>
                <Button
                    onClick={handleMendeleyButton}
                    sx={{
                        background: `url("/mendeley_logo.png") no-repeat center center`,
                        opacity: mendeleyView ? 1.0 : 0.5,
                        backgroundSize: 'contain',
                        pr: 2,
                        width: 32,
                        height: 32,
                        border: 'none',
                        '&:hover': {
                            background: `url("/mendeley_logo.png") no-repeat center center`,
                            backgroundSize: 'contain',
                            opacity: mendeleyView ? 0.5 : 1.0
                        }
                    }}
                />
            </Box>


            <Dialog open={viewDialogOpen} onClose={closeViewDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    {currentRef?.name}
                    <IconButton style={{position: 'absolute', right: '10px', top: '10px'}}
                                onClick={closeViewDialog}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ViewPdf documentId={currentRef?.id}/>
                </DialogContent>
            </Dialog>

            <List sx={{pt: 0, pb: 0, mb: -1, mt: 1, width: 285}}>
                <ListItem sx={{color: '#3f3f3f'}}>
                    <Typography variant="h5" sx={{pl: loggedIn ? 5 : 2.5}}>
                        Your references
                    </Typography>
                </ListItem>
                {loggedIn && !mendeleyView && (
                    <>
                        <ListItem sx={{color: '#3f3f3f', p: 1.5}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<AddIcon/>}
                                sx={{width: 255, borderRadius: 2}}
                                onClick={() => setOpen(true)} // Open the Dialog when the button is clicked
                            >
                                Upload new
                            </Button>

                            <Dialog open={open} onClose={() => setOpen(false)}>
                                <DialogContent>
                                    <DropzoneArea accept={true}
                                                  maxSize={10 * 1024 * 1024}
                                                  filesLimit={30}
                                                  onUploadComplete={onUploadComplete}
                                    />
                                </DialogContent>
                            </Dialog>
                        </ListItem>
                        <ListItem
                            sx={{color: '#3f3f3f', p: 1.5, mt: -1}}>   {/* add a new ListItem for the search box */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search references"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ListItem>
                    </>
                )}
                {!loggedIn && (
                    <ListItem sx={{color: '#3f3f3f', p: 1.5, pt: 50, pl: 2, width: 240, textAlign: 'center'}}>
                        <Typography variant="body1">
                            Sign in to access your references.
                        </Typography>
                    </ListItem>
                )}
            </List>

            {loggedIn && <StyledScrollbars style={{
                // maxHeight: 'calc(100% - 214px)',
                mt: 2,
                pr: -2
            }}>
                {mendeleyView && <>
                    <Box sx={{mt: 4, mb: 1}}>
                        <Typography variant="h7" sx={{pl: 2, fontWeight: 600}}>
                            GROUPS
                        </Typography>
                    </Box>

                    <List>
                        {[...mendeleyDocuments].map((group, index) => (
                            <div key={group.name}> {/* Use a div or fragment to wrap group items. */}
                                <ListItem button
                                          onClick={() => setExpandedGroup(group.name === expandedGroup ? null : group.name)}>
                                    <Typography variant="h8">
                                        {group.name}
                                    </Typography>
                                </ListItem>

                                <Collapse in={group.name === expandedGroup} timeout="auto" unmountOnExit>
                                    <List>
                                        {group.documents.map((document) => (
                                            <ListItem key={document.id} sx={{color: '#424248', pl: 1.5, pt: 0, pb: 1}}>
                                                <Button
                                                    variant="text"
                                                    color="inherit"
                                                    onClick={() => handleMendeleyReferenceButtonClick(document.id, index)}
                                                    onDoubleClick={handleCheckboxDoubleClick}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: "flex-start",
                                                        position: 'relative',
                                                        width: 270,
                                                        pl: 1.5,
                                                        textAlign: 'left',
                                                        transition: 'background-color 100ms ease-in-out',
                                                        backgroundColor: 'transparent',
                                                        '&::before': {
                                                            content: '""',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            width: '30%',
                                                            height: '100%',
                                                            pointerEvents: 'none',
                                                        },
                                                    }}
                                                    title={document.title}
                                                >
                                                    {selectedMendeleyReferences[document.id] ?
                                                        <CheckBoxIcon color="primary" sx={{width: 20, height: 20}}/> :
                                                        <CheckBoxOutlineBlankIcon sx={{width: 20, height: 20}}/>}
                                                    <IconButton/>
                                                    <Box
                                                        sx={{
                                                            flexGrow: 1,
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            ml: -1
                                                        }}
                                                    >
                                                        {document.title}
                                                    </Box>
                                                </Button>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        ))}
                    </List>

                </>}
                {!mendeleyView &&
                    <><List>
                        {[...phraiserDocuments]
                            .filter(reference => reference.name.toLowerCase().includes(searchQuery.toLowerCase()))
                            .reverse().map((reference) => (
                                <ListItem
                                    key={reference.id}
                                    sx={{color: '#424248', mb: -1, pl: 1.5, pt: 0, pb: 1}}
                                    onContextMenu={(event) => handleContextMenu(event, reference.id)}
                                >
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        onClick={() => handlePhraiserReferenceButtonClick(reference.id)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: "flex-start",
                                            position: 'relative',
                                            width: 270,
                                            pl: 1.5,
                                            textAlign: 'left', // Add this line to align text content to the left
                                            transition: 'background-color 100ms ease-in-out',
                                            backgroundColor: 'transparent',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                width: '30%',
                                                height: '100%',
                                                pointerEvents: 'none',
                                            },
                                        }}
                                        title={reference.name}
                                    >
                                        {selectedPhraiserReferences[reference.id] ?
                                            <CheckBoxIcon color="primary" sx={{width: 20, height: 20}}/> :
                                            <CheckBoxOutlineBlankIcon sx={{width: 20, height: 20}}/>}
                                        <IconButton/>


                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                // paddingRight: selectedArticle === article.id ? '24px' : '0px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                ml: -1
                                            }}
                                        >
                                            {reference.name}
                                        </Box>
                                    </Button>
                                </ListItem>
                            ))}
                    </List>
                        <Menu
                            keepMounted
                            open={menuOpen} // Use the state variable here
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                anchorEl
                                    ? {top: anchorEl.y, left: anchorEl.x}
                                    : undefined
                            }
                        >
                            <MenuItem onClick={handleView}
                                      sx={{fontSize: '0.875rem', minHeight: '30px'}}>View</MenuItem>
                            <MenuItem onClick={handleDelete}
                                      sx={{fontSize: '0.875rem', minHeight: '30px', color: '#b70303'}}>Delete</MenuItem>
                        </Menu>
                    </>
                }
            </StyledScrollbars>
            }
        </DrawerContainer>
    );
};

export default ReferencesMenu;
