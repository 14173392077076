import React from "react";
import phrAIser_logo2 from "../resources/layer2/phrAIser_logo2.svg";
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import phrAIser_mobile from "../resources/layer2/phrAIser_mobile.svg";

function Footer() {
    return (
    <div className="landing-page-footer-container">
                    <div className="thumbnail">
                        <div className="thumbnail-header">
                            <h2 className="last-header-white-control">Control all your content <br/> from just one
                                platform!
                            </h2>
                            <div className="button-div-get-started">
                                <Button
                                    component={Link}
                                    to="/app"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    sx={{
                                        width: 170,
                                        borderRadius: '25px',
                                        mr: 2,
                                        borderColor: 'white',
                                        color: 'white',
                                        display: 'flex',
                                        "&:hover": {
                                            backgroundColor: '#aa0d8a!important' // Change the background color when hovering over the button
                                        }
                                    }}
                                    className='get-started-butt landing-page-tryfree-btn-3'>
                                    <div className="get-started-text-container">
                                        Get Started
                                    </div>
                                    <div className="east-icon-container">
                                        <EastIcon className="east-icon"/>
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <div class="horizontal-line"></div>
                        <div className="ikonki-container-2">
                            <div className="ikonki-footer">
                                <a href="">
                                    <FacebookIcon className="ikonka-footer"/>
                                </a>
                                <a href="">
                                    <LinkedInIcon className="ikonka-footer"/>
                                </a>
                                <a href="">
                                    <YouTubeIcon className="ikonka-footer"/>
                                </a>
                            </div>
                        </div>
                        <div className="thumbnail-features">
                            <div className="thumbnail-logo-column">
                                <img src={phrAIser_logo2} alt="Company logo" className="footer-links" onClick={() => window.location.href = "/"}/>
                            </div>

                            <h4 className="FOOTER-MOBILE-PHRASE">WRITE PERFECT ORIGINAL CONTENT</h4>
                            <div className="thumbnail-logo-column-2">
                                <img src={phrAIser_mobile} alt="Company logo" className="phrAIser_mobile"/>
                            </div>
                            <div className="thumbnail-features-column">
                                <h3 className="footer-header footer-links" onClick={() => window.location.href = "/about"}>COMPANY</h3>
                                <ul>
                                    <li className="footer-links" onClick={() => window.location.href = "/features"}>About Us</li>
                                    <li>Become a Partner</li>
                                    <li>Referral Program</li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                            <div className="thumbnail-features-column">
                                <h3 className="footer-header footer-links" onClick={() => window.location.href = "/features"}>FEATURES</h3>
                                <ul>
                                    <li>Automizer</li>
                                    <li>Traffic Distribution AI</li>
                                    <li>Anti-Fraud Kit</li>
                                    <li>Mobile Notifications</li>
                                    <li>Collaboration Tools</li>
                                </ul>
                            </div>
                            <div className="thumbnail-features-column">
                                <h3 className="footer-header">RESOURCES</h3>
                                <ul>
                                    <li>Beginner Guide</li>
                                    <li>Video Tutorials</li>
                                    <li>Documentation</li>
                                    <li>Blog</li>
                                </ul>
                            </div>
                            <div className="thumbnail-features-column">
                                <h3 className="footer-header footer-links" onClick={() => window.location.href = "/support"}>SUPPORT</h3>
                            </div>
                        </div>
                        <div class="horizontal-line last-line"></div>
                        <div className="last-div-footer ">
                            <div className="copyrights thumbnail-logo-column">
                                <div>© Phraser.io 2023</div>
                            </div>
                            <div className="thumbnail-features-column-2">End User Privacy Policy</div>
                            <div className="thumbnail-features-column-2 footer-links" onClick={() => window.location.href = "/privacy"}>Privacy Policy</div>
                            <div className="thumbnail-features-column-2 footer-links" onClick={() => window.location.href = "/cookie-policy"}>Cookies policy</div>
                            <div className="thumbnail-features-column-2 footer-links" onClick={() => window.location.href = "/terms"}>Terms & Conditions</div>
                            <div className="thumbnail-features-column-2">DPA</div>
                            <div className="thumbnail-features-column-2">Security</div>
                            <div className="ikonki-footer">
                                <a href="">
                                    <FacebookIcon className="ikonka-footer"/>
                                </a>
                                <a href="">
                                    <LinkedInIcon className="ikonka-footer"/>
                                </a>
                                <a href="">
                                    <YouTubeIcon className="ikonka-footer" id="youtubek"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="copyrights-2">
                        <div>© Phraser.io 2023</div>
                    </div>
                </div>
    )};

export default Footer;