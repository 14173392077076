import React, {useEffect, useState} from 'react';
import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    Button,
    ButtonBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

import theme from './theme.js'
import {API, Auth, Hub} from "aws-amplify";
import {loadStripe} from "@stripe/stripe-js";
import backgroundImage from './resources/blob-scene-haikei.svg';
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as MyLogo} from "./phrAIser.io.svg";
import {CircularProgress} from '@mui/material';
import {Helmet} from "react-helmet";

const plans = [
    {
        type: 'Essential',
        id: 'essential_monthly',
        duration: 'Billed as one payment of $19 monthly',
        price: '$19',
        features: ['20,000 words / month', 'GPT-3.5 support', 'Unlimited projects', '30+ article templates']
    },
    {
        type: 'Essential',
        id: 'essential_yearly',
        duration: 'Billed as one payment of $171 yearly',
        price: '$14.25',
        features: ['20,000 words / month', 'GPT-3.5 support', 'Unlimited projects', '30+ article templates']
    },
    {
        type: 'Premium',
        id: 'premium_monthly',
        duration: 'Billed as one payment of $59 monthly',
        price: '$59',
        features: ['100,000 words / month', 'GPT-4 support', 'Unlimited projects', '30+ article templates']
    },
    {
        type: 'Premium',
        id: 'premium_yearly',
        duration: 'Billed as one payment of $531 yearly',
        price: '$44.25',
        features: ['100,000 words / month', 'GPT-4 support', 'Unlimited projects', '30+ article templates']
    },
];

const getBorderRadius = (index) => {
    switch (index) {
        case 0:
            return "16px 16px 0 0";
        case 1:
            return 0;
        case 2:
            return 0;
        case 3:
            return "0 0 16px 16px";
        default:
            return 0;
    }
};

const Upgrade = () => {
    const [hoveredPlanIndex, setHoveredPlanIndex] = useState(null);
    const [displayFeaturesIndex, setDisplayFeaturesIndex] = useState(null);
    const [hoverTimeout, setHoverTimeout] = useState(null);
    const [userSubscription, setUserSubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const isPremiumActive = () => {
        if (!userSubscription) return false;
        const {premium, premium_expiration_date} = userSubscription;
        const now = new Date();
        const expirationDate = new Date(premium_expiration_date);
        return premium && now <= expirationDate;
    };

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    // Redirect user to /app if signed in
                    navigate("/app");
                    break;
                case "signOut":
                    break;
                default:
                    break;
            }
        });

        Auth.currentAuthenticatedUser()
            .then(() => {
                // No action needed if the user is already signed in
            })
            .catch(() => {
                // Redirect to /signup if the user is not signed in
                navigate("/signup", {state: {locationFrom: location.pathname}});
            });

        return unsubscribe;
    }, [navigate]);


    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            const user = await Auth.currentAuthenticatedUser();
            const userId = user.username;
            const subscription = await API.post('phraiser', '/get-subscription', {
                body: {
                    user_id: userId,
                },
            });
            setUserSubscription(subscription);
        };

        fetchSubscriptionDetails();
    }, []);


    const handleMouseEnter = (index) => {
        setHoveredPlanIndex(index);
        const timeout = setTimeout(() => {
            setDisplayFeaturesIndex(index);
        }, 100);
        setHoverTimeout(timeout);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout);
        setHoveredPlanIndex(null);
        setDisplayFeaturesIndex(null);
    };

    const handleGetPremium = async (subscription_type) => {
        setIsLoading(true);

        try {
            const user = await Auth.currentAuthenticatedUser();
            const cognitoUserEmail = user.attributes.email;
            const cognitoUserId = user.username;
            console.log(cognitoUserId)

            const response = await API.post('phraiser', '/get-stripe-session', {
                body: {
                    user_email: cognitoUserEmail,
                    subscription_type: subscription_type
                },
            });

            // Load and initialize Stripe
            const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            const stripe = await stripePromise;

            // Update user attributes with session id

            const stripeSessionId = response.id;
            const response2 = await API.post('phraiser', '/save-session-id', {
                body: {
                    user_id: cognitoUserId,
                    stripe_session_id: stripeSessionId,
                    subscription_type: subscription_type
                },
            });

            // Redirect to Stripe Checkout
            await stripe.redirectToCheckout({
                sessionId: response.id
            });

            // setTransactionStatus('completed');

        } catch (error) {
            setIsLoading(false);
            console.error('Error calling Lambda function:', error);
            // setTransactionStatus('error');
        }
    };

    return (
        <ThemeProvider theme={theme} sd={{paddingBottom: '10vh'}}>
            <Helmet>
                <title>Unlock Advanced Features | Upgrade Your AI Writing Assistant App</title>
                <meta name="description"
                      content="Take your writing experience to new heights by upgrading your AI writing assistant app. Access advanced features like plagiarism detection, language translation, and personalized writing suggestions."/>
                <meta property="og:title"
                      content="Unlock Advanced Features | Upgrade Your AI Writing Assistant App"/>
                <meta property="og:description"
                      content="Take your writing experience to new heights by upgrading your AI writing assistant app. Access advanced features like plagiarism detection, language translation, and personalized writing suggestions."/>
            </Helmet>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
                background: `url(${backgroundImage}) no-repeat center center fixed`,
                backgroundSize: 'cover',
                overflowY: 'scroll',
                paddingBottom: {xs: 0, md: '8vh'},
            }}>
                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        ml: 2,
                        left: "calc(50% - min(620px, 50%))",
                    }}
                >
                    <Box component={Link} to="/" className="clickable-logo">
                        <MyLogo className="my-logo"/>
                    </Box>
                </Box>
                {isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%', // Adjust this value if needed
                        }}
                    >
                        <CircularProgress color="secondary"/>
                    </Box>
                ) : (
                    <Container sx={{
                        maxWidth: '100%',
                        mt: {
                            xs: 11,
                            md: 26,
                        },
                        width: 700
                    }}>
                        <Typography variant="h4" align="center" sx={{
                            mb: {
                                md: 2,
                                xs: 1.1
                            },
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: {
                                xs: '26px',
                                md: '2.125rem'
                            },
                        }}>
                            Choose Your Plan
                        </Typography>
                        <Typography variant="subtitle1" sx={{
                            textAlign: 'center',
                            fontSize: {xs: 10.5, md: 12.5},
                            mb: {
                                md: 3.5,
                                xs: 2.8
                            },
                            color: '#757575',
                            fontWeight: 'bold'
                        }}>
                            INDIVIDUAL PLANS
                        </Typography>
                        {plans.map((plan, index) => (
                            <ButtonBase
                                key={index}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleGetPremium(plan.id)}
                                sx={{
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                            >
                                <Paper
                                    sx={{
                                        padding: theme.spacing(2),
                                        textAlign: 'center',
                                        width: '100%',
                                        height:
                                            hoveredPlanIndex === index ? 130 : 65,
                                        p: 3.5,
                                        borderTop:
                                            index !== 0
                                                ? '1px solid rgba(0, 0, 0, 0.12)'
                                                : 'none',
                                        borderRadius: getBorderRadius(index),
                                        transition:
                                            'box-shadow 0.3s, height 0.3s',
                                        '&:hover': {
                                            boxShadow:
                                                '0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0,0, 0, 0.2)',
                                        },
                                    }}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item xs={5}>
                                            <Typography variant="h5" sx={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                fontSize: {sx: 20, md: '1.5rem'}
                                            }}>
                                                {plan.type}
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{
                                                textAlign: 'left',
                                                fontSize: {xs: 11, md: 14},
                                                color: 'grey'
                                            }}>
                                                {plan.duration}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5} style={{
                                            display: 'flex',
                                            justifyContent: {xs: 'flex-start', md: 'flex-end'},
                                            flexDirection: 'column',
                                            alignItems: {
                                                xs: 'center', md: 'right',
                                            },
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    marginLeft: {xs: 3, md: 'auto'},
                                                }}>
                                                    <Typography variant="body1" sx={{

                                                        fontWeight: 'bold',
                                                        fontSize: {xs: 17, md: 18},
                                                    }}>
                                                        {plan.price}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: 'grey',
                                                            fontWeight: '500',
                                                            fontSize: {xs: 11, md: 18},
                                                            ml: 0.1
                                                        }}>
                                                        /month
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {plan.duration.includes('yearly') && (
                                                <Box
                                                    sx={{
                                                        marginLeft: {xs: 6, md: 'auto'},
                                                        borderRadius: 1,
                                                        mt: 0.5,
                                                        mb: -3,
                                                        p: 0.5,
                                                        backgroundColor: '#eedfea',
                                                        color: 'secondary.main',
                                                        fontSize: {xs: 7, md: 13},
                                                        width: {xs: 40, md: 80}
                                                    }}
                                                >
                                                    SAVE 25%
                                                </Box>
                                            )}
                                        </Grid>
                                        <Grid item xs={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Button variant="contained" color="secondary"
                                                    sx={{marginRight: 0.1, fontWeight: 'bold'}}
                                            >

                                                Select
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {displayFeaturesIndex === index && (
                                        <Box
                                            sx={{
                                                opacity:
                                                    displayFeaturesIndex === index
                                                        ? 1
                                                        : 0,
                                                visibility:
                                                    displayFeaturesIndex === index
                                                        ? 'visible'
                                                        : 'hidden',
                                                transition:
                                                    'opacity 0.3s, visibility 0.3s',
                                            }}
                                        >
                                            <Grid container alignItems="center" justifyContent="center">
                                                <Grid item xs={6}>
                                                    <List>
                                                        {plan.features.slice(0, Math.ceil(plan.features.length / 2)).map((feature, idx) => (
                                                            <ListItem key={idx} sx={{py: 0.0}}>
                                                                <ListItemIcon sx={{minWidth: 'auto', mr: 1}}>
                                                                    <CheckIcon sx={{width: 28, height: 28}}
                                                                               color="primary"/>
                                                                </ListItemIcon>
                                                                <ListItemText primary={feature}
                                                                              primaryTypographyProps={{
                                                                                  color: '#686868',
                                                                                  textAlign: 'left',
                                                                                  fontSize: {xs: '10px', sm: '15px'},
                                                                              }}/>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <List>
                                                        {plan.features.slice(Math.ceil(plan.features.length / 2)).map((feature, idx) => (
                                                            <ListItem key={idx} sx={{py: 0.0}}>
                                                                <ListItemIcon sx={{minWidth: 'auto', mr: 1}}>
                                                                    <CheckIcon sx={{width: 28, height: 28}}
                                                                               color="primary"/>
                                                                </ListItemIcon>
                                                                <ListItemText primary={feature}
                                                                              primaryTypographyProps={{
                                                                                  color: '#686868',
                                                                                  textAlign: 'left',
                                                                                  fontSize: {xs: '10px', sm: '15px'}
                                                                              }}/>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Paper>
                            </ButtonBase>
                        ))}
                    </Container>)}
            </Box>
        </ThemeProvider>
    );
};

export default Upgrade;
