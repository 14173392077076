import React from 'react';
import {Container, Typography} from '@mui/material';

const TermsOfUse = () => (
    <Container maxWidth="sm" sx={{mt: 15}}>
        <Typography variant="h3" component="h1" gutterBottom>
            Terms of Use
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
            Effective Date: May 22, 2023
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
            By using the Phraiser.io AI writing assistant application ("Application"), you agree to these terms of use.
            If you do not agree, please do not use the Application.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            2. Use of the Application
        </Typography>
        <Typography variant="body1" gutterBottom>
            Phraiser.io grants you a limited, non-exclusive, non-transferable license to access and use the Application
            for your personal, non-commercial use.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            3. User Responsibilities
        </Typography>
        <Typography variant="body1" gutterBottom>
            You are responsible for any activity that occurs through your account. You agree not to use the Application
            for any illegal or unauthorized purposes.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            4. Intellectual Property
        </Typography>
        <Typography variant="body1" gutterBottom>
            The Application and its original content, features, and functionality are owned by Phraiser.io and are
            protected by international copyright, trademark, patent, trade secret, and other intellectual property
            rights laws.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            5. Changes to Terms of Use
        </Typography>
        <Typography variant="body1" gutterBottom>
            We may modify these terms of use at any time, and you agree to be bound by such modifications.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            6. Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
            For any queries regarding these Terms of Use, please contact us at support@phraiser.io.
        </Typography>
    </Container>
);

export default TermsOfUse;
