import React, {useState} from 'react';
import {
    Container,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
} from '@mui/material';
import {styled} from '@mui/system';

import ProfileSettings from './ProfileSettings';
import AccountSettings from './AccountSettings';
import BillingSubscription from "./BillingSubscription";
import BillingHistory from "./BillingHistory"

const ContainerStyled = styled(Container)({
    marginTop: 20,
});


const UserPanel = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <ContainerStyled maxWidth="md">
            <Box display="flex">
                <Paper sx={{marginRight: 2, height: 220, width: 200}}>
                    <List>
                        <ListItem button onClick={() => handleTabClick(0)} sx={{height: 50}}>
                            <ListItemText primary="Profile Settings"/>
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={() => handleTabClick(1)} sx={{height: 50}}>
                            <ListItemText primary="Account Settings"/>
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={() => handleTabClick(2)} sx={{height: 50}}>
                            <ListItemText primary="Billing & Subscription"/>
                        </ListItem>
                        <Divider/>
                        <ListItem button onClick={() => handleTabClick(3)} sx={{height: 50}}>
                            <ListItemText primary="Order History"/>
                        </ListItem>
                    </List>
                </Paper>
                <Paper sx={{flexGrow: 1, p: 3, width: 600, height: 400}}>
                    {activeTab === 0 && <Box>
                        <ProfileSettings/>
                    </Box>}
                    {activeTab === 1 && <AccountSettings/>}
                    {activeTab === 2 && <BillingSubscription/>}
                    {activeTab === 3 && <BillingHistory/>}
                </Paper>
            </Box>
        </ContainerStyled>
    );
};

export default UserPanel;
