import React from 'react';
import "./styles/about_us.css";
import KBpic from "./resources/company/KB_prof.jpg";
import WDpic from "./resources/company/Wojtek_prof.jpg";
import KKpic from "./resources/company/Karol_prof.jpg";

const AboutUs = () => {
    return (
        <div className='us-containers-container'>
            <div className="about-us-container">
                <div className="about-us-header">
                    <h1>About Us</h1>
                    <p>
                        We are a team of three passionate individuals committed to helping
                        businesses grow by implementing AI technologies. Our goal is to
                        create innovative solutions that allow businesses to thrive on a
                        much larger scale.
                    </p>
                </div>

                <div className="team-section">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="team-member">
                            <img src={member.image} alt={member.name} className='member_img'/>
                            <h3 className='member-name'>{member.name}</h3>
                            <p className='member-title'>{member.title}</p>
                            <p className='member-bio'>{member.bio}</p>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

const teamMembers = [

    {
        name: 'Wojtek Drężek',
        title: 'AI Engineer',
        image: WDpic,
        bio: 'Experienced data scientist and full-stack developer. Has delivered over 20 AI projects to various global startups and enterprises.',
    },
    {
        name: 'Kuba Brzeźny',
        title: 'Front-End Developer/QA Automation Engineer',
        image: KBpic,
        bio: 'Kuba is a QA automation  with a passion for apps development.',
    },
    {
        name: 'Karol Kowalski',
        title: 'Digital Marketing Manager',
        image: KKpic,
        bio: "An 8-years-experienced digital & e-commerce expert. Passionate about AI and machine learning and it's use in business as well as everyday life.",
    },
];

export default AboutUs;