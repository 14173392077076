import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    CircularProgress
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {API, Auth} from 'aws-amplify';

const DropzoneArea = ({accept, maxSize, filesLimit, onUploadComplete}) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState({});

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: (acceptedFiles) => {
            if (uploadedFiles.length + acceptedFiles.length <= filesLimit) {
                setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
                acceptedFiles.forEach(file => uploadFile(file));
            }
        },
        accept,
        maxSize,
    });

    const uploadFile = async (file) => {
        try {
            setIsLoading(prevState => ({...prevState, [file.name]: true}));
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                let base64 = reader.result.split(',')[1];
                const user = await Auth.currentAuthenticatedUser();
                const userId = user.username;
                const document_name = file.name;
                const response = await API.post('phraiser', '/upload-document', {
                    body: {
                        b64_document: base64,
                        document_name: document_name,
                        user_id: userId,
                    },
                });
                setIsLoading(prevState => ({...prevState, [file.name]: false}));
                onUploadComplete();
            };

        } catch (error) {
            console.error(error);
            setIsLoading(prevState => ({...prevState, [file.name]: false}));
        }
    }

    const fileList = uploadedFiles.map((file, index) => (
        <ListItem key={index}>
            <ListItemText primary={file.name} secondary={`${(file.size / 1024).toFixed(2)} KB`}/>
            <ListItemSecondaryAction>
                {isLoading[file.name] ? <CircularProgress size={24}/> :
                    <CheckIcon color="primary"/>
                }
            </ListItemSecondaryAction>
        </ListItem>
    ));

    return (
        <>
            <input {...getInputProps()} style={{display: 'none'}}/>
            <Box
                {...getRootProps({onClick: (e) => e.stopPropagation()})}
                sx={{
                    border: '2px dashed',
                    borderColor: isDragActive ? 'primary.main' : 'grey.500',
                    borderRadius: 1,
                    p: 2,
                    minHeight: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {isDragActive ? (
                    <Typography color="primary">Drop the files here...</Typography>
                ) : (
                    <Typography>Drag and drop some files here, or click to select files</Typography>
                )}
                <List>{fileList}</List>
            </Box>
        </>
    );
};

export default DropzoneArea;
