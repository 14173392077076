import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Grid, Paper, Typography } from '@mui/material';
import { BorderAllRounded, Search as SearchIcon } from '@mui/icons-material';
import BlogPost from './components/blog_post';
import DescriptionIcon from '@mui/icons-material/Description';
import ConstructionIcon from '@mui/icons-material/Construction';
import AssignmentIcon from '@mui/icons-material/Assignment';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import SchoolIcon from '@mui/icons-material/School';
import './styles/blog2.css';
import blogPostsContent from './resources/blog/blogPostsContent';
import Garden_party from './resources/blog/Garden_party.svg';
import { Box, ThemeProvider } from '@mui/material/styles';


const Navbar = ({ setActiveCategory }) => {
  function handleBlogClick(event, category) {
    const activeIcons = document.querySelectorAll('.active-icon');
    activeIcons.forEach(icon => {
      icon.classList.remove('active-icon');
    });

    const parentElement = event.currentTarget;
    const childElements = parentElement.querySelectorAll('*');
    
    childElements.forEach((child) => {
      child.classList.add('active-icon');
    });

    const recentlyClickedElements = document.querySelectorAll('.recently-clicked');

    recentlyClickedElements.forEach(element => {
      element.classList.remove('recently-clicked');
    });
    parentElement.classList.add('recently-clicked');

    setActiveCategory(category);
  }

  return (
    <AppBar position="static" >
      <Toolbar style={{backgroundColor:'#fff',
    color: "grey", cursor: "pointer", display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginRight: '0px', Flex: 1, width: '100%important', paddingLeft: '5%'}} className="blog-bar">
        <div style={{display: 'flex', flexDirection:'row', alignItems: 'stretch', gap: 'clamp(2px, 3.3vw, 6vw)'}}>
          <box className='nav-tab all-tab' onClick={(event) => handleBlogClick(event, 'All')} >
            <IconButton size="small" className='iconStyle icon-button' style={{pointerEvents: 'none!important',}}>
              <DescriptionIcon  />
            </IconButton >
            <span className='category-text' sx={{
                        zIndex: 999,
                        fontSize: { sm: '14px', md: '18px', lg: '16px' }}}>All</span>
          </box>
          <div className='nav-tab tools-tab' onClick={(event) => handleBlogClick(event, 'Free Tools')}>
            <IconButton className='iconStyle icon-button' size="small">
              <ConstructionIcon  />
            </IconButton>
            <span className='category-text'>Free Tools</span>
          </div>
          <div className='nav-tab announ-tab' onClick={(event) => handleBlogClick(event, 'Announcement')}>
            <IconButton className='icon-button' size="small">
              <AssignmentIcon className='iconStyle' />
            </IconButton>
            <span className='category-text'>Announcement</span>
          </div>
          <div className='nav-tab learn-tab' onClick={(event) => handleBlogClick(event, 'Learn')}>
            <IconButton size="small" className='icon-button'>
              <SchoolIcon className='iconStyle' />
            </IconButton>
            <span className='category-text'>Learn</span>
          </div>
          <div className='nav-tab howto-tab' onClick={(event) => handleBlogClick(event, 'How To')}>
            <IconButton className='icon-button' size="small">
              <OfflineBoltIcon className='iconStyle' />
            </IconButton >
            <span className='category-text'>How To</span>
          </div>
        </div>
        {/* Search Bar */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', Flex: '1' }}>
          <Paper className="searchbar-blog" component="form" style={{ minWidth: '300px', marginRight: '24%', borderRadius:'10px',}}>
            <IconButton className='icon-button search-icon'>
              <SearchIcon />
            </IconButton>
            <InputBase className="search-input category-text" placeholder="Search Blog" />
          </Paper>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const PostGrid = ({ activeCategory }) => {
  const posts = [
    <BlogPost category="Announcement" img={Garden_party} title="Great Opening" content={blogPostsContent['Quick Start Guide']} readingTime="2" author="Artur Kozminsky" date="May 13, 2023" className="blogPost"/>,
    <BlogPost category="How To" img={Garden_party} title="How to Use Phraiser" content={blogPostsContent['Quick Start Guide']} readingTime="6" author="Artur Kozminsky" date="May 11, 2023" className="blogPost"/>,
    <BlogPost category="Learn" img={Garden_party} title="Quick Start Guide" content={blogPostsContent['Quick Start Guide']} readingTime="8" author="Artur Kozminsky" date="May 11, 2023" className="blogPost"/>,
    <BlogPost category="Free Tools" img={Garden_party} title="Great Opening" content={blogPostsContent['Quick Start Guide']} readingTime="15" author="Artur Kozminsky" date="May 11, 2023" className="blogPost"/>,
    <BlogPost category="Learn" img={Garden_party} title="Great Opening" content={blogPostsContent['Quick Start Guide']} readingTime="4" author="Artur Kozminsky" date="May 11, 2023" className="blogPost"/>
  ];

  return (
    <Grid container spacing={2} style={{ width: '100%', padding: '40px',}}>
      {posts.filter((post) => activeCategory === 'All' || post.props.category === activeCategory)}
    </Grid>
  );
};

const Blog = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  
  return (
    <div className="blog2-page-container">
      <Navbar setActiveCategory={setActiveCategory} />
      <PostGrid activeCategory={activeCategory} />
    </div>
  );
};

export default Blog;