const personal_essay = {
    "id": -1,
    "title": "Enter your personal essay title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Personal Essay", 'id': 'personal_essay'},
        "language": "English",
        "styles": ["Narrative", "Confessional", "Conversational"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the personal story, emotion, or experience that will be explored in the essay.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Background",
            "content": "Provide relevant background information or context for the personal story, emotion, or experience.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Main Experience",
            "content": "Describe the main personal experience in detail, including thoughts and feelings.",
            "context": "",
            "min_words": 100,
            "max_words": 300,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Reflection",
            "content": "Reflect on the experience, analyzing its significance and the lessons learned or insights gained.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the personal essay, reiterating key points and offering any final thoughts.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default personal_essay;