export const addPoint = (article, setArticle) => {
    const highestPointId = Math.max(...Object.keys(article.points).map(id => parseInt(id)));

    const newPointId = highestPointId + 1;

    const newPoint = {
        "id": newPointId.toString() + ".",
        "name": "Point name",
        "content": "Content",
        "context": "",
        "min_words": 100,
        "max_words": 200,
        "subpoints": {}
    };

    // Create a copy of the article object
    const updatedArticle = JSON.parse(JSON.stringify(article));

    // Add the new point to the copy
    updatedArticle.points[newPointId.toString()] = newPoint;

    // Update the state using setArticle
    setArticle(updatedArticle);
};

export const addSubpoint = (article, setArticle, pointId, subpointId) => {
    if (typeof subpointId === "undefined") {
        const point = article.points[pointId];
        const id = Object.keys(point.subpoints).length + 1;
        point.subpoints[id] = {
            id: pointId + '.' + id + '.',
            name: `Point name`,
            "content": "Content",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            subpoints: {}
        };
    } else {

        const point = article.points[pointId].subpoints[subpointId];
        const id = Object.keys(point.subpoints).length + 1;

        point.subpoints[id] = {
            id: pointId + '.' + subpointId + '.' + id + '.',
            name: `Point name`,
            "content": "Content",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            subpoints: {}
        };
    }
    setArticle({...article, points: {...article.points}});
};

const reindexPoints = (article, setArticle, pointsObj, parentId = '') => {
    let newIndex = 1;
    const newPoints = {};
    Object.keys(pointsObj).forEach((pointId) => {
        const newPointId = `${parentId}${parentId ? '.' : ''}${newIndex}`;
        const newPoint = {...pointsObj[pointId]};

        // Reindex subpoints if they exist
        if (newPoint.subpoints) {
            newPoint.subpoints = reindexPoints(article, setArticle, newPoint.subpoints, newPointId);
        }

        newPoints[newIndex] = newPoint;
        newIndex++;
    });
    return newPoints;
};


export const removePoint = (article, setArticle, pointId, subpointId, subsubpointId) => {
    if (typeof subpointId === "undefined") {
        // Remove point
        delete article.points[pointId];
        const newPoints = reindexPoints(article, setArticle, article.points);
        setArticle({...article, points: newPoints});
    } else if (typeof subsubpointId === "undefined") {
        // Remove subpoint
        delete article.points[pointId].subpoints[subpointId];
        const newSubpoints = reindexPoints(article, setArticle, article.points[pointId].subpoints, pointId);
        setArticle({
            ...article,
            points: {
                ...article.points,
                [pointId]: {...article.points[pointId], subpoints: newSubpoints},
            },
        });
    } else {
        // Remove subsubpoint
        delete article.points[pointId].subpoints[subpointId].subpoints[subsubpointId];
        const newSubsubpoints = reindexPoints(
            article, setArticle, article.points[pointId].subpoints[subpointId].subpoints,
            `${pointId}.${subpointId}`
        );
        setArticle({
            ...article,
            points: {
                ...article.points,
                [pointId]: {
                    ...article.points[pointId],
                    subpoints: {
                        ...article.points[pointId].subpoints,
                        [subpointId]: {
                            ...article.points[pointId].subpoints[subpointId],
                            subpoints: newSubsubpoints,
                        },
                    },
                },
            },
        });
    }
};

const swapPoints = (points, id1, id2) => {
    const temp = points[id1];
    points[id1] = points[id2];
    points[id2] = temp;
};

const movePoint = (article, setArticle, pointId, parentIds, direction) => {
    const pointsList = parentIds.reduce((obj, id) => obj[id].subpoints, article.points);
    const idList = Object.keys(pointsList);
    const index = idList.indexOf(pointId);

    if (direction === 'up' && index > 0) {
        swapPoints(pointsList, idList[index], idList[index - 1]);
    } else if (direction === 'down' && index < idList.length - 1) {
        swapPoints(pointsList, idList[index], idList[index + 1]);
    } else {
        // Edge case: do nothing
        return;
    }

    // Reindex points after swapping
    const newPoints = reindexPoints(article, setArticle, pointsList, parentIds.join('.'));
    let updatedArticle = JSON.parse(JSON.stringify(article));
    parentIds.reduce((obj, id, idx) => {
        if (idx === parentIds.length - 1) {
            obj[id].subpoints = newPoints;
        } else {
            obj = obj[id].subpoints;
        }
        return obj;
    }, updatedArticle.points);

    setArticle(updatedArticle);
};

export const movePointUp = (article, setArticle, pointId, parentIds) => {
    movePoint(article, setArticle, pointId, parentIds, 'up');
};

export const movePointDown = (article, setArticle, pointId, parentIds) => {
    movePoint(article, setArticle, pointId, parentIds, 'down');
};
