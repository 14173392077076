import {
    Box, Button, Checkbox, FormControlLabel, Modal, Typography, Divider, CircularProgress,
    Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material";
import React, {useState, useEffect} from "react";
import theme from "../theme";
import {ThemeProvider} from '@mui/material/styles'
import update_article from "./integrations/update_article";
import ViewPdf from "./ViewPdf";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

const ReferencesWindow = ({
                              article,
                              setArticle,
                              references,
                              referencesWindowPointId,
                              setReferencesWindowPointId,
                              setUsageExceeded,
                              mendeleyAccessToken
                          }) => {
    if (!referencesWindowPointId) {
        return null
    }

    const [referencesWindowPoint, setReferencesWindowPoint] = useState(null);
    const [selectedReferences, setSelectedReferences] = useState({});
    const [waitingForUpdate, setWaitingForUpdate] = useState(false);
    const [selectedExtractedReferences, setSelectedExtractedReferences] = useState({});
    const [allExtractedRefsSelected, setAllExtractedRefsSelected] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [currentDoc, setCurrentDoc] = useState({id: null, pageNumber: null});
    const [wordCount, setWordCount] = useState(0);

    console.log('ref', references);
    useEffect(() => {
        let totalWordCount = 0;

        // Count words in selected references
        if (referencesWindowPoint && referencesWindowPoint.hasOwnProperty("extracted_references")) {
            referencesWindowPoint.extracted_references.forEach((reference, index) => {
                if (reference.selected) {
                    totalWordCount += reference.extracted_reference.split(' ').length;
                }
            });

            // Update the word count
            setWordCount(totalWordCount);
        }


    }, [selectedExtractedReferences, selectedReferences]);

    useEffect(() => {
        const ids = referencesWindowPointId.split('.');
        if (ids.length == 2) {
            setReferencesWindowPoint(article.points[ids[0]]);
        } else if (ids.length == 3) {
            setReferencesWindowPoint(article.points[ids[0]].subpoints[ids[1]]);
        } else if (ids.length == 4) {
            setReferencesWindowPoint(article.points[ids[0]].subpoints[ids[1]].subpoints[ids[2]]);
        } else {
            setReferencesWindowPoint(null);
        }
        console.log(referencesWindowPoint);
    }, [referencesWindowPointId, article]);

    useEffect(() => {
        if (referencesWindowPoint && referencesWindowPoint.hasOwnProperty("extracted_references")) {
            referencesWindowPoint.extracted_references.forEach((reference, index) => {
                reference.selected = reference.selected || false;
            });
        }
    }, [referencesWindowPoint]);

    useEffect(() => {
        const initialReferences = {};
        references.forEach(reference => {
            initialReferences[reference.id] = false;
        });
        setSelectedReferences(initialReferences);
    }, [references]);

    const closeReferencesModal = () => {
        setReferencesWindowPointId(null);
    };

    const handleCheckboxChange = (referenceId) => {
        setSelectedReferences(prevState => ({
            ...prevState,
            [referenceId]: !selectedReferences[referenceId],
        }));
    };

    const extractReferences = () => {
        setWaitingForUpdate(true);

        update_article(article,
            setArticle,
            true,
            [['extract_references', referencesWindowPointId]],
            setWaitingForUpdate,
            setUsageExceeded,
            mendeleyAccessToken);
    }

    const resetReferences = () => {
        delete referencesWindowPoint.extracted_references;
        setArticle({...article});
    }

    const doneReferences = () => {
        closeReferencesModal();
    }

    useEffect(() => {
        if (referencesWindowPoint) {
            referencesWindowPoint.reference_documents = Object.keys(selectedReferences).filter(key => selectedReferences[key]);
            setArticle({...article});
        }
    }, [selectedReferences]);

    const handleExtractedRefSelectionChange = (index, extracted_reference) => {
        const wordsInReference = extracted_reference.split(' ').length;

        setSelectedExtractedReferences(prevState => {
            const newState = {...prevState};

            // if the reference is already selected, we allow it to be deselected in all cases
            if (prevState[index]) {
                newState[index] = false;
                referencesWindowPoint.extracted_references[index].selected = false;
            }

            // if the reference is not selected, we check if the word count would exceed the limit
            else if (wordCount + wordsInReference <= 3000) {
                newState[index] = true;
                referencesWindowPoint.extracted_references[index].selected = true;
            }
            console.log(prevState);
            setArticle({...article});
            return newState;
        });
    };

    const handleAllExtractedRefsSelectionChange = () => {
        setAllExtractedRefsSelected(!allExtractedRefsSelected);
        const newSelectedRefsState = {};
        Object.keys(selectedExtractedReferences).forEach((key) => {
            newSelectedRefsState[key] = !allExtractedRefsSelected;
        });
        setSelectedExtractedReferences(newSelectedRefsState);
    };


    const handleView = (docId, pageNumber, documentName) => {
        setCurrentDoc({id: docId, pageNumber, documentName});
        setViewDialogOpen(true);
    };


    const closeViewDialog = () => {
        setViewDialogOpen(false);
    };

    const handleCheckboxDoubleClick = () => {
        const allChecked = Object.values(selectedReferences).every(val => val);

        const newSelectedRefs = {};
        references.forEach(reference => {
            newSelectedRefs[reference.id] = !allChecked;
        });
        setSelectedReferences(newSelectedRefs);
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={viewDialogOpen} onClose={closeViewDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    Document: {currentDoc.documentName} | Page: {currentDoc.pageNumber}
                    <IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={closeViewDialog}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ViewPdf documentId={currentDoc.id} pageNumber={currentDoc.pageNumber}/>
                </DialogContent>
            </Dialog>
            <Modal
                open={referencesWindowPointId !== null}
                onClose={closeReferencesModal}
                aria-labelledby="references-modal-title"
                aria-describedby="references-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 1000,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 3,
                    }}
                >
                    <Typography
                        id="references-modal-title"
                        variant="h6"
                    >
                        References for point{" "}
                        {referencesWindowPoint && referencesWindowPoint.id + " " + referencesWindowPoint.name}
                    </Typography>
                    <Box display="flex" style={{position: 'absolute', right: 80, top: 30}}>
                        <Typography>
                            total words
                        </Typography>
                        <Typography sx={{ml: 1}}>
                            {wordCount} / 3000
                        </Typography>

                    </Box>
                    <IconButton style={{position: 'absolute', right: 10, top: 10}}
                                onClick={closeReferencesModal}>
                        <CloseIcon/>
                    </IconButton>
                    <Divider sx={{mt: 2, mb: 2}}/>
                    {waitingForUpdate && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "15vh", // Adjust this value as needed
                            }}
                        >
                            <CircularProgress/>
                        </Box>
                    )}

                    {!waitingForUpdate && referencesWindowPoint && !referencesWindowPoint.hasOwnProperty("extracted_references") &&
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: 'auto',
                                maxHeight: '50vh'
                            }}
                        >

                            {references.map((reference) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedReferences[reference.id]}
                                            onChange={(event) => handleCheckboxChange(reference.id)}
                                            onDoubleClick={handleCheckboxDoubleClick}
                                        />
                                    }
                                    label={reference.title || reference.name}
                                    key={reference.id}
                                />
                            ))}
                        </Box>
                    }
                    {!waitingForUpdate && referencesWindowPoint && referencesWindowPoint.hasOwnProperty("extracted_references") &&
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: 'auto',
                                maxHeight: '50vh'
                            }}
                        >
                            <Collapse in={!waitingForUpdate}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={allExtractedRefsSelected}
                                                        onChange={handleAllExtractedRefsSelectionChange}
                                                        inputProps={{'aria-label': 'select all references'}}
                                                    />
                                                </TableCell>
                                                <TableCell>Text</TableCell>
                                                <TableCell>Page Number</TableCell>
                                                <TableCell>Document Name</TableCell>
                                                <TableCell>View page</TableCell>

                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {referencesWindowPoint.extracted_references.map((reference, index) => (
                                                <TableRow key={index}
                                                          onClick={() => handleExtractedRefSelectionChange(index, reference.extracted_reference)}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={referencesWindowPoint.extracted_references[index].selected}
                                                        />
                                                    </TableCell>

                                                    <TableCell
                                                        style={{minWidth: 400}}>{reference.extracted_reference}</TableCell>
                                                    <TableCell>{reference.page_number}</TableCell>
                                                    <TableCell>{reference.document_name}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            onClick={() => handleView(reference.document_id, reference.page_number, reference.document_name)}>
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Collapse>
                        </Box>
                    }

                    {!waitingForUpdate && referencesWindowPoint && !referencesWindowPoint.hasOwnProperty("extracted_references") &&
                        <Button
                            color="secondary"
                            size="large"
                            sx={{mt: 2}}
                            onClick={extractReferences}
                        >
                            Compile
                        </Button>
                    }

                    {!waitingForUpdate && referencesWindowPoint && referencesWindowPoint.hasOwnProperty("extracted_references") && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 2
                            }}
                        >
                            <Button
                                color="secondary"
                                size="large"
                                onClick={resetReferences}
                            >
                                Reset
                            </Button>

                            <Button
                                color="primary"
                                size="large"
                                onClick={doneReferences}
                            >
                                Done
                            </Button>
                        </Box>
                    )}

                </Box>
            </Modal>
        </ThemeProvider>

    );
};

export default ReferencesWindow;
