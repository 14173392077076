import {v4 as uuidv4} from 'uuid';
import default_article from './article_templates/default_article.js'
import blog_post from './article_templates/blog_post'
import book_review from './article_templates/book_review'
import case_study from './article_templates/case_study'
import editorial from './article_templates/editorial'
import film_review from './article_templates/film_review'
import historical_article from './article_templates/historical_article'
import how_to_guide from './article_templates/how_to_guide'
import interview from './article_templates/interview'
import listicle from './article_templates/listicle'
import news_article from './article_templates/news_article'
import opinion_piece from './article_templates/opinion_piece'
import personal_essay from './article_templates/personal_essay'
import product_review from './article_templates/product_review'
import profile from './article_templates/profile'
import scientific from './article_templates/scientific'
import satire from './article_templates/satire'
import social_media_post from './article_templates/social_media_post'
import trend_analysis from './article_templates/trend_analysis'
import tech_review from './article_templates/tech_review'
import biography from './article_templates/biography'
import comparison from './article_templates/comparison'
import travelogue from './article_templates/travelogue'

const templates = {
    'default_article': default_article,
    'scientific': scientific,
    'blog_post': blog_post,
    'book_review': book_review,
    'case_study': case_study,
    'editorial': editorial,
    'film_review': film_review,
    'historical_article': historical_article,
    'how_to_guide': how_to_guide,
    'interview': interview,
    'listicle': listicle,
    'news_article': news_article,
    'opinion_piece': opinion_piece,
    'personal_essay': personal_essay,
    'product_review': product_review,
    'profile': profile,
    'satire': satire,
    'social_media_post': social_media_post,
    'trend_analysis': trend_analysis,
    'tech_review': tech_review,
    'biography': biography,
    'comparison': comparison,
    'travelogue': travelogue
}

const assignRandomUUID = (template) => {
    return {...template, id: uuidv4()};
}

const getTemplate = (template_name) => {
    const template = templates[template_name];
    return template ? assignRandomUUID(template) : null;
}

export default getTemplate;
