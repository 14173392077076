import React, {useEffect, useState} from 'react';
import {Document, Page} from 'react-pdf';
import getDocument from "./integrations/getDocument";

const PdfView = ({documentId, pageNumber = null}) => {  // Deconstruct pageNumber prop here
    const [numPages, setNumPages] = useState(null);
    const [pdfBase64String, setPdfBase64String] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getDocument(documentId);
            setPdfBase64String(result);
        };

        fetchData();
    }, [documentId]);  // Only rerun the effect if documentId changes

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    if (pdfBase64String === null) {
        return <div>Loading...</div>;  // Show a loading message while we wait for the PDF
    }

    return (
        <div>
            <Document file={{data: atob(pdfBase64String)}} onLoadSuccess={onDocumentLoadSuccess}>
                {pageNumber ? (
                    <Page pageNumber={pageNumber}/>
                ) : (
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ),
                    )
                )}
            </Document>
        </div>
    );
}

export default PdfView;
