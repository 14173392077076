import React, {useState, useEffect} from 'react';
import {
    Box,
    TextField,
    Button,
} from '@mui/material';

import MinMaxWordsSelector from './selectors/MinMaxWordsSelector';
import update_article from './integrations/update_article';
import MagicPenLoader from './MagicPenLoader'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {addPoint, addSubpoint, removePoint, movePointUp, movePointDown} from './article_methods'
import ContextWindow from './ContextWindow'
import {API, Auth, Hub} from "aws-amplify";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ReferencesMenu from "./ReferencesMenu";
import ReferencesWindow from "./ReferencesWindow";

const Article = ({article, setArticle, scientificMode, loggedIn, references, mendeleyAccessToken}) => {
    const [waitingForUpdate, setWaitingForUpdate] = useState(false);
    const [editVisible, setEditVisible] = useState({});
    const [contextWindowPoint, setContextWindowPoint] = useState(null);
    const [referencesWindowPointId, setReferencesWindowPointId] = useState(null);
    const [usageExceeded, setUsageExceeded] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");


    const urlParams = new URLSearchParams(window.location.search);
    const articleId = urlParams.get('articleId');

    const handleMouseEnter = (pointId) => {
        setEditVisible({[pointId]: true});
    };

    const handleMouseLeave = (pointId) => {
        setEditVisible({});
    };


    const onTitleChange = (event) => {
        setArticle({...article, title: event.target.value});
    };

    const updateContentOfThePoint = (pointId) => {
        if (!loggedIn) {
            setSnackbarMessage("To generate content, kindly log in first.");
            setSnackbarOpen(true);
            return;
        }
        update_article(article,
            setArticle,
            true,
            [['write_point', pointId]],
            setWaitingForUpdate,
            setUsageExceeded)
        return;
    }

    const updateContentOfAllPoints = async () => {
        if (!loggedIn) {
            setSnackbarMessage("To generate content, kindly log in first.");
            setSnackbarOpen(true);
            return;
        }

        update_article(article,
            setArticle,
            true,
            [['write_all_points', '']],
            setWaitingForUpdate,
            setUsageExceeded)

    };

    const generateSynopsis = () => {
        if (!loggedIn) {
            setSnackbarMessage("To generate content, kindly log in first.");
            setSnackbarOpen(true);
            return;
        }
        update_article(article,
            setArticle,
            true,
            [['generate_synopsis_based_on_title', '']],
            setWaitingForUpdate,
            setUsageExceeded)
    };


    const writeFullArticle = async () => {
        if (!loggedIn) {
            setSnackbarMessage("To generate content, kindly log in first.");
            setSnackbarOpen(true);
            return;
        }
        update_article(article,
            setArticle,
            true,
            [['generate_full_article_based_on_title', '']],
            setWaitingForUpdate,
            setUsageExceeded)

    };


    const generateRandomTitle = () => {
        if (!loggedIn) {
            setSnackbarMessage("To generate content, kindly log in first.");
            setSnackbarOpen(true);
            setUsageExceeded(false);
            return;
        }
        update_article(article,
            setArticle,
            true,
            [['generate_random_title', '']],
            setWaitingForUpdate,
            setUsageExceeded)
    };

    const exportToDocx = () => {
        if (!loggedIn) {
            setSnackbarMessage("To export, kindly log in first.");
            setSnackbarOpen(true);
            return;
        }
        let base64Docx = article && article.docx_base64
        let title = article && article.title;
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64Docx}`;
        link.download = `${title}.docx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const uploadArticleToGdrive = async (accessToken) => {
        try {
            let base64Docx = article && article.docx_base64
            let fileName = article && article.title + '.docx';

            // Define the metadata for the file
            const metadata = {
                name: fileName,
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            };

            // Convert the base64 string to a Blob
            const byteCharacters = atob(base64Docx);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});

            // Create the form data to send in the request
            const formData = new FormData();
            formData.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
            formData.append('file', file);

            // Make the request to the Google Drive API to upload the file
            const uploadResponse = await axios.post(
                'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'multipart/related'
                    }
                }
            );

            // Open the file in Google Docs in a new tab
            const fileId = uploadResponse.data.id;
            window.open(`https://docs.google.com/document/d/${fileId}/edit`, '_blank');
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const getGdriveAccessToken = async (code) => {
        let userId = null;
        try {
            const user = await Auth.currentAuthenticatedUser();
            userId = user.username;
        } catch (error) {

        }

        const response = await API.post('phraiser', '/getGDriveAccessToken', {
            body: {
                user_id: userId,
                code: code,
                redirect_uri: "https://www.phraiser.io/app"
            },
        });
        window.close();
        // if (response.access_token) {
        //     uploadArticleToGdrive(response.access_token);
        // }
    }

    // Handle received code for gdrive export permission
    useEffect(() => {
        const code = urlParams.get('code');

        if (code) {
            getGdriveAccessToken(code);
        }
    }, []);

    const exportToGoogleDocs = async () => {
        if (!loggedIn) {
            setSnackbarMessage("To use export feature, kindly log in first.");
            setSnackbarOpen(true);
            return;
        }

        let userId = null;
        try {
            const user = await Auth.currentAuthenticatedUser();
            userId = user.username;
        } catch (error) {

        }
        const response = await API.post('phraiser', '/getGDriveAccessToken', {
            body: {
                user_id: userId
            },
        });
        if (response.access_token) {
            uploadArticleToGdrive(response.access_token);
        } else {
            // If there is no access_token in the database then ask for permissions and create a code
            const clientId = "1009242549028-lf97p92c473lnmrqgeu5dn46ah5mep5l.apps.googleusercontent.com";
            const redirectUri = "https://www.phraiser.io/app"
            const scope = "https://www.googleapis.com/auth/drive.file";

            // create the URL for Google's OAuth 2.0 server
            const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&access_type=offline&scope=${scope}`;

            // redirect the user
            // window.location.href = oauthUrl;
            window.open(oauthUrl, '_blank');
        }
    };


    useEffect(() => {
        if (usageExceeded) {
            setSnackbarMessage("You have exceed your allowed word usage.");
            setSnackbarOpen(true);
            setUsageExceeded(false);
        }

    }, [usageExceeded]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const renderPoints = (pointData, depth = 0, parentIds = []) => {
        return Object.keys(pointData).map((pointId) => {
            const point = pointData[pointId];
            const ids = [...parentIds, pointId];
            const pointKey = ids.join('.');

            const pointKeyStr = pointKey.toString();
            const pointIdStr = pointKeyStr + '.';


            const renderedPoint = (
                <>
                    <Box
                        id={pointKey}
                        key={pointKey}
                        sx={{
                            // mt: depth === 0 ? 2 : 1,
                            // ml: depth > 0 ? 2 * depth : 0,
                            width: 1000,
                            display: "flex",
                            alignItems: "center",
                            flexGrow: 1,
                            ml: -3
                        }}
                        onMouseEnter={() => handleMouseEnter(pointKeyStr)}
                        onMouseLeave={() => handleMouseLeave(pointKeyStr)}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%"
                            }}
                        >
                            <IconButton
                                variant="outlined"
                                size="small"
                                onClick={() => movePointUp(article, setArticle, pointId, parentIds)}
                                sx={{
                                    height: 30,
                                    borderRadius: 2,
                                    'opacity': editVisible[pointKeyStr] ? 1 : 0,
                                    transition: 'opacity 200ms'
                                }}
                            >
                                <ArrowUpwardIcon sx={{width: 20, height: 20}}/>
                            </IconButton>
                            <IconButton
                                variant='outlined'
                                size="small"
                                onClick={() => removePoint(article, setArticle, ...ids)}
                                sx={{
                                    height: 30,
                                    borderRadius: 2,
                                    'opacity': editVisible[pointKeyStr] ? 1 : 0,
                                    transition: 'opacity 200ms',
                                    mt: 1
                                }}
                            >
                                <DeleteIcon sx={{width: 22, height: 22}}/>
                            </IconButton>
                            <IconButton
                                variant="outlined"
                                size="small"
                                onClick={() => movePointDown(article, setArticle, pointId, parentIds)}
                                sx={{
                                    height: 30,
                                    borderRadius: 2,
                                    'opacity': editVisible[pointKeyStr] ? 1 : 0,
                                    transition: 'opacity 200ms',
                                    mt: 1
                                }}
                            >
                                <ArrowDownwardIcon sx={{width: 20, height: 20}}/>
                            </IconButton>
                        </Box>
                        <Box sx={{width: 700}}>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                pl: 1.7,
                                mb: -1.5
                            }}>

                                <Box component="span" sx={{fontSize: 19}}>{`${pointKey}.`}</Box>
                                <TextField
                                    multiline
                                    minRows={1}
                                    spellcheck="false"
                                    size="small"
                                    variant="outlined"
                                    value={point.name || "Point name"}
                                    onChange={(e) => {
                                        point.name = e.target.value;
                                        setArticle({...article});
                                    }}
                                    sx={{
                                        width: '100%',
                                        pr: 1.3,
                                        ...(editVisible[pointKeyStr] ? {
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: 19, // Add this line to adjust the font size
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                marginLeft: -1,
                                            }
                                        } : {
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: 19,
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: (theme) => theme.palette.primary.main,
                                                },
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                marginLeft: -1,
                                            }
                                        })

                                    }}
                                >
                                    {point.name}
                                </TextField>

                            </Box>
                            <TextField
                                multiline
                                minRows={2}
                                spellcheck="false"
                                margin="normal"
                                variant="outlined"
                                value={point.content || "Point content"}
                                onChange={(e) => {
                                    point.content = e.target.value;
                                    setArticle({...article});
                                }}
                                sx={{
                                    width: '100%',
                                    ml: 0.5,
                                    ...(editVisible[pointKeyStr] ? '& .MuiOutlinedInput-root' : {
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: (theme) => theme.palette.primary.main,
                                        },
                                    } : null)

                                }}
                            />
                            <Box display="flex">
                                <MinMaxWordsSelector pointIds={ids} point={point} article={article}
                                                     setArticle={setArticle}
                                                     editVisible={editVisible[pointKeyStr]}/>
                                <Button
                                    variant='outlined'
                                    size="small"
                                    color="primary"
                                    onClick={() => setReferencesWindowPointId(pointIdStr)}
                                    sx={{
                                        ml: 1,
                                        height: 25,
                                        borderRadius: 2,
                                        'opacity': editVisible[pointKeyStr] ? 1 : 0,
                                        transition: 'opacity 200ms'
                                    }}
                                >
                                    References
                                </Button>
                                <Button
                                    variant='outlined'
                                    size="small"
                                    color="secondary"
                                    onClick={() => setContextWindowPoint(point)}
                                    sx={{
                                        ml: 1,
                                        height: 25,
                                        borderRadius: 2,
                                        'opacity': editVisible[pointKeyStr] ? 1 : 0,
                                        transition: 'opacity 200ms'
                                    }}
                                >
                                    Context
                                </Button>

                                {depth < 2 && <Button
                                    variant='outlined'
                                    size="small"
                                    color="secondary"
                                    onClick={() => addSubpoint(article, setArticle, ...ids)}
                                    sx={{
                                        ml: 1,
                                        height: 25,
                                        borderRadius: 2,
                                        'opacity': editVisible[pointKeyStr] ? 1 : 0,
                                        transition: 'opacity 200ms'
                                    }}
                                >
                                    Add subpoint
                                </Button>}
                                <Button
                                    variant='contained'
                                    size="small"
                                    color="secondary"
                                    startIcon={<AutoFixHighIcon/>}
                                    onClick={() => updateContentOfThePoint(`${pointKey}.`)}
                                    sx={{
                                        height: 25,
                                        borderRadius: 2,
                                        ml: 1,
                                        'opacity': editVisible[pointKeyStr] ? 1 : 0,
                                        transition: 'opacity 200ms',
                                        width: 155
                                    }}
                                >
                                    <span>Write</span>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    {point.subpoints && renderPoints(point.subpoints, depth + 1, ids)}
                </>
            );

            return renderedPoint;
        });
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
            {waitingForUpdate && <MagicPenLoader/>}
            <ContextWindow article={article} setArticle={setArticle}
                           contextWindowPoint={contextWindowPoint} setContextWindowPoint={setContextWindowPoint}/>
            <ReferencesWindow article={article} setArticle={setArticle} references={references}
                              referencesWindowPointId={referencesWindowPointId}
                              setReferencesWindowPointId={setReferencesWindowPointId}
                              setUsageExceeded={setUsageExceeded}
                              mendeleyAccessToken={mendeleyAccessToken}
            />
            <Box
                onMouseEnter={() => handleMouseEnter('title')}
                onMouseLeave={() => handleMouseLeave('title')}
                sx={{width: '100%', mb: 1}}
            >

                <TextField
                    multiline
                    minRows={1}
                    spellcheck="false"
                    size="small"
                    variant="outlined"
                    value={article.title}
                    onChange={onTitleChange}
                    sx={{
                        ml: 1,
                        mt: 6,
                        width: '100%',
                        ...(editVisible['title'] ? {
                            '& .MuiOutlinedInput-root': {
                                fontSize: 22, // Add this line to adjust the font size
                            }
                        } : {
                            '& .MuiOutlinedInput-root': {
                                fontSize: 22,
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: (theme) => theme.palette.primary.main,
                                },
                            }
                        })
                    }}
                >
                    {article.title}
                </TextField>
                <Box
                    display="flex"
                    // justifyContent="space-between"
                    // alignItems="center"
                    sx={{mt: 1}}
                >
                    <Button sx={{
                        ml: 1,
                        borderRadius: 2,
                        'opacity': editVisible['title'] ? 1 : 0,
                        transition: 'opacity 200ms'
                    }}
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={generateSynopsis}>
                        Write synopsis
                    </Button>
                    <Button sx={{
                        ml: 1,
                        mr: 1,
                        borderRadius: 2,
                        'opacity': editVisible['title'] ? 1 : 0,
                        transition: 'opacity 200ms'
                    }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={writeFullArticle}>
                        Write full article
                    </Button>
                    {!scientificMode && <Button sx={{
                        ml: 0,
                        mr: 1,
                        borderRadius: 2,
                        'opacity': editVisible['title'] ? 1 : 0,
                        transition: 'opacity 200ms'
                    }}
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={generateRandomTitle}>
                        Generate random title
                    </Button>}
                </Box>
            </Box>

            <Box sx={{width: '100%', pl: 1}}>
                {renderPoints(article.points)}
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                ml: 5,
                pl: 1,
                mt: 10,
                mb: 15
            }}> {/* Updated this line */}
                <Button sx={{
                    height: 25,
                    borderRadius: 2
                }}
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => addPoint(article, setArticle)}
                >
                    Add point
                </Button>
                <Button sx={{
                    ml: 1,
                    height: 25,
                    borderRadius: 2
                }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={updateContentOfAllPoints}>
                    Write all points
                </Button>
                <Button sx={{
                    ml: 1,
                    height: 25,
                    borderRadius: 2
                }}
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={exportToDocx}>
                    Export to docx
                </Button>
                <Button sx={{
                    ml: 1,
                    height: 25,
                    borderRadius: 2
                }}
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={exportToGoogleDocs}>
                    Export to Google Docs
                </Button>
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{width: "100%"}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Article;
