const interview = {
    "id": -1,
    "title": "Enter your interview title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Interview", 'id': 'interview'},
        "language": "English",
        "styles": ["Interview style", "Conversational", "Narrative"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the interviewee and the topic of the interview.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Questions and Answers",
            "content": "Present a series of questions and the interviewee's responses.",
            "context": "",
            "min_words": 50,
            "max_words": 150,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Question 1",
                    "content": "Ask the first question.",
                    "context": "",
                    "min_words": 10,
                    "max_words": 50,
                    "subpoints": {
                        "1": {
                            "id": "2.1.1.",
                            "name": "Answer 1",
                            "content": "Provide the interviewee's response to the first question.",
                            "context": "",
                            "min_words": 50,
                            "max_words": 200,
                            "subpoints": {}
                        }
                    }
                },
                "2": {
                    "id": "2.2.",
                    "name": "Question 2",
                    "content": "Ask the second question.",
                    "context": "",
                    "min_words": 10,
                    "max_words": 50,
                    "subpoints": {
                        "1": {
                            "id": "2.2.1.",
                            "name": "Answer 2",
                            "content": "Provide the interviewee's response to the second question.",
                            "context": "",
                            "min_words": 50,
                            "max_words": 200,
                            "subpoints": {}
                        }
                    }
                },
                "3": {
                    "id": "2.3.",
                    "name": "Question 3",
                    "content": "Ask the third question.",
                    "context": "",
                    "min_words": 10,
                    "max_words": 50,
                    "subpoints": {
                        "1": {
                            "id": "2.3.1.",
                            "name": "Answer 3",
                            "content": "Provide the interviewee's response to the third question.",
                            "context": "",
                            "min_words": 50,
                            "max_words": 200,
                            "subpoints": {}
                        }
                    }
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Conclusion",
            "content": "Summarize the main insights and opinions shared by the interviewee.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default interview;