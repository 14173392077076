import {API, Auth} from 'aws-amplify';

const getMendeleyAccessToken = async (setMendeleyAccessToken) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.post('phraiser', '/get-mendeley-access-token', {
            body: {
                user_id: userId
            },
        });
        setMendeleyAccessToken(response.access_token);
    } catch (error) {
        console.error('Error getting mendeley access token:', error);
    }
};

export default getMendeleyAccessToken;
