import {Link, Outlet, useNavigate, useLocation} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import {ReactComponent as MyLogo} from "./phrAIser.io.svg";
import Footer from "./components/footer";
import Menu_icon from './resources/icons/menu-icon.svg'
import quill_icon from './resources/quill.png'
import {Auth} from "@aws-amplify/auth";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';


const Layout = () => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setDrawerOpen(false);
    };


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 844) {
                setDrawerOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const menuItems = [
        {text: "Features", path: "/features"},
        {text: "Company", path: "/about"},
        {text: "Support", path: "/support"},
        {text: "Blog", path: "/blog"},
        {text: "Pricing", path: "/pricing"},
    ];


    const [loggedIn, setLoggedIn] = useState(false);

    const checkAuthStatus = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setLoggedIn(true);
        } catch (error) {
            console.log(error);
            setLoggedIn(false);
        }
    };

    useEffect(() => {
        checkAuthStatus();
    }, []);

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            setLoggedIn(false);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 844) {
                setIsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <link href='https://fonts.googleapis.com/css?family=Righteous' rel='stylesheet'/>
            <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'/>
            <CssBaseline/>
            <div className="landing-page-header-mobile-container" style={{height: "82px", width: "100%"}}>
                <div className="landing-page-header-mobile">
                    <header
                        className="landing-page-header-container"
                        style={{
                            zIndex: '2999',
                            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
                            width: '100vw',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingLeft: '7vw',
                            paddingRight: '7vw',
                        }}
                    >
                        <div className="logos-container-container" style={{boxShadow: 'none'}}>
                            <div className="landing-page-logo-container"
                                 style={{display: "flex", alignItems: "center", boxShadow: 'none'}}>
                                <Box component={Link} to="/" className="clickable-logo" sx={{boxShadow: 'none'}}>
                                    <MyLogo className="my-logo"/>
                                </Box>
                            </div>
                        </div>
                        <div className="menu-button-container" style={{
                            display: 'flex',
                            position: 'relative',
                            width: '60px',
                            height: '60px',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <div style={{display: 'flex', position: 'relative', width: '100%', height: '100%'}}
                                 edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                                <img src={Menu_icon}
                                     style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain'}}/>
                            </div>
                        </div>
                    </header>
                </div>
                <Drawer
                    anchor="center"
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                    style={{zIndex: '1800'}}
                    PaperProps={{
                        style: {
                            paddingTop: '82px',
                            width: '100vw',
                            boxSizing: 'border-box',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: drawerOpen ? 'translateY(0%)' : 'translateY(-100%)',
                            textAlign: 'center!important',
                        },
                    }}
                >
                    <Box
                        component="ul"
                        style={{
                            paddingInlineStart: '0px',
                            paddingTop: '52px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100% - 82px)',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            boxSizing: 'border-box',
                            translate: 'translateX(-50%), translateY(-50%)',

                            borderRadius: '15px'
                        }}
                    >
                        {menuItems.map((item, index) => (
                            <ListItem button key={index} style={{
                                borderRadius: '15px',
                                paddingLeft: '20vw',
                                paddingRight: '20vw',
                                paddingTop: '10%',
                                paddingBottom: '10%'
                            }} onClick={() => handleNavigation(item.path)}>
                                <ListItemText primary={item.text} style={{textAlign: 'center',}}/>
                            </ListItem>
                        ))}
                    </Box>
                </Drawer>
            </div>
            <div className="landing-page-header">
                <header className="landing-page-header-container"
                        style={{boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)'}}>
                    {/*<div className="logos-container-container">*/}
                    {/*    <div*/}
                    {/*        className="landing-page-logo-container"*/}
                    {/*        style={{display: "flex", alignItems: "center"}}*/}
                    {/*    >*/}
                    {/*        <Box component={Link}*/}
                    {/*             to="/" className="clickable-logo">*/}
                    {/*            <MyLogo className="my-logo"/>*/}
                    {/*        </Box>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <font style={{
                        'font-family': 'Righteous',
                        'font-size': 32,
                        color: '#44444f'
                    }}>phr</font>
                    <font style={{
                        'font-family': 'Righteous',
                        'font-size': 32,
                        color: '#429bcb'
                    }}>ai</font>
                    <font style={{
                        'font-family': 'Righteous',
                        'font-size': 32,
                        color: '#44444f'
                    }}>ser.io</font>
                    <button className="landing-page-menu-btn"
                            onClick={() => window.location.href = "/features"}>Features
                    </button>
                    <button className="landing-page-menu-btn" onClick={() => window.location.href = "/about"}>Company
                    </button>
                    <button className="landing-page-menu-btn"
                            onClick={() => window.location.href = "/support"}>Support
                    </button>
                    <button className="landing-page-menu-btn blog-btn"
                            onClick={() => window.location.href = "/blog"}>Blog
                    </button>
                    <button className="landing-page-menu-btn pricing"
                            onClick={() => window.location.href = "/pricing"}>Pricing
                    </button>
                    <div className="landing-page-button-container">
                        {loggedIn ? (
                            <Button
                                component={Link}
                                to="/app"
                                variant="outlined"
                                color="secondary"
                                size="large"
                                sx={{
                                    width: 170,
                                    borderRadius: '25px',
                                    mr: 2,
                                    fontSize: 'calc(15 / 1440 * 100vw)',
                                }}
                                className="first-signup-button"
                            >
                                Go To App
                            </Button>
                        ) : (
                            <Button
                                component={Link}
                                to="/login"
                                variant="outlined"
                                color="secondary"
                                size="large"
                                sx={{
                                    width: 170,
                                    borderRadius: '25px',
                                    mr: 2,
                                    fontSize: 'calc(15 / 1440 * 100vw)',
                                }}
                                className="first-tryfree-button"
                            >
                                Log In
                            </Button>
                        )}
                        {loggedIn ? (
                            <Button
                                component={Link}
                                to="/"
                                variant="contained"
                                color="secondary"
                                size="large"
                                sx={{
                                    width: 170,
                                    borderRadius: '25px',
                                    backgroundColor: '#CB10A6',
                                    fontSize: 'calc(15 / 1440 * 100vw)',
                                    "&:hover": {
                                        backgroundColor: '#aa0d8a',
                                    },
                                }}
                                onClick={handleLogout}
                                className="first-signup-button"
                            >
                                Log Out
                            </Button>
                        ) : (
                            <Button
                                component={Link}
                                to="/signup"
                                variant="contained"
                                color="secondary"
                                size="large"
                                sx={{
                                    width: 170,
                                    borderRadius: '25px',
                                    backgroundColor: '#CB10A6',
                                    fontSize: 'calc(15 / 1440 * 100vw)',
                                    "&:hover": {
                                        backgroundColor: '#aa0d8a',
                                    },
                                }}
                                className="first-signup-button"
                            >
                                Sign Up
                            </Button>
                        )}

                    </div>
                </header>
            </div>
            <Outlet/>
            <Footer/>
        </>
    )
};

export default Layout;
