import React from "react";
import {FormControl, InputLabel, MenuItem, Select, Tooltip,} from "@mui/material";
import articleTypes from "../config/articleTypes";

const ArticleTypeSelect = ({articleType, setArticleType}) => {

    const handleChange = (e) => {
        const selectedType = e.target.value;
        if (selectedType == 'Scientific') {
            setArticleType({
                "type": "Scientific",
                "id": "scientific",
                "description": "An article that compares and contrasts two or more subjects or concepts.",
                "recommended_styles": []
            });
        } else {
            const selectedArticleType = articleTypes.find(
                (articleTypeItem) => articleTypeItem.type === selectedType
            );
            setArticleType(selectedArticleType);
        }

    };

    return (
        <>
            <FormControl
                variant="outlined"
                size="small"
                sx={{
                    minWidth: 200,
                    mt: 0,
                }}
            >
                <InputLabel htmlFor="article-type-select">Article Type</InputLabel>
                <Select
                    label="Article Type"
                    value={articleType.type} // Update this line
                    onChange={handleChange} // Update this line
                    inputProps={{
                        name: "articleType",
                        id: "article-type-select",
                    }}
                >
                    <MenuItem
                        key={'Scientific'}
                        value={'Scientific'}
                    >
                        <Tooltip
                            title={'A peer-reviewed document that presents original research and its results, contributing to the collective knowledge in a specific scientific field.'}
                            placement="right"
                            arrow
                            enterDelay={300}
                            leaveDelay={0}
                        >
                            <span>{'Scientific'}</span>
                        </Tooltip>
                    </MenuItem>
                    {articleTypes.map((articleTypeItem) => (
                        <MenuItem
                            key={articleTypeItem.type}
                            value={articleTypeItem.type} // Update this line
                        >
                            <Tooltip
                                title={articleTypeItem.description}
                                placement="right"
                                arrow
                                enterDelay={300}
                                leaveDelay={0}
                            >
                                <span>{articleTypeItem.type}</span>
                            </Tooltip>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default ArticleTypeSelect;
