import {API, Auth} from 'aws-amplify';

const saveArticle = async (article) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        console.log(article);
        const response = await API.post('phraiser', '/save-article', {
            body: {
                'userId': userId,
                'article': article
            },
        });

        if (!response) {
            throw new Error('Failed to update article');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export default saveArticle;