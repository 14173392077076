import React from "react";
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';

const Violet_box_stworek = () => {
    return (
        <div className="violet-gradient-padding-container">
            <div className="violet-gradient-container">
                <div className="div-playscreen">
                    <img src={process.env.PUBLIC_URL + '/playscreen.png'} alt="Playscreen" className="playscreen"/>
                </div>
                <div className="violet-gradient-text-container">
                        <span className="endless-phrase-1">
                        ENDLESS WRITING POSSIBILITIES
                        </span>
                    <span className="endless-phrase-2">
                        AI writing platform made for copywriters, marketers and
                        reasearchers.
                        </span>
                    <span className="endless-phrase-3">
                        Picture a platform so influential it has inspired over 100 creative minds to develop and use it for transformative content creation.
                        Now, consider that you have this power in your hands: Welcome to Phraiser, the future of content writing..{" "}
                        </span>
                    <span className="endless-phrase-4">
                        <div className="inline-block-endless-phrase-4">
                            <div className="endless-phrase-4-innnercontainer">
                            <Button
                                component={Link}
                                to="/app"
                                variant="contained"
                                color="secondary"
                                size="large"
                                sx={{
                                    width: 170,
                                    borderRadius: '25px',
                                    mr: 2,
                                    borderColor: 'white',
                                    color: 'white',
                                    "&:hover": {
                                        backgroundColor: '#aa0d8a!important' // Change the background color when hovering over the button
                                    }
                                }}
                                className='landing-page-tryfree-btn-3'
                            >
                                TRY FOR FREE
                            </Button>
                            <span className="endless-phrase-5">
                            Free registration. No credit card required.
                            </span>
                            </div>
                        </div>
                        </span>
                </div>
            </div>
        </div>
    );
};

export default Violet_box_stworek;