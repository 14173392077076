const satire = {
    "id": -1,
    "title": "Enter your satirical article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Satire", 'id': 'satire'},
        "language": "English",
        "styles": ["Satirical", "Humorous", "Conversational"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the subject or issue you'll be satirizing, and set the tone for the rest of the article.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Main Body",
            "content": "Use humor, irony, and exaggeration to critique the subject or issue.",
            "context": "",
            "min_words": 200,
            "max_words": 500,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Point 1",
                    "content": "Describe the first satirical point or scenario.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 150,
                    "subpoints": {}
                },
                "2": {
                    "id": "2.2.",
                    "name": "Point 2",
                    "content": "Describe the second satirical point or scenario.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 150,
                    "subpoints": {}
                },
                "3": {
                    "id": "2.3.",
                    "name": "Point 3",
                    "content": "Describe the third satirical point or scenario.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 150,
                    "subpoints": {}
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Conclusion",
            "content": "Wrap up the satire by summarizing the main points and offering a final humorous or ironic insight.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default satire;