// AccountSettings.js
import React, {useState} from 'react';
import {Auth} from 'aws-amplify';
import {
    Box,
    Typography,
    TextField,
    Button,
} from '@mui/material';

const AccountSettings = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleChangePassword = async () => {
        if (newPassword !== confirmNewPassword) {
            setMessage("New passwords don't match");
            return;
        }

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
                currentUser,
                currentPassword,
                newPassword
            );
            setCurrentPassword(null);
            setNewPassword('');
            setConfirmNewPassword('');
            setMessage('Password changed successfully');
        } catch (error) {
            console.error('Error changing password:', error);
            setMessage('Error changing password');
        }
    };

    return (
        <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'space-around', gap: 'calc(5px + 0.2vw)'}}>
            <Typography variant="h6">Account Settings</Typography>
            <Typography variant="body1" color="primary" sx={{mt: 2, mb: 3}}>
                Change your password
            </Typography>
            <Box mt={2}>
                <TextField
                    size="small"
                    label="Current Password"
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
            </Box>
            <Box mt={2}>
                <TextField
                    size="small"
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </Box>
            <Box mt={2}>
                <TextField
                    size="small"
                    label="Confirm New Password"
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
            </Box>
            <Box mt={2}>
                <Button variant="contained" color="primary" onClick={handleChangePassword}>
                    Change Password
                </Button>
                {message && (
                    <Typography variant="body2" mt={1}>
                        {message}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default AccountSettings;
