import {API, Auth} from 'aws-amplify';

const saveMendeleyAuthCode = async (authCode) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;

        const response = await API.post('phraiser', '/save-mendeley-auth-code', {
            body: {
                'user_id': userId,
                'auth_code': authCode
            },
        });

        if (!response) {
            throw new Error('Failed to save mendeley auth code');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export default saveMendeleyAuthCode;
