import {API, Auth} from 'aws-amplify';

const getDocument = async (documentId) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.post('phraiser', '/getDocuments', {
            body: {
                user_id: userId,
                document_ids: [documentId]
            },
        });
        return response[0]
    } catch (error) {
        console.error('Error fetching documents list:', error);
        return null;
    }
};

export default getDocument;