/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "phraiser",
            "endpoint": "https://tk3kfnb6ak.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:48114563-602a-4159-b399-a3e0f4d401e5",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_EmDHsq1VP",
    "aws_user_pools_web_client_id": "307qemtkfe1thrqlda5heao2ah",
    "oauth": {
        "domain": "phraiseree512d85-ee512d85-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.phraiser.io/",
        "redirectSignOut": "http://localhost:3000/,https://www.phraiser.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "articles-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "userMendeleyToken-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "userPayment-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "userSubscription-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "userToken-prod",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
