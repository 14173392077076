import React, {useEffect, useState} from 'react';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import Paper from '@mui/material/Paper';

import Footer from "./Footer";

const formalityMarks = [
    {value: 0, label: 'Chilled'},
    {value: 25, label: 'Casual'},
    {value: 50, label: 'Neutral'},
    {value: 75, label: 'Professional',},
    {value: 100, label: 'Formal'},
];

const wordChoiceMarks = [
    {value: 0, label: 'Informal'},
    {value: 25, label: 'Moderate'},
    {value: 50, label: 'Neutral'},
    {value: 75, label: 'Professional',},
    {value: 100, label: 'Sophisticated'},
];

const grammarMarks = [
    {value: 0, label: 'Basic'},
    {value: 25, label: 'Standard'},
    {value: 50, label: 'Structured'},
    {value: 75, label: 'Sophisticated',},
    {value: 100, label: 'Advanced'},
];

const technicalMarks = [
    {value: 0, label: 'Basic'},
    {value: 25, label: 'Moderate'},
    {value: 50, label: 'Technical'},
    {value: 75, label: 'Advanced',},
    {value: 100, label: 'Expert'},
];

const wordLengthMarks = [
    {value: 0, label: 'Shortest'},
    {value: 25, label: 'Shorter'},
    {value: 50, label: 'Normal'},
    {value: 75, label: 'Longer',},
    {value: 100, label: 'Longest'},
];

function valueLabelFormat(value) {
    return formalityMarks.findIndex((mark) => mark.value === value) + 1;
}

async function getParaphrasedText(text, formality, wordChoice, grammar, technicalLevel, wordLength, sentenceLength) {
    const response = await fetch('https://xu8192x2rk.execute-api.eu-central-1.amazonaws.com/phraiser-prod?' +
        'text=' + text + '&formality=' + formality +
        '&wordChoice=' + wordChoice + '&grammar=' + grammar + '&technicalLevel=' + technicalLevel +
        '&wordLength=' + wordLength + '&sentenceLength=' + sentenceLength
        , {
            method: 'GET',
            headers: {'APIkey': 'CMmyPILZvBZ589OI0Ax9J3hs'}
        });
    const results = await response.json();
    return results;
}

async function getElaboratedText(text, idea, sentences, formality, wordChoice, grammar, technicalLevel, wordLength, sentenceLength) {
    const response = await fetch('https://xu8192x2rk.execute-api.eu-central-1.amazonaws.com/phraiser-elabo-prod?' +
        'text=' + text + '&idea=' + idea + '&sentences=' + sentences + '&formality=' + formality +
        '&wordChoice=' + wordChoice + '&grammar=' + grammar + '&technicalLevel=' + technicalLevel +
        '&wordLength=' + wordLength + '&sentenceLength=' + sentenceLength
        , {
            method: 'GET',
            headers: {'APIkey': 'CMmyPILZvBZ589OI0Ax9J3hs'}
        });
    const results = await response.json();
    return results;
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


function Paraphraser() {
    const {height, width} = useWindowDimensions();
    const [text, setText] = useState(null);
    const [paraphrasedText, setParaphrasedText] = useState(null);
    const [ideas, setIdeas] = useState([]);
    const [formality, setFormality] = useState(50);
    const [wordChoice, setWordChoice] = useState(50);
    const [grammar, setGrammar] = useState(50);
    const [technicalLevel, setTechnicalLevel] = useState(50);
    const [wordLength, setWordLength] = useState(50);
    const [sentenceLength, setSentenceLength] = useState(50);
    const [loading, setLoading] = useState(false);

    const [slidersValue, setSlidersValue] = useState({});
    const [spinners, setSpinners] = useState({});
    const [elaboratedTexts, setElaboratedTexts] = useState({});

    const isMobile = width < 1000;

    const updateText = (e) => {
        setText(e.target.value);
    }

    const paraphrase = () => {
        const fetchParaphrasedText = async () => {
            setLoading(true);
            const result = await getParaphrasedText(text, formality, wordChoice, grammar, technicalLevel, wordLength, sentenceLength);
            setLoading(false);
            setParaphrasedText(result.paraphrased_text);
            setIdeas(result.ideas);
            for (let i = 0; i < result.ideas.length; i++) {
                spinners[i] = false;


            }
        }
        fetchParaphrasedText();

    }

    const elaborate = (e) => {

        let idx = e.target.value;
        let idea = ideas[idx];
        let sentences = slidersValue[idx] || 3;
        // console.log('2222222', idea, sentences);
        const fetchElaboratedText = async () => {
            // setLoading(true);
            setSpinners(ela => ({...spinners, [idx]: true}));
            const result = await getElaboratedText(text, idea, sentences, formality, wordChoice, grammar, technicalLevel, wordLength, sentenceLength);
            // setLoading(false);
            // setParaphrasedText(result.paraphrased_text);
            // setIdeas(result.ideas);
            setSpinners(ela => ({...spinners, [idx]: false}));
            setElaboratedTexts(ela => ({...elaboratedTexts, [idx]: result.elaborated_text}));
        }
        fetchElaboratedText();

    }

    const updateFormality = (e) => {
        setFormality(e.target.value);
    }
    const updateWordChoice = (e) => {
        setWordChoice(e.target.value);
    }
    const updateGrammar = (e) => {
        setGrammar(e.target.value);
    }
    const updateTechnicalLevel = (e) => {
        setTechnicalLevel(e.target.value);
    }
    const updateWordLength = (e) => {
        setWordLength(e.target.value);
    }
    const updateSentenceLength = (e) => {
        setSentenceLength(e.target.value);
    }

    const updateSlidersValue = (e, index) => {
        setSlidersValue(slidersValue => ({...slidersValue, [index]: e.target.value}));
    }

    useEffect(() => {
        document.title = "AI Paraphraser - Unlock the Power of AI for Better Writing"
    }, []);
    return (
        <>
            <div style={{
                width: '100%', backgroundImage: "url(" + "img/house_background.jpg" + ")", backgroundSize: 'cover',
                'background-position': 'center'
            }}>
                <div style={{'padding-left': (width - 800) / 2, 'padding-top': 0}}>
                    <div style={{
                        width: isMobile ? '100%' : 800, height: 300, backgroundColor: 'rgba(255,255,255,.97)',
                        'padding-left': 0, 'padding-top': 20
                    }}>

                        <div style={{'padding-bottom': 40}}>
                             <span style={{
                                 'font-family': 'lato', 'font-size': 28, color: '#3D3C09', fontWeight: 500,
                                 'padding-left': isMobile ? 20 : 20
                             }}>
                                Articulate your draft writing
                            </span>
                        </div>

                        <div>
                            <div style={{
                                width: isMobile ? '96%' : 400,
                                float: isMobile ? 'none' : 'left',
                                'padding-left': 25,
                                'padding-right': 30
                            }}>
                                <span>
                                    Formality
                                </span>
                                <Slider
                                    // aria-label="Restricted values"
                                    defaultValue={75}
                                    valueLabelFormat={valueLabelFormat}
                                    // getAriaValueText={valuetext}
                                    step={null}
                                    // valueLabelDisplay="auto"
                                    marks={formalityMarks}
                                    onChange={updateFormality}
                                />
                            </div>
                            <div style={{
                                width: isMobile ? '92%' : 400,
                                float: isMobile ? 'none' : 'left',
                                'padding-left': 30,
                                'padding-right': 10
                            }}>
                                <span>
                                    Word Choice
                                </span>
                                <Slider
                                    // aria-label="Restricted values"
                                    defaultValue={75}
                                    // valueLabelFormat={valueLabelFormat}
                                    // getAriaValueText={valuetext}
                                    step={null}
                                    // valueLabelDisplay="auto"
                                    marks={wordChoiceMarks}
                                    onChange={updateWordChoice}
                                />
                            </div>
                        </div>
                        <div style={{'padding-top': isMobile ? 0 : 100}}>
                            <div style={{
                                width: isMobile ? '95%' : 400,
                                float: isMobile ? 'none' : 'left',
                                'padding-left': 25,
                                'padding-right': 30
                            }}>
                                <span>
                                    Grammar
                                </span>
                                <Slider
                                    // aria-label="Restricted values"
                                    defaultValue={50}
                                    valueLabelFormat={valueLabelFormat}
                                    // getAriaValueText={valuetext}
                                    step={null}
                                    // valueLabelDisplay="auto"
                                    marks={grammarMarks}
                                    onChange={updateGrammar}
                                />
                            </div>
                            <div style={{
                                width: isMobile ? '92%' : 400,
                                float: isMobile ? 'none' : 'left',
                                'padding-left': 30,
                                'padding-right': 10
                            }}>
                                <span>
                                    Technical level
                                </span>
                                <Slider
                                    // aria-label="Restricted values"
                                    defaultValue={0}
                                    valueLabelFormat={valueLabelFormat}
                                    // getAriaValueText={valuetext}
                                    step={null}
                                    // valueLabelDisplay="auto"
                                    marks={technicalMarks}
                                    onChange={updateTechnicalLevel}
                                />
                            </div>
                        </div>
                        <div style={{'padding-top': isMobile ? 0 : 100}}>
                            <div style={{
                                width: isMobile ? '95%' : 400,
                                float: isMobile ? 'none' : 'left',
                                'padding-left': 25,
                                'padding-right': 30
                            }}>
                                <span>
                                    Word length
                                </span>
                                <Slider
                                    // aria-label="Restricted values"
                                    defaultValue={50}
                                    valueLabelFormat={valueLabelFormat}
                                    // getAriaValueText={valuetext}
                                    step={null}
                                    // valueLabelDisplay="auto"
                                    marks={wordLengthMarks}
                                    onChange={updateWordLength}
                                />
                            </div>
                            <div style={{
                                width: isMobile ? '92%' : 400,
                                float: isMobile ? 'none' : 'left',
                                'padding-left': 30,
                                'padding-right': 10
                            }}>
                                <span>
                                    Sentences length
                                </span>
                                <Slider
                                    // aria-label="Restricted values"
                                    defaultValue={50}
                                    valueLabelFormat={valueLabelFormat}
                                    // getAriaValueText={valuetext}
                                    step={null}
                                    // valueLabelDisplay="auto"
                                    marks={wordLengthMarks}
                                    onChange={updateSentenceLength}
                                />
                            </div>
                        </div>
                        <div style={{width: '100%', 'margin-top': isMobile ? 20 : 100}}>
                            <div>
                                <TextField multiline rows={6} style={{
                                    width: isMobile ? width - 40 : 800,
                                    'margin-left': 20,
                                    'margin-right': 20
                                }}
                                           placeholder="Your text to rephrase" onChange={updateText}/>
                            </div>
                            <div style={{
                                'margin-top': 40,
                                'margin-bottom': 40,
                                'padding-left': isMobile ? (width / 2) - 80 : 315
                            }}>
                                {!loading && <Button style={{
                                    width: 160,
                                    height: 50,
                                    fontSize: 16,
                                    fontWeight: 500,
                                    'font-family': 'lato',
                                    'background-color': '#429bcb'
                                }}
                                                     variant="contained" size="large" endIcon={<SwapVertIcon/>}
                                                     onClick={paraphrase}>
                                    Paraphrase
                                </Button>}
                                {loading && <div style={{'padding-left': 60}}><CircularProgress/></div>}

                            </div>
                            <div>
                                <TextField multiline rows={10} style={{
                                    width: isMobile ? width - 40 : 800,
                                    'margin-left': 20,
                                    'margin-right': 20
                                }} value={paraphrasedText}/>
                            </div>
                            <div style={{
                                'margin-top': 40,
                                'margin-bottom': 70,
                                'padding-left': isMobile ? (width / 2) - 40 : 350
                            }}>
                                <Button style={{
                                    width: 80,
                                    height: 50,
                                    fontSize: 16,
                                    fontWeight: 500,
                                    'font-family': 'lato',
                                    'background-color': '#429bcb'
                                }}
                                        variant="contained" size="large" onClick={() => {
                                    navigator.clipboard.writeText(paraphrasedText)
                                }}>
                                    Copy
                                </Button>

                            </div>
                            <div style={{width: '100%', 'padding-left': 30}}>
                                {(ideas.length > 0) &&
                                    <div style={{'margin-bottom': 30}}>
                                        <span style={{
                                            'font-family': 'lato', 'font-size': 28, color: '#3D3C09', fontWeight: 500,
                                            'padding-left': isMobile ? 0 : 0
                                        }}>
                                        Augment your ideas
                                        </span>
                                    </div>
                                }

                                {ideas.map((text, index) => (
                                    <Paper variant="outlined" style={{
                                        overflow: 'auto',
                                        width: '100%',
                                        'margin-bottom': 20,
                                        'padding': 13
                                    }}>
                                        <div style={{'margin-bottom': 20}}>
                                            <span style={{
                                                'font-family': 'lato',
                                                'font-size': 18,
                                                color: '#3D3C09',
                                                fontWeight: 500
                                            }}>
                                                {text}
                                            </span>
                                        </div>
                                        {!spinners[index] && <>
                                            <div style={{float: 'left', 'padding-top': 20}}>
                                                <Button
                                                    style={{width: 120, float: 'right', 'background-color': '#429bcb'}}
                                                    variant="contained" size="small"
                                                    value={index}
                                                    onClick={elaborate}
                                                >
                                                    Augment
                                                </Button>
                                            </div>
                                            {/*<div style={{float: 'left', 'margin-left': 10, 'padding-top': 20}}>*/}
                                            {/*    <Button style={{width: 120, float: 'right', 'background-color': '#429bcb'}} variant="contained" size="small">*/}
                                            {/*        Summarize*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                            <div style={{
                                                width: isMobile ? '60%' : 400,
                                                float: isMobile ? 'left' : 'left',
                                                'padding-left': isMobile ? 20 : 25,
                                                'padding-right': 10,
                                                'margin-bottom': 20
                                            }}>
                                                <div style={{}}>
                                                    <span>
                                                        Sentences
                                                    </span>
                                                </div>

                                                <Slider
                                                    aria-label="2"
                                                    min={1}
                                                    max={10}
                                                    marks
                                                    valueLabelDisplay="auto"
                                                    defaultValue={3}
                                                    step={1}
                                                    onChange={(e) => updateSlidersValue(e, index)}
                                                />
                                            </div>
                                        </>
                                        }
                                        {spinners[index] && <div style={{'padding-left': 40}}><CircularProgress/></div>}
                                        <div style={{clear: 'both'}}>
                                            {elaboratedTexts[index]}
                                        </div>


                                    </Paper>
                                ))}
                            </div>
                            <Footer/>

                        </div>

                    </div>
                </div>

            </div>


        </>
    )
}

export default Paraphraser;