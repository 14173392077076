import {Box, Button, Modal, TextField, Typography} from "@mui/material";
import React, {useState} from "react";

const ContextWindow = ({
                           article,
                           setArticle,
                           contextWindowPoint,
                           setContextWindowPoint,
                       }) => {
    const [characterCount, setCharacterCount] = useState(
        contextWindowPoint ? contextWindowPoint.context.length : 0
    );

    const closeContextModal = () => {
        setContextWindowPoint(null);
    };

    const handleTextFieldChange = (e) => {
        const newContext = e.target.value;
        if (newContext.length <= 2000) {
            if (contextWindowPoint) {
                contextWindowPoint.context = newContext;
                setArticle({...article});
            }
            setCharacterCount(newContext.length);
        }
    };

    return (
        <Modal
            open={contextWindowPoint !== null}
            onClose={closeContextModal}
            aria-labelledby="context-modal-title"
            aria-describedby="context-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 700,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 3,
                }}
            >
                <Typography
                    id="context-modal-title"
                    variant="h6"
                    component="h2"
                >
                    Provide any contextual information for point{" "}
                    {contextWindowPoint && contextWindowPoint.id}
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={18}
                    color="secondary"
                    margin="normal"
                    value={contextWindowPoint ? contextWindowPoint.context : ""}
                    onChange={handleTextFieldChange}
                />
                <Typography variant="subtitle2">
                    {characterCount}/2000
                </Typography>
                <Box
                    sx={{display: "flex", justifyContent: "flex-end", mt: 1}}
                >
                    <Button
                        color="primary"
                        size="large"
                        onClick={closeContextModal}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ContextWindow;
