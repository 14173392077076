const tech_review = {
    "id": -1,
    "title": "Enter your tech review article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Tech Review", 'id': 'tech_review'},
        "language": "English",
        "styles": ["Informative", "Descriptive", "Analytical"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the technology or product and provide a brief overview of its purpose.",
            "context": "",
            "min_words": 40,
            "max_words": 80,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Features",
            "content": "Describe the key features and capabilities of the technology or product.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Applications",
            "content": "Discuss the potential applications and use cases for the technology or product.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Pros and Cons",
            "content": "Analyze the advantages and disadvantages of the technology or product.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the key points of the review and offer a final assessment of the technology or product.",
            "context": "",
            "min_words": 40,
            "max_words": 80,
            "subpoints": {}
        }
    }
}


export default tech_review;