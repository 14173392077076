const news_article = {
    "id": -1,
    "title": "Enter your news article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "News Article", 'id': 'news_article'},
        "language": "English",
        "styles": ["News reporting", "Informative", "Expository"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Headline",
            "content": "Craft a concise and attention-grabbing headline for the news article.",
            "context": "",
            "min_words": 5,
            "max_words": 15,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Lead",
            "content": "Introduce the event or development and provide the most important information.",
            "context": "",
            "min_words": 30,
            "max_words": 60,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Background",
            "content": "Offer relevant background information and context to help readers understand the significance of the event or development.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Details",
            "content": "Provide additional details, facts, and evidence to support the reporting.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "4.1.",
                    "name": "Quote 1",
                    "content": "Include a relevant quote from a source or expert related to the event or development.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                },
                "2": {
                    "id": "4.2.",
                    "name": "Quote 2",
                    "content": "Include another relevant quote from a source or expert related to the event or development.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                }
            }
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the news article and highlight its significance or potential impact.",
            "context": "",
            "min_words": 30,
            "max_words": 50,
            "subpoints": {}
        }
    }
}

export default news_article;