import React, { useRef, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KBprof from '../resources/company/KB_prof.jpg'
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const BlogPost = (props) =>  {

    const navigate = useNavigate();

    const handlePostClick = () => {
        navigate(`/singleBlog/${title}`, { state: { category, title, img, content, readingTime, author, date } });
    };

    const {category, title, img, content, readingTime, author, date } = props;
    let font_title = "#291852";
    const categoryColor = (category === 'Announcement') ? "#e99887" : (category === 'How To') ? '#7473b0' : (category === 'Learn') ? "#015d92" : (category === 'Free Tools') ? "#03ad59" : "violet";
    const backgroundColor = (category === 'Announcement') ? "#fcdfd9" : (category === 'How To') ? '#dbd9fc' : (category === 'Learn') ? "#9df9ef" : (category === 'Free Tools') ? "#C7ffb0" : "violet";

    const blogPostRef = useRef(null);

    useEffect(() => {
        if (blogPostRef.current) {
        blogPostRef.current.classList.add('blogPost');
        }
    }, []);

    
    
    return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{padding: '1.5%'}} className={`blogPost ${category}`} onClick={handlePostClick}>
        <Paper elevation={0}  sx={{backgroundColor: 'rgba(0, 0, 0, 0)'}}>
          <div
            style={{
              backgroundColor: 'none',
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              boxSizing: 'border-box',  
            }}
          >
            <div style={{backgroundColor: backgroundColor,
                          width: '100%',
                          height: '100%',
                          aspectRatio: '1/1',
                          justifyContent: 'flex-end',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '40px',
                          borderRadius: '15px',
                          cursor: 'pointer'
                          }}>
              <Typography variant="h6" align="left" fontSize={'clamp(17px,1.6vw,1.8vw)'} fontWeight={700} lineHeight={1.2} color={font_title}>
                {title}
              </Typography>
              <Typography variant="body1" align="left" color={categoryColor} fontWeight={700} fontSize={'clamp(12px,1.1vw,1.6vw)'}>
                {category}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row!important', alignItems: 'space-between', justifyContent: 'space-between', marginTop:'calc(0.08vw + 23px', marginLeft: '0px'}}>
                <div style={{ display: 'flex', flexDirection: 'row!important', alignItems: 'center', }}>
                    <div style={{ marginRight: 'calc(0.35vw + 5px)', position: 'relative', width:'30px'}}>
                        <img src={KBprof} alt="KB Prof" style={{width:'30px', height: '30px' , borderRadius: '50%'}}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <Typography variant="body1" align="left" fontWeight="bold">
                        { author }
                        </Typography>
                        <Typography variant="body2" align="left" color="textSecondary">
                        { date }
                        </Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'right', color: "#48beb9", cursor: 'pointer' }}>
                    { readingTime } mins to read  <ArrowForwardIcon style={{ marginRight: "0.4vw", marginLeft: "0.4vw"}}/>
                </div>
            </div>
            
          </div>         
        </Paper>
      </Grid>
    );
};

export default BlogPost;

