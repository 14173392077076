import {useEffect, useState} from 'react';
import {API, Auth} from 'aws-amplify';
import BillingSubscriptions from "./BillingSubscription";

function BillingHistory() {
    const [subscriptionRecords, setSubscriptionRecords] = useState([]);

    useEffect(() => {
        async function fetchSubscriptionRecords() {
            const user = await Auth.currentAuthenticatedUser();
            const userId = user.username;

            const data = await API.post('phraiser', '/get-billing-history', {
                body: {
                    user_id: userId,
                },
            });
            setSubscriptionRecords(data);
        }

        fetchSubscriptionRecords();
    }, []);


    return (
        <table>
            <thead>
            <tr>
                <th>Invoice ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Created</th>
            </tr>
            </thead>
            <tbody>
            {subscriptionRecords && subscriptionRecords.map((record) => (
                <tr key={record.invoice_id}>
                    <td>{record.invoice_id}</td>
                    <td>{record.subscription_start_date.slice(0, 10)}</td>
                    <td>{record.amount}</td>
                    <td>{record.premium ? 'Paid' : 'Not Paid'}</td>
                    <td>{record.premium_expiration_date}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default BillingHistory;