import {API, Auth} from 'aws-amplify';

export default async function getArticle(articleId) {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.get('phraiser', `/get-article?article_id=${articleId}&user_id=${userId}`);
        if (response && response.status === 404) {
            return null;
        }


        return response;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return null;
        } else {
            console.error('Error fetching article:', error);
        }
    }
}