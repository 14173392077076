import React, {useEffect, useState} from 'react';
import {API, Auth} from 'aws-amplify';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    FormLabel,
} from '@mui/material';

const BillingSubscriptions = () => {
    const [userSubscription, setUserSubscription] = useState(null);

    const handleCancelSubscription = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        try {
            await API.post('phraiser', '/cancel-subscription', {
                body: {
                    user_id: userId,
                },
            });
            setUserSubscription(null);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            const user = await Auth.currentAuthenticatedUser();
            const userId = user.username;
            const subscription = await API.post('phraiser', '/get-subscription', {
                body: {
                    user_id: userId,
                },
            });
            setUserSubscription(subscription);
        };

        fetchSubscriptionDetails();
    }, []);


    console.log(userSubscription);
    return (
        <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'space-around', gap: 'calc(5px + 0.2vw)'}}>
            <Typography variant="h6">Billing and subscription</Typography>
            <Box mt={3}>
                <Typography variant="body1" color="primary">
                    Your current subscription
                    type: {userSubscription && userSubscription.subscription_type}
                </Typography>
            </Box>
            <Box mt={3}>
                {userSubscription && <Typography variant="body1" color="primary">
                    Your usage: {userSubscription.current_usage}/{userSubscription.allowed_usage}
                </Typography>}
            </Box>
            <Box mt={3}>
                {userSubscription && <Typography variant="body1" color="primary">
                    Your premium subscription is active until {userSubscription.subscription_expiration_date}.
                </Typography>}
            </Box>
            {userSubscription && <Box mt={3}>
                {!userSubscription.is_canceled && userSubscription.subscription_type.includes('essential') ||
                userSubscription.subscription_type.includes('premium') ? (
                        <Button variant="contained" sx={{backgroundColor: "#B30000"}} onClick={handleCancelSubscription}>
                            Cancel subscription
                        </Button>)
                    : (

                        <Button href='/upgrade' variant="contained" color='primary'
                                onClick={handleCancelSubscription}>
                            Get subscription
                        </Button>
                    )}
            </Box>}
        </Box>
    );
};

export default BillingSubscriptions;
