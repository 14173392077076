const listicle = {
    "id": -1,
    "title": "Enter your listicle title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Listicle", 'id': 'listicle'},
        "language": "English",
        "styles": ["Informative", "Conversational", "Narrative"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the topic of the listicle and explain its relevance or importance.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "List Items",
            "content": "Present the list items in a clear and engaging manner.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Item 1",
                    "content": "Describe the first item on the list.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "2": {
                    "id": "2.2.",
                    "name": "Item 2",
                    "content": "Describe the second item on the list.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "3": {
                    "id": "2.3.",
                    "name": "Item 3",
                    "content": "Describe the third item on the list.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Conclusion",
            "content": "Summarize the listicle and offer final thoughts or recommendations.",
            "context": "",
            "min_words": 30,
            "max_words": 50,
            "subpoints": {}
        }
    }
}

export default listicle;