const editorial = {
    "id": -1,
    "title": "Enter your editorial title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Editorial", 'id': 'editorial'},
        "language": "English",
        "styles": ["Persuasive", "Argumentative", "Conversational"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the topic and the publication's stance on the issue.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Argument 1",
            "content": "Present the first argument in support of the publication's stance.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Argument 2",
            "content": "Present the second argument in support of the publication's stance.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Argument 3",
            "content": "Present the third argument in support of the publication's stance.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Counterarguments and Rebuttals",
            "content": "Address possible counterarguments and provide rebuttals to strengthen the publication's stance.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "6": {
            "id": "6.",
            "name": "Conclusion",
            "content": "Summarize the arguments and reinforce the publication's stance on the issue.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default editorial;