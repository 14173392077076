import {API, Auth} from 'aws-amplify';

const listMendeleyDocuments = async (setDocuments, accessToken) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.post('phraiser', '/list-mendeley-documents', {
            body: {
                user_id: userId,
                access_token: accessToken
            },
        });
        setDocuments(response);
    } catch (error) {
        console.error('Error fetching mendeley documents list:', error);
    }
};

export default listMendeleyDocuments;
