import React from "react";
import {MenuItem, FormControl, InputLabel, Select, Box} from "@mui/material";

const LanguageSelect = ({language, setLanguage}) => {
    const languageOptions = [
        {value: "english", label: "English"},
        {value: "polish", label: "Polish"},
        {value: "german", label: "German"},
        {value: "french", label: "French"},
        {value: "spanish", label: "Spanish"},
        {value: "russian", label: "Russian"},
        {value: "portuguese", label: "Portuguese"},
        {value: "italian", label: "Italian"},
        {value: "dutch", label: "Dutch"},
        {value: "hungarian", label: "Hungarian"},
        {value: "czech", label: "Czech"},
        {value: "slovak", label: "Slovak"},
        {value: "danish", label: "Danish"},
        {value: "swedish", label: "Swedish"},
        {value: "norwegian", label: "Norwegian"},
        {value: "finnish", label: "Finnish"},
        {value: "mandarin_chinese", label: "Mandarin Chinese"},
        {value: "malay", label: "Malay"},
        {value: "slovenian", label: "Slovenian"},
        {value: "arabic", label: "Arabic"},
        {value: "indonesian", label: "Indonesian"},
        {value: "urdu", label: "Urdu"},
        {value: "japanese", label: "Japanese"},
        {value: "swahili", label: "Swahili"},
        {value: "korean", label: "Korean"},
        {value: "thai", label: "Thai"},
        {value: "vietnamese", label: "Vietnamese"},
        {value: "hebrew", label: "Hebrew"},
        {value: "persian", label: "Persian"},
        {value: "tagalog", label: "Tagalog"},
    ];

    return (
        <Box sx={{mt: 0}}>
            <FormControl variant="outlined" size="small" sx={{minWidth: 200}}>
                <InputLabel id="language-select-label">Target Language</InputLabel>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    label="Target Language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    inputProps={{
                        name: "language",
                        id: "language-select-input",
                    }}
                >
                    {languageOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LanguageSelect;