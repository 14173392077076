import React from 'react';
import notepad_icon from '../resources/pricing/notepad_icon.svg';
import languages_icon from '../resources/pricing/languages_icon.svg';
import atom_icon from '../resources/pricing/atom-icon.svg';
import brainstorm_icon from '../resources/pricing/brainstorm_icon.svg';
import bulb_dollar_icon from '../resources/pricing/bulb_dollar_icon.svg';
import champagne_icon from '../resources/pricing/champagne_icon.svg';
import '../styles/benefits_of_phraiser.css';
import '../styles/queries_responses.css';

const BenefitsOfPhraiser = () => {

    return (
        <div className="benefits_of_phraiser">
            <div className="benefits_of_phraiser_wrapper">
                <div className="left-half-benefits">
                    <div className='benefits_of_phraiser_texts_col'>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={notepad_icon} alt="notepad_icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Write better, everywhere, anytime
                                </div>
                                <div className='text-benefits'>
                                    Phraiser is designed to write high quality texts including scientific articles, blog
                                    posts, social media posts, reviews.
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={atom_icon} alt="atom_icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Create impactful scientific articles
                                </div>
                                <div className='text-benefits'>
                                    Phraiser not only reads your references but also extracts the most
                                    relevent citations for your specific topic.
                                    Now envision these citations compiled in a language that embodies the expertise of a
                                    10+ years specialist in your field,
                                    making your scientific article shine.
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={languages_icon} alt="languages_icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Create content in 34 languages
                                </div>
                                <div className='text-benefits'>
                                    Phraiser can read and write in 30 languages including English, German, Spanish,
                                    Portugese, Japanese, Czech and Polish.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-half-benefits">
                    <div className='benefits_of_phraiser_texts_col'>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={brainstorm_icon} alt="notepad_icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Brainstorm new ideas
                                </div>
                                <div className='text-benefits'>
                                    Breakthrough writing tool that will generate ANY article title, script and content
                                    dedicated to each topic!
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={bulb_dollar_icon} alt="languages_icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className='title-benefits'>
                                    Scale your Business with AI
                                </div>
                                <div className='text-benefits'>
                                    Empower your team with an AI to save time and get more content done in 10x less
                                    time.
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={champagne_icon} alt="atom_icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className='title-benefits'>
                                    Have fun with random article writer
                                </div>
                                <div className='text-benefits'>
                                    Whether you're a scientist, blogger, content creator, or business professional,
                                    Phraiser is your ultimate writing companion. Experience the future of writing today!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="benefits_of_phraiser_mobile_wrapper">
                <div className="left-half-benefits">
                    <div className='benefits_of_phraiser_texts_col'>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={notepad_icon} alt="notepad_icon" className="benefits-icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Write better, everywhere, anytime
                                </div>
                                <div className='text-benefits'>
                                    Phraiser is designed to produce high quality content including blog posts, articles,
                                    case studies and research.
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={languages_icon} alt="languages_icon" className="benefits-icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Create content in 34 languages
                                </div>
                                <div className='text-benefits'>
                                    Phraiser can read and write in 34 languages including English, German, Spanish,
                                    Portugese, Japanese, Czech and Polish.
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={atom_icon} alt="atom_icon" className="benefits-icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Become a Scientist! Write original research
                                </div>
                                <div className='text-benefits'>
                                    AI will help you write professional original research that feels like written by 10
                                    year+ specialist in specific field.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-half-benefits">
                    <div className='benefits_of_phraiser_texts_col'>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={brainstorm_icon} alt="notepad_icon" className="benefits-icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className="title-benefits">
                                    Brainstorm new ideas
                                </div>
                                <div className='text-benefits'>
                                    Breakthrough writing tool that will generate ANY article title, script and content
                                    dedicated to each topic!
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={bulb_dollar_icon} alt="languages_icon" className="benefits-icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className='title-benefits'>
                                    Scale your Business with AI
                                </div>
                                <div className='text-benefits'>
                                    Empower your team with an AI to save time and get more content done in 10x less
                                    time.
                                </div>
                            </div>
                        </div>
                        <div className='benefits-row'>
                            <div className='icon'>
                                <img src={champagne_icon} alt="atom_icon" className="benefits-icon"></img>
                            </div>
                            <div className="title_plus_text">
                                <div className='title-benefits'>
                                    Have fun with random article writer
                                </div>
                                <div className='text-benefits'>
                                    Integrate all your traffic sources with Voluum and set up custom rules ro
                                    auto-optimize your campaigns
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BenefitsOfPhraiser;