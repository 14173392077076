const scientific = {
    "id": -1,
    "title": "Enter your article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Review", 'id': 'review_article'},
        "language": 'English',
        "styles": [],
        "model": 'gpt-4'
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Purpose",
            "content": "Define the purpose of the review and the specific research question or objective.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "1.1.",
                    "name": "Scope",
                    "content": "Describe the scope of the review, including the types of articles and the time frame examined.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "2": {
                    "id": "1.2.",
                    "name": "Methodology",
                    "content": "Explain the approach taken to search and select the articles for review.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "2": {
            "id": "2.",
            "name": "Findings",
            "content": "Summarize the key findings from the reviewed articles.",
            "context": "",
            "min_words": 300,
            "max_words": 500,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Finding 1",
                    "content": "Describe the first key finding from the reviewed articles.",
                    "context": "",
                    "min_words": 100,
                    "max_words": 200,
                    "subpoints": {}
                },
                "2": {
                    "id": "2.2.",
                    "name": "Finding 2",
                    "content": "Describe the second key finding from the reviewed articles.",
                    "context": "",
                    "min_words": 100,
                    "max_words": 200,
                    "subpoints": {}
                },
                "3": {
                    "id": "2.3.",
                    "name": "Finding 3",
                    "content": "Describe the third key finding from the reviewed articles.",
                    "context": "",
                    "min_words": 100,
                    "max_words": 200,
                    "subpoints": {}
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Conclusion",
            "content": "Draw conclusions about the current state of research in the area, identifying trends, gaps, and future directions.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "3.1.",
                    "name": "Summary",
                    "content": "Summarize the key findings and their implications for the field.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100
                },
                "2": {
                    "id": "3.2.",
                    "name": "Future Directions",
                    "content": "Discuss the gaps identified in the research and suggest potential areas for future research.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100
                }
            }
        }
    }
}

export default scientific;