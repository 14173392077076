import React from 'react';
import './styles/features.css';
import phraiserchart from "./resources/layer2/phraiser-chart.svg";
import Art_list from './resources/blog/Art_list.png';
import Organized from './resources/features/Organized.svg';
import Edit_delete_create from './resources/features/edit_delete_create.svg';
import the_scratch from './resources/features/the_scratch.svg';
import help from './resources/features/help.svg';
import Context_up from './resources/features/Context_up.svg';
import Footer from "./components/footer";

const Features = () => {
    return (
        <div className="features-container">
            <div className="features-header">
                <h1>Features</h1>
                <h4 className='discover'>Discover the amazing features of our PhrAIser app</h4>
            </div>

            <div className="features-grid">
                {features.map((feature, index) => (
                    <div key={index} className="feature">
                        <div className="icon-container">
                            <img className="feature-icon" src={feature.icon} alt={feature.title}/>
                        </div>
                        <div className="content-container-features">
                            <h3 classname="title-feature">{feature.title}</h3>
                            <p className="feature-description">{feature.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const features = [
    {
        icon: phraiserchart,
        title: 'Article Generator',
        description: `Introducing the game-changing feature of our React web app - article generation with complete customization! With 22 article types to choose from, you can easily select the topic that resonates with you the most. But that's not all - you can also select any of the 39 writing styles available, allowing you to create a unique combinatory style of writing that perfectly matches your vision.\n
Not only can you customize the writing style, but you can also choose the language and version of AI engine that will be handling the logic of the precedent. This ensures that your article is generated with precision and accuracy, perfectly reflecting your ideas and message.\n
Gone are the days of struggling to come up with the right words for your article. With our automatic article generation feature, you can save valuable time and focus on what really matters - sharing your ideas with the world. Try it out today and experience the power of customized article generation like never before!\n`,
    },
    {
        icon: Organized,
        title: 'Articles List',
        description: 'Our Article List feature is a robust and intuitive tool designed to streamline your content management process. It allows you to effortlessly store all your articles in one convenient location. The interactive interface enables easy editing, so you can revise and update your articles as your ideas evolve or new information becomes available. Additionally, with our seamless delete function, removing outdated or unnecessary articles is just a click away. This cohesive organization system not only improves efficiency but also ensures that your content is always current, relevant, and impactful. Use our Article List feature to enhance your content strategy and stay ahead in the fast-paced digital landscape.',
    },
    {
        icon: Edit_delete_create,
        title: 'Flexible workflow',
        description: "In the digital world, flexibility is key. The way work flows while using Phraiser places a strong emphasis on user-friendly interface, ensuring you can easily compose and adjust your unique workflow according to your specific needs. With our flexible system, you have the freedom to leave work at any given stage and return to it anytime, as well as abort it deleting the project if desired. Business objectives. Embrace flexibility and take full control of your work with the dynamic workflow possibilities the app is offering.",
    },
    {
        icon: the_scratch,
        title: 'Write Full Article option',
        description: `The app you have the freedom and flexibility to create unique, engaging content right from the ground up. Whether you're a seasoned writer looking for a more efficient way to create content, or a business owner looking to draft professional articles for your website, this feature is designed with you in mind.

Our user-friendly interface and powerful tools provide a seamless experience, allowing you to bring your ideas to life with ease. You can start with a blank canvas and let your creativity flow, or use our intuitive prompts to help guide your writing process.

In today's digital age, quality content is king. It's what sets you apart from the competition and engages your audience. With the ability to write full articles from scratch, you can ensure your content is always fresh, relevant, and tailored to your audience's needs. Embrace your creative potential and craft compelling content with our feature.`,
    },
    {
        icon: Context_up,
        title: 'Context Data Uploader',
        description: `The Context Data Uploader is a complete game-changer, offering unprecedented levels of customization and precision in content creation. This feature allows you to feed your own data, be it industry reports, customer feedback, proprietary research, or other relevant information, directly into Phraiser. The AI engine then processes this data, understands the context and nuances, and uses it as a foundation for creating content. This leads to output that is not only unique and high-quality but also deeply aligned with your specific needs and goals.

This feature also facilitates the creation of industry-specific content. By using data from your field, Phraiser can produce content that is accurate, knowledgeable, and resonates with your audience's specific interests and pain points.

the 'Context Data Uploader' feature is a testament to Phraiser's commitment to making AI-driven content creation as user-friendly, flexible, and powerful as possible. By empowering you to use your own data, Phraiser allows you to tap into the potential of AI while keeping your unique business context at the heart of the content creation process.`,
    },
    {
        icon: help,
        title: "Adjustable and Tweakable",
        description: `Embrace the power of customization with Phraiser, an AI writing tool that adapts to your unique content needs. Our adjustable parameters ensure that your content is not only high-quality but also perfectly tailored to your specific requirements.

Article Type: Choose from 23 different article types, ranging from blog posts, product descriptions, press releases, to research summaries, and more. Each type has been designed to cater to various content needs, ensuring you get the most suitable structure and tone for your specific piece.
    
Writing Styles: To ensure that the written content aligns with your brand voice or the intended feel of the text, you can select from 39 different writing styles. Whether you prefer a formal tone, conversational style, humorous twist, or narrative storytelling, Phraiser can emulate it.
    
Target Language: Break language barriers and connect with a global audience. With Phraiser, you can choose your content to be written in any of the 30 languages we support. Each language has been meticulously trained into the AI, ensuring that the written content is not only grammatically correct but also culturally sensitive.
    
AI Engine: Choose between ChatGPT3.5 and ChatGPT4.0, two advanced AI engines renowned for their language understanding and generation abilities. Depending on your preference for more creative suggestions (ChatGPT4.0) or tried-and-tested reliability (ChatGPT3.5), you can select the engine that suits your needs best.
    
The power to tweak these parameters allows you to guide Phraiser's output more effectively. By giving you control over the article type, writing style, target language, and the AI engine used, Phraiser ensures that the content produced aligns closely with your requirements, resonates with your target audience, and upholds your brand's image.`
    },

];

export default Features;