const historical_article = {
    "id": -1,
    "title": "Enter your historical article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Historial Article", 'id': 'historical_article'},
        "language": "English",
        "styles": ["Informative", "Narrative", "Expository"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the historical event or period and set the context for the rest of the article.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Background",
            "content": "Provide relevant context and background information to help readers understand the historical event or period.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Main Events",
            "content": "Discuss the key events, figures, or developments associated with the historical event or period.",
            "context": "",
            "min_words": 200,
            "max_words": 400,
            "subpoints": {
                "1": {
                    "id": "3.1.",
                    "name": "Event 1",
                    "content": "Describe the first key event, figure, or development.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "2": {
                    "id": "3.2.",
                    "name": "Event 2",
                    "content": "Describe the second key event, figure, or development.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "3": {
                    "id": "3.3.",
                    "name": "Event 3",
                    "content": "Describe the third key event, figure, or development.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "4": {
            "id": "4.",
            "name": "Significance and Impact",
            "content": "Explain the significance of the historical event or period, and discuss its impact on society, culture, or politics.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the key points discussed in the article and offer any final thoughts or perspectives on the historical event or period.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default historical_article;