import React from 'react';
import {Box, FormControl, Typography, Slider} from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const MinMaxWordsSelector = ({pointIds, point, article, setArticle, editVisible}) => {

    const handleWordCountChange = (event, newValue) => {
        const [newMinWords, newMaxWords] = newValue;
        const newPoints = {...article.points};

        const updatePoint = (mainId, subId = null, subSubId = null) => {
            if (subId === null) {
                newPoints[mainId] = {
                    ...newPoints[mainId],
                    min_words: newMinWords,
                    max_words: newMaxWords
                };
            } else if (subSubId === null) {
                newPoints[mainId].subpoints[subId] = {
                    ...newPoints[mainId].subpoints[subId],
                    min_words: newMinWords,
                    max_words: newMaxWords
                };
            } else {
                newPoints[mainId].subpoints[subId].subpoints[subSubId] = {
                    ...newPoints[mainId].subpoints[subId].subpoints[subSubId],
                    min_words: newMinWords,
                    max_words: newMaxWords
                };
            }
        };

        if (pointIds.length === 1) {
            updatePoint(pointIds[0]);
        } else if (pointIds.length === 2) {
            updatePoint(pointIds[0], pointIds[1]);
        } else if (pointIds.length === 3) {
            updatePoint(pointIds[0], pointIds[1], pointIds[2]);
        }

        setArticle({
            ...article,
            points: newPoints
        });
    };

    return (
        <Box display="flex" sx={{'opacity': editVisible ? 1 : 0, transition: 'opacity 200ms'}}>
            <Typography gutterBottom sx={{ml: 2, mt: 0.4, color: '#484747', fontSize: 14}}>
                Words
            </Typography>
            <Box display="inline" mt={-0.1}>
                <FormControl variant="standard" sx={{minWidth: 40, ml: 1}}>
                    <Slider
                        value={[point.min_words, point.max_words]}
                        onChange={handleWordCountChange}
                        color="secondary"
                        valueLabelDisplay="auto"
                        min={40}
                        step={20}
                        max={500} // Set your desired max value here
                        sx={{
                            width: 150, // Adjust the width to make the slider smaller
                            '& .MuiSlider-thumb': {
                                width: 14, // Adjust the thumb size
                                height: 14,
                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'inherit',
                                },
                            },
                            '& .MuiSlider-track': {
                                height: 2, // Adjust the track height
                            },
                            '& .MuiSlider-rail': {
                                height: 2, // Adjust the rail height
                            },
                            '& .MuiSlider-valueLabel': {
                                top: -15, // Adjust the value label position
                                '& *': {
                                    fontSize: '0.75rem', // Adjust the value label font size
                                },
                            },
                        }}
                    />
                </FormControl>
            </Box>
        </Box>
    );
};

export default MinMaxWordsSelector;
