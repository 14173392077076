const default_article = {
    "id": -1,
    "title": "Enter your article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Default", 'id': 'default_article'},
        "language": 'English',
        "styles": [],
        "model": 'gpt-3.5-turbo'
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Provide a brief overview of the topic and set the context for the rest of the article.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "1.1.",
                    "name": "Hook",
                    "content": "Start with an interesting statement or question to engage the reader.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                },
                "2": {
                    "id": "1.2.",
                    "name": "Background",
                    "content": "Give a brief background of the topic to help readers understand the context.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "2": {
            "id": "2.",
            "name": "Main Body",
            "content": "Discuss the key points, concepts, or arguments in detail.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Point 1",
                    "content": "Describe the first key point, concept, or argument.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "2": {
                    "id": "2.2.",
                    "name": "Point 2",
                    "content": "Describe the second key point, concept, or argument.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "3": {
                    "id": "2.3.",
                    "name": "Point 3",
                    "content": "Describe the third key point, concept, or argument.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Conclusion",
            "content": "Summarize the key points discussed in the article and offer final thoughts, recommendations, or potential next steps.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "3.1.",
                    "name": "Summary",
                    "content": "Briefly recap the main points, concepts, or arguments presented in the article.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100
                },
                "2": {
                    "id": "3.2.",
                    "name": "Final Thoughts",
                    "content": "Provide recommendations, potential next steps, or concluding thoughts on the topic.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100
                }
            }
        }
    }
}

export default default_article;