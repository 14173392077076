const book_review = {
    "id": -1,
    "title": "Enter your book review title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Book review", 'id': 'book_review'},
        "language": "English",
        "styles": ["Informative", "Analytical", "Conversational"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the book and its author, and provide a brief overview of the book's main themes or ideas.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Summary",
            "content": "Summarize the plot or main ideas of the book without giving away too many spoilers.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Analysis",
            "content": "Discuss the strengths and weaknesses of the book, including its writing style, themes, characters, and pacing.",
            "context": "",
            "min_words": 150,
            "max_words": 300,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Personal Opinion",
            "content": "Share your personal opinion on the book, and explain why you liked or disliked it.",
            "context": "",
            "min_words": 50,
            "max_words": 150,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the review, and provide a final recommendation for potential readers.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default book_review;