import React, {useEffect, useState} from "react";
import {Auth, Hub} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import {Link} from 'react-router-dom';
import {ReactComponent as MyLogo} from "../phrAIser.io.svg";
import {ThemeProvider} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';

import theme from '../theme.js'


const SignUpForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [isConfirmationCodeSent, setIsConfirmationCodeSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [accountCreated, setAccountCreated] = useState(false);
    const [showPasswordInputs, setShowPasswordInputs] = useState(false);
    const [isEmailBlocked, setIsEmailBlocked] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const locationFrom = location.state?.locationFrom || '/app';

    const [user, setUser] = useState(null);
    const [customState, setCustomState] = useState(null);


    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    setUser(data);
                    navigate(locationFrom);
                    break;
                case "signOut":
                    setUser(null);
                    break;
                case "customOAuthState":
                    setCustomState(data);
            }
        });

        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                setUser(currentUser);
                // Redirect user to /app if signed in
                navigate("/app");
            })
            .catch(() => console.log("Not signed in"));

        return unsubscribe;
    }, [navigate]);

    const handleContinueWithEmail = async (e) => {
        e.preventDefault();
        if (!email.includes("@") || !email.includes(".")) {
            setErrorMessage("Invalid email address");
            return;
        }
        console.log("Email correct. Showing password inputs.");
        setShowPasswordInputs(true);
        setIsEmailBlocked(true);
        setShowConfirmButton(true);
        setErrorMessage("");
    };

    const handleConfirmSignUp = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        try {
            await Auth.confirmSignUp(email, confirmationCode);
            console.log("Confirmation successful! User registered.");
            setAccountCreated(true);
            setIsConfirmationCodeSent(false);
            setEmail("");
            setPassword("");
            setPasswordConfirmation("");
            await Auth.signIn(email, password);
            navigate(locationFrom);
        } catch (error) {
            console.error("Confirmation error:", error);
            setErrorMessage("Wrong confirmation code");
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        if (password !== passwordConfirmation) {
            setErrorMessage("Passwords don't match");
            return;
        }
        try {
            await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email: email,
                },
            });
            console.log("Sign up successful! Confirmation code sent.");
            setIsConfirmationCodeSent(true);
            setIsEmailBlocked(true);
            setShowPasswordInputs(false);
            setShowConfirmButton(true);
        } catch (error) {
            console.error("Sign up error:", error);
            if (error.code === 'UsernameExistsException') {
                try {
                    // Resend the confirmation code
                    await Auth.resendSignUp(email);
                    console.log("Confirmation code resent.");
                    setIsConfirmationCodeSent(true);
                    setIsEmailBlocked(true);
                    setShowPasswordInputs(false);
                    setShowConfirmButton(true);
                } catch (resendError) {
                    console.error("Error resending confirmation code:", resendError);
                    setErrorMessage("An error occurred during sign up");
                }
            } else if (error.code === 'InvalidPasswordException') {
                setErrorMessage("Password not long enough. It should have at least 8 characters.");
            } else {
                setErrorMessage("An error occurred during sign up");
            }
        }
    };


    const handleGoogleSignIn = (response) => {
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
    };

    useEffect(() => {
        if (window.google && window.google.accounts && window.google.accounts.id) {
            window.google.accounts.id.initialize({
                // client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                client_id: "1009242549028-lf97p92c473lnmrqgeu5dn46ah5mep5l.apps.googleusercontent.com",
                ux_mode: "popup",
                callback: handleGoogleSignIn,
            });
            window.google.accounts.id.renderButton(document.getElementById("google-signin-button"), {
                theme: "outline",
                size: "large",
                width: 350
            });
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        ml: 2,
                        left: "calc(50% - min(620px, 50%))",
                    }}
                >
                    <Box component={Link} to="/" className="clickable-logo">
                        <MyLogo className="my-logo"/>
                    </Box>
                </Box>
                <Box
                    sx={{
                        marginTop: '28vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography component="h1" variant="h3" sx={{fontWeight: 'bold', mb: 2}}>
                        Sign Up
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={isConfirmationCodeSent ? handleConfirmSignUp : handleSignUp}
                        sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}
                    >
                        <TextField
                            margin="normal"
                            required
                            size="small"
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isEmailBlocked}
                            sx={{width: 350, mb: 2}}
                        />
                        {showPasswordInputs && (
                            <>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    size="small"
                                    label="Password"
                                    type="password"
                                    name="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    sx={{width: 350, mb: -0.5}}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="passwordConfirmation"
                                    size="small"
                                    label="Repeat Password"
                                    type="password"
                                    name="passwordConfirmation"
                                    autoComplete="current-password"
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    sx={{width: 350, mb: 1}}
                                />
                                {errorMessage && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        sx={{mt: 2, mb: 0, textAlign: "center"}}
                                    >
                                        {errorMessage}
                                    </Typography>
                                )}

                            </>
                        )}
                        {isConfirmationCodeSent && (
                            <>
                                <Box sx={{pl: 7, pr: 7}}>
                                    <Typography variant="body2" sx={{color: "grey"}}>
                                        We just sent you a temporary sign up code. Please check your inbox and paste the
                                        sign up code below.
                                    </Typography>
                                </Box>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="confirmationCode"
                                    size="small"
                                    label="Confirmation Code"
                                    name="confirmationCode"
                                    autoComplete="off"
                                    autoFocus
                                    value={confirmationCode}
                                    onChange={(e) => setConfirmationCode(e.target.value)}
                                    sx={{width: 350}}
                                />
                            </>
                        )}
                        {showConfirmButton ? (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{mt: 2, width: 350}}
                            >
                                Confirm
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    sx={{mt: 3, mb: 2}}
                                    onClick={handleContinueWithEmail}
                                    sx={{width: 350}}
                                >
                                    {!showPasswordInputs ? "Continue with email" : !isConfirmationCodeSent ? "Sign Up" : "Confirm"}
                                </Button>
                                {errorMessage && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        sx={{mt: 2, mb: 0, textAlign: "center"}}
                                    >
                                        {errorMessage}
                                    </Typography>
                                )}
                                <Divider sx={{my: 2, width: 350}}>or</Divider>
                                <div
                                    id="google-signin-button"
                                    className="g_id_signin"
                                    data-type="standard"
                                    data-login_uri="http://localhost:3000"
                                    data-size="large"
                                    data-theme="outline"
                                    data-text="continue_with"
                                    data-shape="rectangular"
                                    data-width="240"
                                    data-logo_alignment="left"
                                ></div>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="primary"
                                    onClick={() => navigate('/login')}
                                    sx={{mt: 1, mb: 2, width: 350}}
                                >
                                    Already have an account?
                                </Button>
                            </>

                        )}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default SignUpForm;
