import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Layout from "./pages/Layout";
import Paraphraser from "./pages/Paraphraser";
import ArticleAssistant from "./pages/article/ArticleAssistant";
import NoPage from "./pages/NoPage";
import Account from "./pages/user/Account";
import PremiumPage from './pages/payment/PremiumPage';
import Landing from './pages/Landing';
import Pricing from './pages/Pricing';
import Upgrade from './pages/Upgrade';
import SignUpForm from './pages/user/SignUpForm';
import SignInForm from './pages/user/SignInForm';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog2';
import Features from './pages/Features';
import TagManager from "react-gtm-module";
import LicencingSupport from "./pages/LicencingSupport";
import CookiesBanner from './pages/components/cookies_consent';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CoookiePolicy from './pages/CookiePolicy';
import TermsOfUse from './pages/Terms';
import SingleBlogPage from './pages/SingleBlogPage';
import saveMendeleyAuthCode from "./integretions/saveMendeleyAuthCode";

const tagManagerArgs = {
    gtmId: 'GTM-TNR5P7P'
}

TagManager.initialize(tagManagerArgs)


function App() {
    const [cookieAccepted, setCookieAccepted] = useState(false);

    useEffect(() => {
        const currentDomain = window.location.hostname;
        const desiredDomain = 'www.phraiser.io';
        const cookieValue = localStorage.getItem('cookieAccepted');


        if (currentDomain === 'phraiser.io') {
            window.location.replace(`https://${desiredDomain}${window.location.pathname}${window.location.search}`);
        }
        if (cookieValue === 'true') setCookieAccepted(true);
    }, []);

    const acceptCookie = () => {
        setCookieAccepted(true);
        localStorage.setItem('cookieAccepted', 'true');
    };

    function MendeleyCallback() {
        const navigate = useNavigate();
        const query = new URLSearchParams(window.location.search);
        const authCode = query.get('code');

        useEffect(() => {
            const saveAndNavigate = async () => {
                if (authCode) {
                    await saveMendeleyAuthCode(authCode);
                    navigate('/app');
                }
            };

            saveAndNavigate();
        }, [authCode, navigate]);
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Landing/>}/>
                    <Route path='about' element={<AboutUs/>}/>
                    <Route path='pricing' element={<Pricing/>}/>
                    <Route path='features' element={<Features/>}/>
                    <Route path='blog' element={<Blog/>}/>
                    <Route path="account" element={<Account/>}/>
                    <Route path="premium" element={<PremiumPage/>}/>
                    <Route path="paraphrase" element={<Paraphraser/>}/>
                    <Route path="*" element={<NoPage/>}/>
                    <Route path='support' element={<LicencingSupport/>}/>
                    <Route path='privacy' element={<PrivacyPolicy/>}/>
                    <Route path='cookie-policy' element={<CoookiePolicy/>}/>
                    <Route path='terms' element={<TermsOfUse/>}/>
                    <Route path="/singleBlog/:title" element={<SingleBlogPage/>}/>
                </Route>
                <Route path="app" element={<ArticleAssistant/>}/>
                <Route path="signup" element={<SignUpForm/>}/>
                <Route path="login" element={<SignInForm/>}/>
                <Route path='upgrade' element={<Upgrade/>}/>
                <Route path='mendeley-authorization-successful' element={<MendeleyCallback/>}/>
            </Routes>
            {!cookieAccepted && <CookiesBanner onAccept={acceptCookie}/>}
        </BrowserRouter>
    );
}

export default App;
