const comparison = {
    "id": -1,
    "title": "Enter your comparison article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Comparison", 'id': 'comparison'},
        "language": "English",
        "styles": ["Informative", "Analytical", "Expository"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the subjects or concepts being compared.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Similarities",
            "content": "Discuss the similarities between the subjects or concepts.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Differences",
            "content": "Discuss the differences between the subjects or concepts.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Conclusion",
            "content": "Summarize the main points and provide a final analysis or recommendation.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default comparison;