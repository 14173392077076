import './styles/pricing_regular.css'
import './styles/pricing_mobile.css'
import Switch from '@mui/material/Switch';
import Twisted_arrow from "./resources/pricing/twisted_arrow.svg";
import Notepad_icon from "./resources/pricing/notepad_icon.svg";
import Brainstorm from "./resources/pricing/brainstorm_icon.svg";
import Bulb_dollar from "./resources/pricing/bulb_dollar_icon.svg";
import Champagne from "./resources/pricing/champagne_icon.svg";
import Languages from "./resources/pricing/languages_icon.svg";
import Atom from "./resources/pricing/atom-icon.svg";
import Stars_icon from "./resources/pricing/stars-icon.svg";
import tick from "./resources/pricing/Vector.svg";
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import Violet_box from "./resources/pricing/Card.svg";
import QuestionsLayout from './components/pricing_question_box';
import Reviews_pricing from './components/Reviews_pricing';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EastIcon from '@mui/icons-material/East';
import phrAIser_logo2 from "./resources/layer2/phrAIser_logo2.svg";
import phrAIser_mobile from "./resources/layer2/phrAIser_mobile.svg";
import BenefitsOfPhraiser from './components/benefits_of_phraiser';
import {Helmet} from "react-helmet";
import React from "react";

const twisted_arrow = document.createElementNS("http://www.w3.org/2000/svg", "svg");

function Pricing() {
    return (
        <div className='pricing-page-container'>
            <Helmet>
                <title>Flexible Pricing Plans for our AI Writing Assistant App | Choose Your Perfect Fit</title>
                <meta name="description"
                      content="Explore our range of pricing plans for our AI writing assistant app. Whether you're an individual writer or a professional team, find the best package that suits your needs and budget."/>
                <meta property="og:title"
                      content="Flexible Pricing Plans for our AI Writing Assistant App | Choose Your Perfect Fit"/>
                <meta property="og:description"
                      content="Explore our range of pricing plans for our AI writing assistant app. Whether you're an individual writer or a professional team, find the best package that suits your needs and budget."/>
            </Helmet>
            <div className='three-price-boxes-3-1-3-container'>
                <div className='containers-container-3-1-3'>
                    <div className='first-column-3'>
                        <div className='first-column-filler-reactangle'></div>
                        <div className='first-column-elongated-content'>
                            <div className='first-column-first-div-real'>
                                <div className='essential-header'>Essential</div>
                                <div className='two-prices-elements-horizont-2'>
                                    <div className='first-elem-horizon-1 price-smaller'>
                                        $19
                                    </div>
                                    <div className='second-elem-horizon-1'>
                                        <div>
                                            /month
                                        </div>
                                    </div>

                                </div>
                                <div className='words-per-month-2'>
                                    20,000 words/mo
                                </div>
                            </div>
                            <div className='first-column-second-div-real'>
                                <div className='second-column-last-element-2'>
                                    <div className='ticks-2'>
                                        <div className='tick-element'><img src={tick}></img></div>
                                        <div className='tick-element'><img src={tick}></img></div>
                                        <div className='tick-element'><img src={tick}></img></div>
                                    </div>
                                    <div className='second-column-last-elem-elems-container'>
                                        <div className='list-element'>English supported</div>
                                        <div className='list-element'>Unlimited projects</div>
                                        <div className='list-element'>Email support</div>
                                    </div>
                                </div>
                                <div className='essential-buy-button'>
                                    <Button component={Link}
                                            to="/upgrade"
                                            variant="outlined"
                                            color="secondary"

                                            sx={{
                                                width: 170,
                                                borderRadius: '25px',
                                                mr: 2,
                                                fontSize: 'calc(15 / 1440 * 100vw)',
                                                textTransform: 'none'
                                            }}
                                            className="premium-button essential-button">
                                        Begin Now
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='first-column-filler-reactangle'></div>
                    </div>
                    <div className='second-column-1'>
                        <div className='first-column-second-div-top-violet-div'>
                            <div className='white-text-first-violet-box'>
                                Most Popular <img src={Stars_icon} className='stars-icon'/>
                            </div>
                        </div>
                        <div className='first-column-first-white-div'>
                            <div className='first-column-first-white-div-first-elem'>Premium</div>
                            <div className='eighty-nine-dollars'>89$</div>
                            <div className='horizontal-line-violet-89'></div>
                            <div className='two-prices-elements-horizont-1'>
                                <div className='first-elem-horizon-1'>
                                    $59
                                </div>
                                <div className='second-elem-horizon-1'>
                                    <div>
                                        /month
                                    </div>
                                </div>

                            </div>
                            <div className='words-per-month'>
                                100,000 words/mo
                            </div>
                        </div>
                        <div className='second-column-last-element'>
                            <div className='ticks'>
                                <div className='tick-element'><img src={tick}></img></div>
                                <div className='tick-element'><img src={tick}></img></div>
                                <div className='tick-element'><img src={tick}></img></div>
                                <div className='tick-element'><img src={tick}></img></div>
                                <div className='tick-element'><img src={tick}></img></div>
                            </div>
                            <div className='second-column-last-elem-elems-container-2'>
                                <div className='list-element'>Support for 30+ languages</div>
                                <div className='list-element'>Google Docs export enabled</div>
                                <div className='list-element'>Up to 3 users</div>
                                <div className='list-element'>Support of GPT 3.5</div>
                                <div className='list-element'>30+ article templates</div>

                            </div>
                        </div>
                        <div className='premium-button-div'>
                            <Button component={Link}
                                    to="/app"
                                    variant="outlined"
                                    color="secondary"

                                    sx={{
                                        width: 170,
                                        borderRadius: '25px',
                                        mr: 2,
                                        fontSize: 'calc(15 / 1440 * 100vw)',
                                        textTransform: 'none'
                                    }}
                                    className="premium-button">
                                Go Premium
                            </Button>
                        </div>
                    </div>
                    <div className='third-column-3'>
                        <div className='first-column-filler-reactangle'></div>
                        <div className='first-column-elongated-content'>
                            <div className='third-column-first-div-real'>
                                <div className='essential-header'>Unlimited</div>
                                <div className='two-prices-elements-horizont-2'>
                                    <div className='first-elem-horizon-1 price-smaller-2'>
                                        $120
                                    </div>
                                    <div className='second-elem-horizon-1'>
                                        <div>
                                            /month
                                        </div>
                                    </div>

                                </div>
                                <div className='words-per-month-2'>
                                    Unlimited words/mo
                                </div>
                            </div>
                            <div className='third-column-second-div-real'>
                                <div className='third-column-last-element-2'>
                                    <div className='ticks-2'>
                                        <div className='tick-element'><img src={tick}
                                                                           className='tick-element-svg'></img></div>
                                        <div className='tick-element'><img src={tick}
                                                                           className='tick-element-svg'></img></div>
                                        <div className='tick-element'><img src={tick}
                                                                           className='tick-element-svg'></img></div>
                                    </div>
                                    <div className='second-column-last-elem-elems-container-3'>
                                        <div className='list-element'>Tailored AI Brand Voice</div>
                                        <div className='list-element'>GPT 4 enabled</div>
                                        <div className='list-element'>Dedicated account manager</div>
                                    </div>
                                </div>
                                <div className='essential-buy-button'>
                                    <Button component={Link}
                                            to="/upgrade"
                                            variant="outlined"
                                            color="secondary"

                                            sx={{
                                                width: 170,
                                                borderRadius: '25px',
                                                mr: 2,
                                                fontSize: 'calc(15 / 1440 * 100vw)',
                                                textTransform: 'none'
                                            }}
                                            className="premium-button essential-button">
                                        Contact Sales
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='first-column-filler-reactangle'></div>
                    </div>
                </div>
            </div>
            <div className="first-container-gradient-violet">
                <div className='first-info-box'>
                    <div className='floating-arrow-element-container'>

                    </div>
                    <div className='first-info-box-header'>
                        <h1 className='first-info-box-header'>Pricing plans<br/> for all your needs</h1>
                    </div>
                    <div className='first-info-box-info'>
                        Ready to get started? Choose your plan<br/>
                        and start creating amazing content today!
                    </div>
                    <div className='yearly-monthly-switchbox'>
                        <div className='yearly-monthly-switchbox-leftbox'>
                            Pay Monthly
                        </div>
                        <div className='yearly-monthly-switchbox-centerbox'>
                            <Switch label="Label"/>
                        </div>
                        <div className='yearly-monthly-switchbox-rightbox'>
                            Pay Yearly
                        </div>
                        <div className='twisted-arrow'>
                            <img src={Twisted_arrow} alt="twisted-arrow-image"/>
                        </div>
                        <div className='save-25-percent-text'>
                            <h4>Save 25%</h4>
                        </div>
                    </div>
                </div>


            </div>

            <div className="first-white-box">
                <div className='benefits-of-phraser-reactangle'>
                    <div className='benefits-header'>BENEFITS OF PHRAISER</div>
                    <div className='ai-tool-you-trust'>AI tool that you can trust</div>
                    <BenefitsOfPhraiser/>
                    <div className='violet-image-container'>
                        <img src={Violet_box} alt="violet_box" className='violet_box'>
                        </img>
                        <div className='violbox-text-elems-container'>
                            <div className='violbox-try-phraiser-header'>Try Phraiser.io for free</div>
                            <div className='violbox-check-endless-text'>Check the endless possibilities of AI writing
                                assistant.
                            </div>
                            <a href="/support" className="get-started-link">
                                <div className='violbox-get-started-button'>Get started</div>
                            </a>
                        </div>
                    </div>
                    <div className='frequently-header'>Frequently asked questions</div>
                    <div className='frequently-desciption'>Can’t find the anwser you’re looking for? Reach out to
                        customer support team.
                    </div>
                    <QuestionsLayout className="frequently-question-box"/>
                    <div className='last-div'>
                        <Reviews_pricing/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pricing;