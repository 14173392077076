import {API, Auth} from 'aws-amplify';

const listPhraiserDocuments = async (setDocuments) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.post('phraiser', '/list-phraiser-documents', {
            body: {
                user_id: userId
            },
        });
        setDocuments(response);
    } catch (error) {
        console.error('Error fetching phraiser documents list:', error);
    }
};

export default listPhraiserDocuments;
