import React from "react";
import {Tooltip, MenuItem, FormControl, InputLabel, Select} from "@mui/material";
import writingStyles from "../config/writingStyles";
import Typography from "@mui/material/Typography";

function WritingStyleSelect({articleType, style, setStyle}) {
    const handleChange = (event) => {
        const selectedStyles = event.target.value.map((selectedStyle) => {
            return writingStyles.find((item) => item.style === selectedStyle);
        });
        setStyle(selectedStyles);
    };

    const recommendedStyles = articleType.recommended_styles || [];

    const sortedWritingStyles = writingStyles
        .slice()
        .sort((a, b) => {
            if (recommendedStyles.includes(a.style) && !recommendedStyles.includes(b.style)) {
                return -1;
            } else if (!recommendedStyles.includes(a.style) && recommendedStyles.includes(b.style)) {
                return 1;
            }
            return writingStyles.indexOf(a) - writingStyles.indexOf(b);
        });

    return (
        <>
            <FormControl variant="outlined" size="small" sx={{minWidth: 240}}>
                <InputLabel htmlFor="writing-style-select">Writing Styles</InputLabel>
                <Select
                    multiple
                    label="Writing Styles"
                    value={style.map((item) => item.style)}
                    onChange={handleChange}
                    inputProps={{
                        name: "writingStyles",
                        id: "writing-style-select",
                    }}
                >
                    {sortedWritingStyles.map((item, index) => (
                        <MenuItem key={index} value={item.style}>
                            <Tooltip
                                title={`${item.description}${
                                    recommendedStyles.includes(item.style) ? " (Recommended)" : ""
                                }`}
                                placement="right"
                                arrow
                                enterDelay={300}
                                leaveDelay={0}
                            >
                                <span>{item.style}{recommendedStyles.includes(item.style) ? ' (Recommended)' : ''}</span>
                            </Tooltip>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}

export default WritingStyleSelect;
