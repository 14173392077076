const profile = {
    "id": -1,
    "title": "Enter your profile title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Profile", 'id': 'profile'},
        "language": "English",
        "styles": ["Informative", "Narrative", "Descriptive"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the subject of the profile and provide context.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Background",
            "content": "Present background information about the subject, such as their history, upbringing, or early experiences.",
            "context": "",
            "min_words": 100,
            "max_words": 300,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Achievements",
            "content": "Highlight the subject's key accomplishments, awards, or milestones.",
            "context": "",
            "min_words": 100,
            "max_words": 300,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Anecdotes",
            "content": "Share interesting anecdotes or stories related to the subject.",
            "context": "",
            "min_words": 100,
            "max_words": 300,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the key points and provide a final assessment of the subject's impact or importance.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default profile;