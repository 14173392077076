// ProfileSettings.js
import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    FormLabel,
} from '@mui/material';

const ProfileSettings = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [newName, setNewName] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                setEmail(currentUser.attributes.email);
                setName(currentUser.attributes.name);
                setNewName(currentUser.attributes.name);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, []);

    const handleSave = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, {name: newName});
            setName(newName);
            setMessage('Name updated successfully.');
        } catch (error) {
            console.error('Error updating name:', error);
            setMessage('Error updating name.');
        }
    };

    return (
        <Box sx={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'space-around'}}>
            <Box sx={{display:'flex', flexDirection: 'column', alignItems:'flex-start', justifyContent:'space-around'}}>
            <Typography variant="h6">Profile Settings</Typography>
            <Box mt={2}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign:'left'}}>Email</FormLabel>
                    <Typography>{email}</Typography>
                </FormControl>
            </Box>
            <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: '1'}}>
                <Typography variant="body1" color="primary" sx={{mt: 4, mb: 2,textAlign:'left'}}>
                    Change your name
                </Typography>
                <Box mt={0}>
                    <TextField
                        textAlign="center"
                        label="Name"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                    />
                </Box>
            </Box>
            <Box mt={2}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
                {message && (
                    <Typography variant="body2" mt={1}>
                        {message}
                    </Typography>
                )}
            </Box>
        </Box>
    </Box>
    );
};

export default ProfileSettings;
