const writingStyles = [
    {"id": "persuasive", "style": "Persuasive", "description": "Make a compelling argument to persuade the reader."},
    {"id": "informative", "style": "Informative", "description": "Provide clear and concise information on a topic."},
    {"id": "narrative", "style": "Narrative", "description": "Tell a story with a beginning, middle, and end."},
    {
        "id": "descriptive",
        "style": "Descriptive",
        "description": "Use vivid language to paint a picture for the reader."
    },
    {"id": "expository", "style": "Expository", "description": "Explain a concept or idea in detail."},
    {
        "id": "argumentative",
        "style": "Argumentative",
        "description": "Make a case for a specific viewpoint or opinion."
    },
    {"id": "satirical", "style": "Satirical", "description": "Use humor and irony to critique a subject."},
    {"id": "analytical", "style": "Analytical", "description": "Examine a topic or issue critically."},
    {"id": "poetic", "style": "Poetic", "description": "Utilize poetic language, metaphor, and imagery."},
    {"id": "conversational", "style": "Conversational", "description": "Write as if speaking directly to the reader."},
    {
        "id": "interview_style",
        "style": "Interview style",
        "description": "Present information as a series of questions and answers."
    },
    {
        "id": "scientific",
        "style": "Scientific",
        "description": "Use formal language and structure to convey complex ideas."
    },
    {
        "id": "stream_of_consciousness",
        "style": "Stream of consciousness",
        "description": "Capture the thoughts and emotions of a character."
    },
    {
        "id": "epistolary",
        "style": "Epistolary",
        "description": "Compose the text as a series of letters or diary entries."
    },
    {
        "id": "news_reporting",
        "style": "News reporting",
        "description": "Write in the style of a news article or press release."
    },
    {"id": "parody", "style": "Parody", "description": "Imitate another work or style for comedic effect."},
    {"id": "allegorical", "style": "Allegorical", "description": "Use symbolism to represent abstract ideas."},
    {"id": "minimalist", "style": "Minimalist", "description": "Use simple, straightforward language and structure."},
    {"id": "absurdist", "style": "Absurdist", "description": "Explore illogical or nonsensical scenarios."},
    {"id": "gothic", "style": "Gothic", "description": "Employ dark, atmospheric, and supernatural elements."},
    {"id": "romantic", "style": "Romantic", "description": "Focus on emotions, imagination, and the beauty of nature."},
    {"id": "cyberpunk", "style": "Cyberpunk", "description": "Write in a futuristic, technology-focused setting."},
    {
        "id": "utopian_dystopian",
        "style": "Utopian/Dystopian",
        "description": "Explore the implications of a perfect or flawed society."
    },
    {"id": "postmodern", "style": "Postmodern", "description": "Play with structure, genre, and conventions."},
    {
        "id": "magical_realism",
        "style": "Magical realism",
        "description": "Blend fantastical elements with realistic settings."
    },
    {
        "id": "pulp_fiction",
        "style": "Pulp fiction",
        "description": "Write in the style of classic pulp magazines, with action and adventure."
    },
    {
        "id": "hardboiled_detective",
        "style": "Hardboiled detective",
        "description": "Imitate the gritty, cynical tone of noir fiction."
    },
    {"id": "episodic", "style": "Episodic", "description": "Write in a series of connected vignettes or episodes."},
    {"id": "didactic", "style": "Didactic", "description": "Teach a moral or philosophical lesson through the text."},
    {
        "id": "interactive_fiction",
        "style": "Interactive fiction",
        "description": "Write a story where the reader can make choices that influence the outcome."
    },
    {
        "id": "documentary",
        "style": "Documentary",
        "description": "Present information in the style of a documentary film or series."
    },
    {"id": "confessional", "style": "Confessional", "description": "Share deeply personal experiences and emotions."},
    {
        "id": "biography",
        "style": "Biography",
        "description": "Write about the life and accomplishments of a real or fictional person."
    },
    {"id": "autobiography", "style": "Autobiography", "description": "Chronicle your own life and experiences."},
    {
        "id": "historical_fiction",
        "style": "Historical fiction",
        "description": "Set your story in a specific historical period."
    },
    {"id": "mythological", "style": "Mythological", "description": "Write in the style of ancient myths and legends."},
    {
        "id": "parable",
        "style": "Parable",
        "description": "Convey a moral or ethical lesson through a simple narrative."
    },
    {"id": "fable", "style": "Fable", "description": "Use animal characters and personification to teach a lesson."},
    {
        "id": "fairy_tale",
        "style": "Fairy tale",
        "description": "Write a story with magical elements, often involving a quest or adventure."
    }
]

export default writingStyles;