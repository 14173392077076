import React, {useState, useEffect} from 'react';
import '../styles/SlidingContent.css';
import {ReactComponent as Aflilogo} from "../resources/layer2/aflilogo.svg";


const SlidingContent = () => {
    const [activeContent, setActiveContent] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setActiveContent((prevContent) => (prevContent + 1) % 4);
        }, 10000);
        return () => clearTimeout(timer);
    }, [activeContent]);

    const changeContent = (index) => {
        setActiveContent(index);
    };

    const contentData = [
        ['REVIEWS', "I used nearly every AI writing tool on the market and I always come back to Phraiser. It provides the most user-friendly interface and has highest quality of content generated. It just feel like written 100% by human.", 'Luke Kling', 'Digital Manager'],
        ['REVIEWS', "I used nearly every AI writing tool on the market and I always come back to Phraiser. It provides the most user-friendly interface and has highest quality of content generated. It just feel like written 100% by human.", 'Luke Kling', 'Digital Manager'],
        ['REVIEWS', "I used nearly every AI writing tool on the market and I always come back to Phraiser. It provides the most user-friendly interface and has highest quality of content generated. It just feel like written 100% by human.", 'Luke Kling', 'Digital Manager'],
        ['REVIEWS', "I used nearly every AI writing tool on the market and I always come back to Phraiser. It provides the most user-friendly interface and has highest quality of content generated. It just feel like written 100% by human.", 'Luke Kling', 'Digital Manager'],
    ];

    return (
        <div className="sliding-content">
            <div className="sliding-content-wrapper">
                <div
                    className="content-container"
                    style={{transform: `translateX(-${activeContent * 100}%)`}}
                >
                    {contentData.map((content, index) => (
                        <div key={index} className="content">
                            {content.map((item, i) => (
                                <span key={i} className={`span-${i}`}>{item}</span>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="buttons">
                    {contentData.map((_, index) => (
                        <button
                            key={index}
                            className={`flat-button${activeContent === index ? ' active' : ''}`}
                            onClick={() => changeContent(index)}
                        ></button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SlidingContent;