import React, {useEffect, useRef, useState} from "react";
import {Box, Divider} from "@mui/material";
import Article from "./Article";
import WritingStyleSelect from "./selectors/WritingStyleSelect";
import LanguageSelect from "./selectors/LanguageSelect";
import ArticleTypeSelect from "./selectors/ArticleTypeSelect";
import ModelSelect from "./selectors/ModelSelect";
import {ThemeProvider} from '@mui/material/styles';
import {ReactComponent as MyLogo} from "../phrAIser.io.svg";
import "../styles/io_app.css";
import theme from '../theme.js'
import SideMenu from "./SideMenu"
import ReferencesMenu from "./ReferencesMenu";
import saveArticle from "./integrations/saveArticle"
import getArticle from "./integrations/getArticle"
import listArticles from "./integrations/listArticles"
import deleteArticle from "./integrations/deleteArticle"
import {Auth} from "@aws-amplify/auth";
import getTemplate from "./templates"
import {Link} from "react-router-dom";
import {API} from "aws-amplify";
import {Helmet} from "react-helmet";


const isMobileDevice = () => {
    return window.matchMedia("only screen and (max-width: 767px)").matches;
};

const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};
const debouncedSaveArticle = debounce(saveArticle, 1000);


const ArticleAssistant = () => {
    const [isMobile, setIsMobile] = useState(isMobileDevice());
    const [articleType, setArticleType] = useState({"type": "", 'id': 'default_article'});
    const [language, setLanguage] = useState("english");
    const [styles, setStyles] = useState([]);
    const [model, setModel] = useState("gpt-3.5-turbo");
    const [scientificMode, setScientificMode] = useState(false);
    const [articles, setArticles] = useState([]);
    const [article, setArticle] = useState(getTemplate('default_article'));
    const [loggedIn, setLoggedIn] = useState(false);
    const [userSubscription, setUserSubscription] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState('free');
    const [newArticleSelected, setNewArticleSelected] = useState(true);
    const [references, setReferences] = useState({});
    const [mendeleyAccessToken, setMendeleyAccessToken] = useState(null);


    const urlParams = new URLSearchParams(window.location.search);
    const articleId = urlParams.get('articleId');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(isMobileDevice());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const updateTitle = (article) => {
        setArticles((prevArticles) => {
            return prevArticles.map((list_elem_article) => {
                if (list_elem_article.id === article.id) {
                    return {
                        ...list_elem_article,
                        title: article.title,
                    };
                }
                return list_elem_article;
            });
        });
    }
    const debouncedUpdateTitle = debounce(updateTitle, 3000);

    useEffect(() => {
        if (article.title != 'Enter your article title here') {
            debouncedSaveArticle(article);
            debouncedUpdateTitle(article);
        }

    }, [article]);

    useEffect(() => {
        // Update the article with the new data
        setArticle(prevArticle => ({
            ...prevArticle,
            parameters: {
                ...prevArticle.parameters,
                articleType: articleType,
                language: language,
                styles: styles,
                model: model
            }
        }));
    }, [language, styles, model]);

    useEffect(() => {
        if (article && articleType && newArticleSelected) {
            console.log(articleType);
            if (articleType.id === 'scientific') {
                const userConfirmed = window.confirm('Do you want to switch to scientific mode?');
                if (userConfirmed) {
                    setScientificMode(true);
                    setArticle(getTemplate(articleType.id));
                    setArticle(prevArticle => ({
                        ...prevArticle,
                        parameters: {
                            ...prevArticle.parameters,
                            articleType: articleType,
                            language: language,
                            styles: styles,
                            model: model,
                        },
                    }));
                }
            } else if (articleType.id != 'default_article') {
                setScientificMode(false);
                const userConfirmed = window.confirm('Do you want to override the whole article with a template?');
                if (userConfirmed) {
                    setArticle(getTemplate(articleType.id));
                    setArticle(prevArticle => ({
                        ...prevArticle,
                        parameters: {
                            ...prevArticle.parameters,
                            articleType: articleType,
                            language: language,
                            styles: styles,
                            model: model,
                        },
                    }));
                }
            }
        }

    }, [articleType]);
    const checkAuthStatus = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setLoggedIn(true);
        } catch (error) {
            setLoggedIn(false);
        }
    };

    useEffect(() => {
        checkAuthStatus();
    }, []);

    useEffect(() => {
        if (loggedIn) {
            listArticles(setArticles, article);

            const fetchSubscriptionDetails = async () => {
                const user = await Auth.currentAuthenticatedUser();
                const userId = user.username;
                const subscription = await API.post('phraiser', '/get-subscription', {
                    body: {
                        user_id: userId,
                    },
                });
                setUserSubscription(subscription);
            };

            fetchSubscriptionDetails();

        } else {
            setArticles([]);
        }
    }, [loggedIn]);

    useEffect(() => {
        if (userSubscription && userSubscription.subscription_type.includes('essential')) {
            setSubscriptionType('essential')
        } else if (userSubscription && userSubscription.subscription_type.includes('premium')) {
            setSubscriptionType('premium')
        } else {
            setSubscriptionType('free')
        }
    }, [userSubscription]);


    const handleArticleSelection = async (articleId) => {
        setNewArticleSelected(false);
        const fetchedArticle = await getArticle(articleId);
        if (fetchedArticle) {
            setArticle(fetchedArticle);
            setArticleType(fetchedArticle.parameters.articleType);
            setLanguage(fetchedArticle.parameters.language);
            setStyles(fetchedArticle.parameters.styles);
            setModel(fetchedArticle.parameters.model);

        } else {
            let new_article = getTemplate('default_article');
            setArticle(new_article);
            setArticles((prevArticles) => {
                if (prevArticles.length > 0) {
                    const lastIndex = prevArticles.length - 1;
                    const updatedArticles = [...prevArticles];
                    updatedArticles[lastIndex] = new_article;
                    return updatedArticles;
                }
                return prevArticles;
            });

        }
    };


    useEffect(() => {
        if (articleId) {
            handleArticleSelection(articleId);
        }
    }, [articleId]);

    async function startNewArticle() {
        setNewArticleSelected(true);
        let newArticle = getTemplate('default_article');
        setArticle(newArticle);
        setArticles((prevArticles) => [...prevArticles, {'id': newArticle.id, 'title': newArticle.title}]);
    }


    async function onDeleteArticle(articleId) {
        deleteArticle(articleId);
        setArticles(articles.filter(article => article.id !== articleId));
    }


    return (
        <>
            <Helmet>
                <title>Your Ultimate AI Writing Assistant | Write Smarter, Faster, and Better</title>
                <meta name="description"
                      content="Discover the power of our AI writing assistant app. From generating unique ideas to refining your writing style, our app empowers you to produce high-quality content effortlessly and achieve your writing goals."/>
                <meta property="og:title"
                      content="Your Ultimate AI Writing Assistant | Write Smarter, Faster, and Better"/>
                <meta property="og:description"
                      content="Discover the power of our AI writing assistant app. From generating unique ideas to refining your writing style, our app empowers you to produce high-quality content effortlessly and achieve your writing goals."/>
            </Helmet>
            {isMobile ? (
                <>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 16,
                            ml: 2,
                            left: "calc(50% - min(620px, 50%))",
                        }}
                    >
                        <Box component={Link} to="/" className="clickable-logo">
                            <MyLogo className="my-logo"/>
                        </Box>
                    </Box>
                    <Box sx={{
                        marginTop: '40vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{width: 240}}>
                            This app is currently compatible with desktop devices only.
                        </Box>

                    </Box>
                </>
            ) : (
                <>
                    <ThemeProvider theme={theme}>
                        <SideMenu
                            articles={articles}
                            onArticleSelect={handleArticleSelection}
                            startNewArticle={startNewArticle}
                            onDeleteArticle={onDeleteArticle}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                        />
                    </ThemeProvider>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100vh",
                            width: "100vw",
                            ml: 0,
                            padding: 0,
                            boxSizing: "border-box"
                        }}
                    >
                        <Box
                            sx={{
                                width: 700,
                                boxSizing: "border-box",
                                maxWidth: "100vw",
                                ml: 2,
                                mr: 3.5,
                            }}
                        >
                            <Box sx={{mt: 3}}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 2,
                                        mb: 2,
                                        ml: -10
                                    }}
                                >
                                    <ArticleTypeSelect
                                        articleType={articleType}
                                        setArticleType={setArticleType}
                                    />
                                    {!scientificMode && (
                                        <WritingStyleSelect
                                            articleType={articleType}
                                            style={styles}
                                            setStyle={setStyles}
                                        />)
                                    }
                                    <LanguageSelect language={language} setLanguage={setLanguage}/>
                                    <ModelSelect model={model} setModel={setModel} subscriptionType={subscriptionType}/>
                                </Box>
                                <Divider sx={{mb: 1, ml: -10, mr: -13.5}}/>
                                <Article article={article} setArticle={setArticle} scientificMode={scientificMode}
                                         loggedIn={loggedIn}
                                         references={references}
                                         mendeleyAccessToken={mendeleyAccessToken}/>
                            </Box>
                        </Box>
                    </Box>
                    <ThemeProvider theme={theme}>
                        <ReferencesMenu
                            references={references}
                            setReferences={setReferences}
                            onReferenceSelect={null}
                            onUploadReferences={null}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                            mendeleyAccessToken={mendeleyAccessToken}
                            setMendeleyAccessToken={setMendeleyAccessToken}
                        />
                    </ThemeProvider>
                </>
            )}
        </>
    );
};

export default ArticleAssistant;
