import React, { useState } from 'react';
import '../styles/QuestionsLayout.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const QuestionBox = ({ questionText, questionAnswer, visible, onArrowClick }) => {
  return (
    <div className="question-box">
      <div className="top-row" onClick={onArrowClick}>
        <div className="question">{questionText}</div>
        <div className="arrow" >
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <div className={`answer${visible ? ' visible' : ''}`}>
        {questionAnswer}
      </div>
    </div>
  );
};

const QuestionsLayout = () => {
  const [visibleAnswer, setVisibleAnswer] = useState(-1);

  const handleArrowClick = (index) => {
    setVisibleAnswer(visibleAnswer === index ? -1 : index);
  };

  const questions = [
    'What can I create with Phraiser.io?',
    'Is the content from Phraiser.io original?',
    'What are the available payment methods?',
    'Can I get a free demo?',
    'What languages does it support?',
    "What happens if I hit my plan’s word limits?",
    'How do I cancel my paid plan?',
    'What is a refund policy?',
  ];

  const answers = [
    `Phraiser.io empowers you to create customized content by selecting the article type, writing style, and AI engine version (ChatGPT3.5 or ChatGPT4.0). Structure your work by naming sections, then freely edit, save, adjust, and delete within the app. Experience an inspirational, user-friendly platform designed to streamline high-quality content creation and organization.`,
    "Yes, the content generated by Phraiser.io is original. Leveraging the power of advanced AI models, Phraiser.io creates unique output from the input parameters you provide. These AI models are trained on a broad range of internet text but do not simply copy from their training data. Instead, they generate original responses based on patterns and information they've learned during training.",
    'You can create a variety of content with Phraiser.io. You can create content for your website, blog, social media, and more. You can also create content for your business, such as product descriptions, blog posts, and more...',
    `Absolutely, Phraiser.io offers a free usage tier that allows you to use up to 4000 words each month. This is a great way toget familiarized with the interface, the various features, and the quality of content that our AI can generate. If you wish to go beyond this limit and unlock the premium features of our app, you can opt for one of our paid subscriptions. Our subscription plans are designed to cater to different usage needs and also provide access to advanced functionalities for an enhanced content creation experience.`,
    "The PhrAIser platform operates with English, Polish, German, French, Spanish, Russian, Portuguese, Italian, Dutch, Hungarian, Czech, Slovak, Danish, Swedish, Norwegian, Finnish, Mandarin Chinese, Malay, Slovenian, Arabic, Indonesian, Urdu, Japanese, Swahili, Korean, Thai, Vietnamese, Hebrew, Persian, and Tagalog.",
    'If you exceed the word limits of your plan, the application will notify you in a friendly manner. At that point, you can choose to wait until the start of the new subscription month or upgrade your subscription plan to accommodate your needs.',
    'You can cancel your paid plan at any time during the month. If you cancel before your next billing cycle, you will not be charged again, and your subscription will end at the end of the current billing period.',
    "Our refund policy is designed to ensure customer satisfaction. If you feel disappointed with your experience on PhrAIser, you have the option to request a refund. We encourage you to provide a detailed description of your situation, and if possible, attach samples of your work using PhrAIser to help us understand your concerns better. Our team will review your case promptly, and if we find your situation genuine and valid, we will gladly issue a refund without hesitation. We strive to prioritize our customers' satisfaction and are committed to resolving any issues that may arise."
  ]

  return (
    <div className="container">
      <div className="column">
        {questions.slice(0, 4).map((question, index) => (
          <QuestionBox
            key={index}
            questionText={question}
            questionAnswer={answers[index]}
            visible={visibleAnswer === index}
            onArrowClick={() => handleArrowClick(index)}
          />
        ))}
      </div>
      <div className="column">
        {questions.slice(4).map((question, index) => (
          <QuestionBox
            key={index + 4}
            questionText={question}
            questionAnswer={answers[index + 4]}
            visible={visibleAnswer === index + 4}
            onArrowClick={() => handleArrowClick(index + 4)}
          />
        ))}
      </div>
    </div>
  );
};

export default QuestionsLayout;