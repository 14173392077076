const product_review = {
    "id": -1,
    "title": "Enter your product review title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Product Review", 'id': 'product_review'},
        "language": "English",
        "styles": ["Informative", "Descriptive", "Analytical"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the product and provide a brief overview of its purpose.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Key Features",
            "content": "Describe the product's key features and their benefits.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Feature 1",
                    "content": "Explain the first key feature and its benefits.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "2": {
                    "id": "2.2.",
                    "name": "Feature 2",
                    "content": "Explain the second key feature and its benefits.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "3": {
                    "id": "2.3.",
                    "name": "Feature 3",
                    "content": "Explain the third key feature and its benefits.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Pros and Cons",
            "content": "Discuss the product's advantages and disadvantages.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "3.1.",
                    "name": "Pros",
                    "content": "List the positive aspects of the product.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                },
                "2": {
                    "id": "3.2.",
                    "name": "Cons",
                    "content": "List the negative aspects of the product.",
                    "context": "",
                    "min_words": 50,
                    "max_words": 100,
                    "subpoints": {}
                }
            }
        },
        "4": {
            "id": "4.",
            "name": "Conclusion",
            "content": "Provide a final recommendation based on the product's features, benefits, and drawbacks.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default product_review;