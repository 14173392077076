import React, { useRef, useEffect } from 'react';
import Icon1 from "../resources/icons/icon_puzzle.svg";
import Icon2 from "../resources/icons/icon_bell.svg";
import Icon3 from "../resources/icons/icon_vanity_bag.svg";
import Icon4 from "../resources/icons/icon_ai.svg";

const ScrollableComponent = () => {
  const scrollableRef = useRef(null);

  useEffect(() => {
    const scrollable = scrollableRef.current;

    let isMouseDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (event) => {
      event.preventDefault();
      isMouseDown = true;
      scrollable.style.cursor = 'grabbing';
      startX = event.pageX - scrollable.offsetLeft;
      scrollLeft = scrollable.scrollLeft;
    };

    const handleMouseMove = (event) => {
      if (!isMouseDown) return;
      event.preventDefault();
      const x = event.pageX - scrollable.offsetLeft;
      const walk = (x - startX) * 2;
      scrollable.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      isMouseDown = false;
      scrollable.style.cursor = 'grab';
    };

    const handleMouseLeave = () => {
      isMouseDown = false;
      scrollable.style.cursor = 'grab';
    };

    scrollable.addEventListener('mousedown', handleMouseDown);
    scrollable.addEventListener('mousemove', handleMouseMove);
    scrollable.addEventListener('mouseup', handleMouseUp);
    scrollable.addEventListener('mouseleave', handleMouseLeave);

    // Clean up event listeners on component unmount
    return () => {
      scrollable.removeEventListener('mousedown', handleMouseDown);
      scrollable.removeEventListener('mousemove', handleMouseMove);
      scrollable.removeEventListener('mouseup', handleMouseUp);
      scrollable.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div ref={scrollableRef} className="outer-container scrollable">
                              <div className="container-boxes">
                                <div className="box">
                                  <img src={Icon1} alt="Icon1" className="icons1"/>
                                  <h3 className="header3lvl">Content marketing</h3>
                                  <h5 className="content-quatro-boxes">
                                    You already know the topic but don’t have idea how to build and
                                    write full article? Now you don’t have to worry. Phraiser will
                                    generate full text on the provided topic. Copy the text, add
                                    final touches and You are good to go!
                                  </h5>
                                  <h5 className="violet-content">Check examples</h5>
                                </div>
                                <div className="box">
                                <img src={Icon2} alt="Icon1" className="icons1"/>
                                  <h3 className="header3lvl">Search Engine Optimization</h3>
                                  <h5 className="content-quatro-boxes">
                                    Write and optimize the content in seconds. Generate original
                                    text that will boost your site visibility in organic search. It
                                    is quick and effective way to rank high in SERP.
                                  </h5>
                                  <h5 className="violet-content">Check examples</h5>
                                </div>
                                <div className="box">
                                  <img src={Icon3} alt="Icon1" className="icons1"/>
                                  <h3 className="header3lvl">Random articles</h3>
                                  <h5 className="content-quatro-boxes">
                                  Don’t have idea what to write about? Phraiser will generate it
                                    for You:    <ul>
                                                  <li>Random title</li>
                                                  <li>Synopsis to the title</li>
                                                  <li>Full content divided</li>
                                                </ul>
                                  </h5>
                                  <h5 className="violet-content">Check examples</h5>
                                </div>
                                <div className="box">
                                  <img src={Icon4} alt="Icon1" className="icons1"/>
                                  <h3 className="header3lvl">Original research</h3>
                                  <h5 className="content-quatro-boxes">
                                    Looking for professional research sudy that You can use in
                                    specific media or research magazines? Phraiser will generate
                                    100% original research study, written with professional language
                                    that will stun the readers. Ready for trophies? :){" "}
                                  </h5>
                                  <h5 className="violet-content">Check examples</h5>
                                </div>
                              </div>
                            </div>
  );
};

export default ScrollableComponent;