import React, {useState, useEffect} from 'react';
import {Box, Typography} from "@mui/material";
import './MagicPenLoader.css';


const loadingTexts = [
    "Organizing my neural pathways for optimal writing power!",
    "Filling my circuits with creativity juice, hold on tight!",
    "Searching for the right words...like a literary Indiana Jones!",
    "Summoning my muse...she's taking a bit to respond!",
    "Creating a word tornado...prepare to be blown away!",
    "Unlocking the secrets of the universe...or at least a clever opening sentence!",
    "Scouring the depths of my imagination...it's dark down here!",
    "Waiting for lightning to strike...or at least a good idea!",
    "Analyzing millions of articles... okay, maybe just a few... for inspiration!",
    "Accessing my inner Hemingway...be patient!",
    "Forging a path to literary greatness...step by step!",
    "Gathering all the best writing clichés...and avoiding them like the plague!",
    "Going where no writer has gone before...except maybe Shakespeare!",
    "Reaching deep into my writer's toolbox...and pulling out a metaphor!",
    "Seeking out the perfect turn of phrase...it's like finding a needle in a haystack!",
    "Tapping into the rhythm of language...it's like a literary dance!",
    "Forging a bond with my readers...it's like we're in this together!",
    "Probing the depths of my writer's soul...it's a deep and mysterious place!",
    "I'm pouring my heart and soul into this article, but first, let me pour some coffee.",
    "Finding the perfect words is like finding a needle in a haystack, but don't worry, I've got a magnet.",
    "I'm not procrastinating, I'm just creating anticipation for the article. You're welcome.",
    "It takes a village to write an article, so let me go round up my inner village",
    "Creating quality content is like climbing a mountain, but at least there's no altitude sickness in this office.",
    "The article is brewing, but it's not coffee, it's content. Close enough, right?",
    "I'm not just writing an article, I'm creating a masterpiece, so hold on to your hats!",
    "My creativity is at an all-time high, but I'm still waiting for my boss to give me a raise.",
    "I'm not just writing an article, I'm sculpting it, like Michelangelo with his chisel and marble.",
    "I'm not just writing an article, I'm building a universe, like J.K. Rowling with her wand and spells.",
    "I'm fine-tuning my writing skills for your reading pleasure.",
    "The gears of creativity are spinning, hold on for the ride.",
    "My writing fingers are doing their warm-up exercises.",
    "I'm brewing up some wordsmithing wizardry for you.",
    "I'm tinkering with the alchemy of language, just for you.",
    "I'm channeling my inner writing ninja to bring you something truly amazing."

];

const MagicPenLoader = () => {
    const [loadingText, setLoadingText] = useState(loadingTexts[Math.floor(Math.random() * loadingTexts.length)]); // initial loading text
    const loaderStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        fontSize: 20
    };

    const contentBoxStyle = {
        // backgroundColor: '#f8f6f6', // Change this to your desired background color
        borderRadius: '10px', // Adjust the border radius if needed
        // padding: -2, // Adjust the padding if needed

        textAlign: 'center',
        width: 1200,
        height: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    };


    useEffect(() => {
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * loadingTexts.length); // generate a random index from the array of loading texts
            setLoadingText(loadingTexts[randomIndex]); // set the loading text to the random text
        }, 5000); // change the text every 3 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div style={loaderStyle}>
            <div style={contentBoxStyle}>
                {/*<h1 style={{marginLeft: 0}} className="title">*/}
                {/*    Generating content*/}
                {/*</h1>*/}
                <div style={{transform: 'scale(0.6)'}} className="rainbow-marker-loader"></div>
                <h5 style={{'margin-top': 10}}>
                    {loadingText} {/* display the current loading text */}
                </h5>
            </div>

        </div>
    );
};

export default MagicPenLoader;
