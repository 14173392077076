const travelogue = {
    "id": -1,
    "title": "Enter your travelogue article title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Travelogue", 'id': 'travelogue'},
        "language": "English",
        "styles": ["Narrative", "Descriptive", "Conversational"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the destination and provide a brief overview of the trip or journey.",
            "context": "",
            "min_words": 40,
            "max_words": 80,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Notable Experiences",
            "content": "Recount memorable experiences or encounters during the trip.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Insights",
            "content": "Share personal insights or reflections gained from the trip.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Recommendations",
            "content": "Offer recommendations for activities, attractions, or accommodations at the destination.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the key points of the travelogue and provide a final reflection on the journey.",
            "context": "",
            "min_words": 40,
            "max_words": 80,
            "subpoints": {}
        }
    }
}


export default travelogue;