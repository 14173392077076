import {API, Auth} from 'aws-amplify';

export default async function deleteArticle(articleId) {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.get('phraiser', `/delete-article?article_id=${articleId}&user_id=${userId}`);
        return response;
    } catch (error) {
        console.error('Error fetching article:', error);
    }
}