import React from "react";
import "./styles/regular_screens.css";
import {ReactComponent as MyLogo} from "./phrAIser.io.svg";
import {ReactComponent as Header2} from "./resources/header2.svg";
import {ReactComponent as Lapek1} from "./resources/Lapek.svg"; // This is the SVG file
import ElementDimensionSync from "./components/ElementDimensionSync.js";
import ScrollableComponent from "./components/click_scrollable_div.js";
import header from "./resources/header2.svg";
import Icon1 from "./resources/icons/icon_puzzle.svg";
import Icon2 from "./resources/icons/icon_bell.svg";
import Icon3 from "./resources/icons/icon_vanity_bag.svg";
import Icon4 from "./resources/icons/icon_ai.svg";
import Infolayer1 from "./resources/layer2/info_layer_1.svg";
import Potential_long_div from './resources/mobile/potential_long_div.svg';
import imagevioletpic from "./resources/layer2/PhotoVioletBlock.svg";
import phraiserchart from "./resources/layer2/phraiser-chart.svg";
import Footer from "./components/footer";
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import './styles/queries_responses.css';
import ShiftingContent from './components/sliding_reviews';
import BenefitsOfPhraiser from './components/benefits_of_phraiser';
import Violet_box_stworek from "./components/violet_box_stworek";
import PlayScreen2 from "./resources/playscreen.png";
import {Helmet} from 'react-helmet';

window.addEventListener('load', function () {
    document.body.classList.add('loaded');
});


function Landing() {
    return (
        <div className="landing-page">
            <Helmet>
                <title>Phraiser: Personal AI Writing Assistant | Boost Your Writing Efficiency</title>
                <meta name="description"
                      content="Experience the next level of writing productivity with our AI writing assistant app. Enhance your creativity, improve grammar, and generate high-quality content effortlessly."/>
                <meta property="og:title"
                      content="Phraiser: Personal AI Writing Assistant | Boost Your Writing Efficiency"/>
                <meta property="og:description"
                      content="Experience the next level of writing productivity with our AI writing assistant app. Enhance your creativity, improve grammar, and generate high-quality content effortlessly."/>
            </Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
            <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'/>
            <div className="landing-page-body">
                <div className="landing-page-body-gradient-container">
                    <h3 className="header1-h3">YOUR PERSONAL WRITING ASSISTANT</h3>
                    <div>
                        <h1 className="header1-h1">
                            Unlock the Power of AI for Better Writing
                        </h1>
                    </div>
                    <div className="buttons2">
                        <div className="landing-page-button-container-2">
                            <Button
                                component={Link}
                                to="/app"
                                variant="outlined"
                                color="primary"
                                size="large"
                                sx={{
                                    width: 170,
                                    borderRadius: '25px',
                                    mr: 2,
                                    borderColor: 'white',
                                    color: 'white',
                                    fontSize: 'calc(15 / 1440 * 100vw)',
                                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                                }}
                                className='second-tryfree-button'
                            >
                                TRY FOR FREE
                            </Button>
                            <Button component={Link}
                                    to="/upgrade"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    sx={{
                                        width: 170,
                                        borderRadius: '25px',
                                        backgroundColor: '#CB10A6',
                                        fontSize: 'calc(15 / 1440 * 100vw)',
                                        "&:hover": {
                                            backgroundColor: '#aa0d8a!important' // Change the background color when hovering over the button
                                        }
                                    }
                                    }
                                    className="landing-page-signup-btn-2">
                                START NOW
                            </Button>
                        </div>
                        {/* <ElementDimensionSync
                                element1={document.querySelector(".landing-page-tryfree-btn")}
                                element2={document.querySelector(".landing-page-tryfree-btn")}
                            /> */}
                    </div>
                </div>
                <div className="rounded-div-white" style={{'margin-top': 0}}>
                    <Lapek1 className="lapek1" preserveAspectRatio="width"/>
                </div>
                <div className="landing-page-body-grey-container">
                    <div className="header2" style={{'margin-top': 150}}>
                        <span>Discover Phr<span className="Im_blue_daboodee">ai</span>ser in action</span>
                    </div>
                    <Violet_box_stworek/>
                    <div className="violet-gradient-container-2">
                        <div className="violet-gradient-text-container-2">
                                    <span className="endless-phrase-1">
                                      ENDLESS WRITING POSSIBILITIES
                                    </span>
                            <div className="endless-phrase-2">
                                {/* <div className="endless-phrase-2-container"> */}
                                AI writing platform made for researchers, copywriters, marketers and
                                business professionals.
                                {/* </div> */}
                            </div>
                            <span className="endless-phrase-3">

                                    </span>
                            <div className="div-playscreen">
                                <img src={PlayScreen2} alt="Playscreen" className="playscreen"/>
                            </div>
                            <span className="endless-phrase-4">
                                      <div className="inline-block-endless-phrase-4">
                                        <div className="endless-phrase-4-innnercontainer">
                                          <Button
                                              component={Link}
                                              to="/app"
                                              variant="contained"
                                              color="secondary"
                                              size="large"
                                              sx={{
                                                  width: 170,
                                                  borderRadius: '25px',
                                                  mr: 2,
                                                  borderColor: 'white',
                                                  color: 'white',
                                                  "&:hover": {
                                                      backgroundColor: '#aa0d8a!important' // Change the background color when hovering over the button
                                                  }
                                              }}
                                              className='landing-page-tryfree-btn-3'
                                          >
                                            TRY FOR FREE
                                          </Button>
                                          <span className="endless-phrase-5">
                                          Free registration. No credit card required.
                                          </span>
                                        </div>
                                      </div>
                                    </span>
                        </div>
                    </div>
                    <h2 className="header3">
                        Features so good that they will change the way you write content
                    </h2>
                    <ScrollableComponent/>
                    <div className="landing-page-body-quatro-vertical-split-container">
                                                <span className="landing-page-body-single-quatro-box">
                <img src={Icon4} alt="Icon1" className="icons1"/>
                <h3 className="header3lvl">Research - reliable referencing</h3>
                <h5 className="content-quatro-boxes">
                  Say goodbye to the hassle of managing references manually. Our tool seamlessly integrates your documents, enabling you to effortlessly compile citations and create impactful scientific articles.{" "}
                </h5>
                <h5 className="violet-content">Check examples</h5>
              </span>
                        <span className="landing-page-body-single-quatro-box">
                          <img src={Icon1} alt="Icon1" className="icons1"/>
                          <h3 className="header3lvl">Content marketing</h3>
                          <h5 className="content-quatro-boxes">
                            You already know the topic but don’t have idea how to build and
                            write full article? Now you don’t have to worry. Phraiser will
                            generate full text on the provided topic. Copy the text, add
                            final touches and You are good to go!
                          </h5>
                          <h5 className="violet-content">Check examples</h5>
                        </span>
                        <span className="landing-page-body-single-quatro-box">
                          <img src={Icon2} alt="Icon1" className="icons1"/>
                          <h3 className="header3lvl">Search Engine Optimization</h3>
                          <h5 className="content-quatro-boxes">
                            Write and optimize the content in seconds. Generate original
                            text that will boost your site visibility in organic search. It
                            is quick and effective way to rank high in SERP.
                          </h5>
                          <h5 className="violet-content">Check examples</h5>
                      </span>
                        <span className="landing-page-body-single-quatro-box">
                        <img src={Icon3} alt="Icon1" className="icons1"/>
                        <h3 className="header3lvl">Random articles</h3>
                        <h5 className="content-quatro-boxes">
                          Don’t have idea what to write about? Phraiser will generate it
                          for You:              <ul>
                                                    <li>Random title</li>
                                                    <li>Synopsis to the title</li>
                                                    <li>Full content divided</li>
                                                </ul>
                        </h5>
                        <h5 className="violet-content">Check examples</h5>
                      </span>

                    </div>

                    <div className="last-white-box-container">
                        <div className="last-white-box-1">
                            <span className="header4">BENEFITS OF PHRAISER</span>
                            <div className="header5">
                                How will Phraiser unlock<br/> your writing potential?
                            </div>
                        </div>
                        <BenefitsOfPhraiser/>
                    </div>
                </div>
                <div className="violet-box-reviews">
                    <ShiftingContent/>
                    {/* <span className="header6">REVIEWS</span>
                  <div className="content6">
                      I used nearly every AI writing tool on the market and I always come
                      back to Phraiser. It provides the most user-friendly interface and
                      has highest quality of content generated. It just feel like written
                      100% by human.
                  </div>
                  <div className="name6">Luke Kling</div>
                  <div className="subcontent6">Affiliate Community Leader</div>
                  <div className="name6">
                      <img src={aflilogo} className="name6 aflilogo"/>
                  </div> */}
                </div>
                <div>
                    <div className="landing-page-last-grey-space-container-mobile">
                        <div className="items-aligner-container-1">

                            <div className="research-section-column">
                                <div className="two-vertical-divs">
                                    <div className="research-section-div-up">
                                        <div className="violet-mid-text">
                                            COMPOSE ARTICLES OF THE HIGHEST QUALITY
                                        </div>
                                        <div className="central-header-content-marketing">
                                            Content marketing made easy
                                        </div>
                                        <div className="horizontal-inner-div1">
                                            <div className="message-last-grey-box-2">
                                                You are in good hands! Use the tool to improve your writing,
                                                write articles on random topic, rephrase the text or augment the
                                                text to make it longer and more complex.
                                                <ul>
                                                    <li>Video tutorials</li>
                                                    <li>Live webinars</li>
                                                    <li>Dedicated support</li>
                                                    <li>Use cases</li>
                                                    <li>Blog posts</li>
                                                </ul>
                                            </div>
                                            <img
                                                src={imagevioletpic}
                                                alt="Icon5"
                                                className="imagevioletpic"
                                            />
                                        </div>
                                    </div>
                                    <div className="research-section-div-down">
                                        <div className="centered-violet-original-title">
                                            ORIGINAL CONTENT WRITTEN IN SECONDS
                                        </div>
                                        <div className="central-header-content-marketing">
                                            Easy way to write unique text
                                        </div>
                                        <div className="horizontal-inner-div2">
                                            <img src={phraiserchart} alt="Icon5" className="Icon6"/>
                                            <div className="message-last-grey-box-2">
                                                Forget long hours spent on content writing. Use reliable AI
                                                power to get original text that you can use on social media, on
                                                your blog or even as a research papers published on well-known
                                                scientific magazines.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landing-page-last-grey-space-container">
                    <div className="landing-page-last-grey-space-first-box">
                    <span className="first-box-left">
                      <div className="items-aligner-container-2">
                        <div className="violet-mid-text">
                          WRITE ORIGINAL RESEARCH & CASE STUDIES
                        </div>
                        <div className="central-header-content-marketing">
                          Content marketing<br/>made easy
                        </div>
                        <div className="message-last-grey-box">
                          You are in good hands! Use the tool to improve<br></br>your writing,
                          write articles on random topic,<br></br>rephrase the text or augment the
                          text to make it<br></br>longer and more complex.
                          <ul>
                            <li>Video tutorials</li>
                            <li>Live webinars</li>
                            <li>Dedicated support</li>
                            <li>Use cases</li>
                            <li>Blog posts</li>
                          </ul>
                        </div>
                      </div>
                    </span>
                        <span className="first-box-right">
                      <img
                          src={imagevioletpic}
                          alt="Icon5"
                          className="imagevioletpic"
                      />
                    </span>
                    </div>
                    <div className="second-box-of-last-grey-container">
                        <div className="phraiserchart">
                            <img src={phraiserchart} alt="Icon5" className="Icon5"/>
                        </div>

                        <span className="second-box-right">
                        <div className="items-aligner-container">
                          <div className="centered-violet-original-title">
                            ORIGINAL CONTENT WRITTEN IN SECONDS
                          </div>
                          <div className="central-header-content-marketing">
                            Easy way to write <br></br> unique text
                          </div>
                          <div className="message-last-grey-box-2">
                            Forget long hours spent on content writing. Use reliable AI
                            power to get original text that you can use on social media, on
                            your blog or even as a original research published on well-known
                            scientific magazines.
                          </div>
                        </div>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;
