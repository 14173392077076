import React from "react";
import {MenuItem, FormControl, InputLabel, Select, Box, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";

const ModelSelect = ({model, setModel, subscriptionType}) => {
    return (
        <Box sx={{mt: 0}}>
            <FormControl variant="outlined"
                         size="small"
                         sx={{minWidth: 200}}>
                <InputLabel htmlFor="language-select">AI model</InputLabel>
                <Select
                    label="AI model"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    inputProps={{
                        name: "model",
                        id: "model-select",
                    }}
                >
                    <MenuItem
                        key="gpt-3.5-turbo"
                        value="gpt-3.5-turbo"
                    >
                        <Tooltip
                            title="Excellent high-performance model"
                            placement="right"
                            arrow
                            enterDelay={300}
                            leaveDelay={0}
                        >
                            <span>GPT-3.5</span>
                        </Tooltip>
                    </MenuItem>
                    <MenuItem
                        key="gpt-4"
                        value="gpt-4"
                        disabled={subscriptionType !== 'premium'} // Disable the GPT-4 menu item if subscriptionType is not 'premium'
                    >
                        <Tooltip
                            title="Slower model but provides improved coherence, creativity and context understanding. 99.9% of unique content."
                            placement="right"
                            arrow
                            enterDelay={300}
                            leaveDelay={0}
                        >
                            <span>GPT-4</span>
                        </Tooltip>
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default ModelSelect;
