import {API, Auth} from 'aws-amplify';

const deleteDocument = async (documentId) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.post('phraiser', '/delete-document', {
            body: {
                user_id: userId,
                document_id: documentId
            },
        });
    } catch (error) {
        console.error('Error fetching documents list:', error);
    }
};

export default deleteDocument;