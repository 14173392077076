import {API, Auth} from 'aws-amplify';
import getArticle from './getArticle';

const update_article = async (
    article,
    setArticle,
    updateArticle = true,
    commands,
    setWaitingForUpdate,
    setUsageExceeded,
    mendeleyAccessToken = null
) => {
    try {
        setWaitingForUpdate(true);

        let userId = null;
        try {
            const user = await Auth.currentAuthenticatedUser();
            userId = user.username;
        } catch (error) {

        }

        try {
            const response = await API.post('phraiser', '/write-article', {
                body: {
                    article: article,
                    files: [],
                    commands: commands,
                    userId: userId,
                    mendeleyAccessToken: mendeleyAccessToken
                },
            });
            // console.log(commands, 'article received', response);
        } catch (error) {
            console.error('Error sending article update request:', error);
            setWaitingForUpdate(false);

            // Check if the status code is 402 (Usage exceeded/Payment Required)
            if (error.response && error.response.status === 402) {
                setUsageExceeded(true);
                return null;
            }
        }

        const pollArticle = async (articleId, retries = 600, interval = 1000) => {
            if (retries <= 0) {
                console.error('Error: Timeout while polling for article update');
                return;
            }

            const updatedArticle = await getArticle(articleId);

            if (updatedArticle && updatedArticle.status === 'COMPLETED') {
                if (updateArticle) {
                    setArticle({...updatedArticle});
                }

                setWaitingForUpdate(false);
                return updatedArticle;
            } else {
                setTimeout(() => pollArticle(articleId, retries - 1, interval), interval);
            }
            return updatedArticle;
        };

        const updatedArticle = pollArticle(article.id);
        return updatedArticle;
    } catch (error) {
        console.error('Error sending article update request:', error);
        setWaitingForUpdate(false);
    }
};


export default update_article;
