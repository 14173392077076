import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Lato, sans-serif',
        fontWeightLight: 900
    },
    palette: {
        primary: {
            main: '#4299CB',
        },
        secondary: {
            main: '#CB10A6',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    '&:first-letter': {
                        textTransform: 'uppercase',
                    },
                },
            },
        },
    },
});

export default theme;
