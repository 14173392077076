const opinion_piece = {
    "id": -1,
    "title": "Enter your opinion piece title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Opinion Piece", 'id': 'opinion_piece'},
        "language": "English",
        "styles": ["Argumentative", "Persuasive", "Conversational"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the topic and state the author's viewpoint.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {
                "1": {
                    "id": "1.1.",
                    "name": "Topic",
                    "content": "Briefly describe the topic of the opinion piece.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                },
                "2": {
                    "id": "1.2.",
                    "name": "Viewpoint",
                    "content": "Clearly state the author's opinion on the topic.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                }
            }
        },
        "2": {
            "id": "2.",
            "name": "Argument 1",
            "content": "Present the first argument to support the author's viewpoint.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Argument 2",
            "content": "Present the second argument to support the author's viewpoint.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Argument 3",
            "content": "Present the third argument to support the author's viewpoint.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Counterargument",
            "content": "Acknowledge a counterargument and provide a rebuttal to strengthen the author's viewpoint.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "6": {
            "id": "6.",
            "name": "Conclusion",
            "content": "Summarize the author's viewpoint and arguments, and offer a final thought or call to action.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default opinion_piece;