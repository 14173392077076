const social_media_post = {
    "id": -1,
    "title": "Enter your social media post title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Social Media Post", 'id': 'social_media_post'},
        "language": "English",
        "styles": [
            "Conversational",
            "Informative",
            "Persuasive"
        ],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Engaging Statement",
            "content": "Begin the post with an attention-grabbing statement or question.",
            "context": "",
            "min_words": 5,
            "max_words": 15,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Main Message",
            "content": "Convey the main idea, news, or promotion in a concise and clear manner.",
            "context": "",
            "min_words": 15,
            "max_words": 40,
            "subpoints": {
                "1": {
                    "id": "2.1.",
                    "name": "Relevance",
                    "content": "Explain the relevance or importance of the main message to the target audience.",
                    "context": "",
                    "min_words": 5,
                    "max_words": 20,
                    "subpoints": {}
                },
                "2": {
                    "id": "2.2.",
                    "name": "Call to Action",
                    "content": "Include a call to action that encourages readers to take a specific action or learn more.",
                    "context": "",
                    "min_words": 5,
                    "max_words": 15,
                    "subpoints": {}
                }
            }
        },
        "3": {
            "id": "3.",
            "name": "Visual Content",
            "content": "Mention any visual content, such as images or videos, that will accompany the post.",
            "context": "",
            "min_words": 5,
            "max_words": 15,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Hashtags and Mentions",
            "content": "Include relevant hashtags and mentions to increase the post's visibility and reach.",
            "context": "",
            "min_words": 5,
            "max_words": 15,
            "subpoints": {}
        }
    }
}

export default social_media_post;