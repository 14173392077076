const film_review = {
    "id": -1,
    "title": "Enter your film review title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Film Review", 'id': 'film_review'},
        "language": "English",
        "styles": ["Informative", "Analytical", "Descriptive"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the film, its director, and provide a brief overview of the movie's main themes or ideas.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Plot Summary",
            "content": "Summarize the plot without giving away too many spoilers.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Characters and Acting",
            "content": "Discuss the main characters and the quality of the acting.",
            "context": "",
            "min_words": 50,
            "max_words": 150,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Technical Aspects",
            "content": "Analyze the film's technical aspects, such as cinematography, sound, editing, and special effects.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Personal Opinion",
            "content": "Share your personal opinion on the film, and explain why you liked or disliked it.",
            "context": "",
            "min_words": 50,
            "max_words": 150,
            "subpoints": {}
        },
        "6": {
            "id": "6.",
            "name": "Conclusion",
            "content": "Summarize the review, and provide a final recommendation for potential viewers.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default film_review;