const trend_analysis = {
    "id": -1,
    "title": "Enter your trend analysis title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Trend Analysis", 'id': 'trend_analysis'},
        "language": "English",
        "styles": ["Informative", "Analytical", "Expository"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the current or emerging trend and set the context for the rest of the article.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Trend Origins",
            "content": "Discuss the origins of the trend, including any historical or cultural factors that have contributed to its development.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Implications",
            "content": "Examine the implications of the trend, including its potential impact on society, industry, or the environment.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Future Impact",
            "content": "Predict the future impact of the trend, considering factors such as technological advancements, market forces, or consumer behavior.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the key points discussed in the article and offer any final thoughts or perspectives on the trend.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default trend_analysis;