import {API, Auth} from 'aws-amplify';

const listArticles = async (setArticles, article) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.username;
        const response = await API.post('phraiser', '/list-articles', {
            body: {
                userId: userId
            },
        });
        setArticles([...response, article]);
    } catch (error) {
        console.error('Error fetching article list:', error);
    }
};

export default listArticles;