import React, {useEffect, useState} from "react";
import {Auth, Hub} from "aws-amplify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import {ReactComponent as MyLogo} from "../phrAIser.io.svg";
import {ThemeProvider} from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {useLocation} from 'react-router-dom';


import theme from "../theme.js";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";

const SignInForm = ({location_from}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [isConfirmationCodeSent, setIsConfirmationCodeSent] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const location = useLocation();
    const locationFrom = location.state?.locationFrom || '/';

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    // Redirect user to /app if signed in
                    navigate("/app");
                    break;
                case "signOut":
                    break;
                default:
                    break;
            }
        });

        Auth.currentAuthenticatedUser()
            .then(() => {
                // Redirect user to /app if signed in
                navigate("/app");
            })
            .catch(() => console.log("Not signed in"));

        return unsubscribe;
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        // Check if email is valid
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setErrorMessage("Invalid email address");
            return;
        }

        try {
            await Auth.signIn(email, password);
            navigate(locationFrom);
        } catch (error) {
            console.error("Error logging in:", error);
            setErrorMessage("Invalid email or password");
        }
    };

    const handleContinueWithEmail = (e) => {
        e.preventDefault();
        setErrorMessage("");
        // Check if email is valid
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setErrorMessage("Invalid email address");
            return;
        }
        setShowPasswordInput(true);
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        setErrorMessage("");
        setShowForgotPassword(true);
    };

    const handleSendResetLink = (e) => {
        e.preventDefault();
        setErrorMessage("");
        // Check if email is valid
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setErrorMessage("Invalid email address");
            return;
        }
        setShowForgotPassword(true);
        Auth.forgotPassword(email)
            .catch((error) => {
                console.error("Error sending forgot password email:", error);
                setErrorMessage("Failed to send forgot password email");
            });
        setIsConfirmationCodeSent(true);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
            setSnackbarMessage("Password reset successfully");
            setSnackbarOpen(true);
            setShowResetPassword(false);
            setIsConfirmationCodeSent(false);
            setShowForgotPassword(false);
        } catch (error) {
            console.error("Error resetting password:", error);
            if (error.code === "CodeMismatchException") {
                setErrorMessage("Invalid verification code provided, please try again.");
            } else {
                setErrorMessage("Failed to reset password");
            }
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleGoogleSignIn = (response) => {
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
    };


    useEffect(() => {
        if (window.google && window.google.accounts && window.google.accounts.id) {
            window.google.accounts.id.initialize({
                // client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                client_id: "1009242549028-lf97p92c473lnmrqgeu5dn46ah5mep5l.apps.googleusercontent.com",
                ux_mode: "popup",
                callback: handleGoogleSignIn,
            });
            window.google.accounts.id.renderButton(document.getElementById("google-signin-button"), {
                theme: "outline",
                size: "large",
                width: 350
            });
        }
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        ml: 2,
                        left: "calc(50% - min(620px, 50%))",
                    }}
                >
                    <Box component={Link} to="/" className="clickable-logo">
                        <MyLogo className="my-logo"/>
                    </Box>
                </Box>
                <Box
                    sx={{
                        marginTop: "28vh",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h3" sx={{fontWeight: "bold", mb: 2}}>
                        Log in
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleLogin}
                        sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}
                    >
                        <div
                            id="google-signin-button"
                            className="g_id_signin"
                            data-type="standard"
                            data-size="large"
                            data-theme="outline"
                            data-text="continue_with"
                            data-shape="rectangular"
                            data-width="240"
                            data-logo_alignment="left"
                        ></div>
                        <Divider sx={{my: 2, width: 350, mb: 0}}>or</Divider>
                        {!isConfirmationCodeSent && <TextField
                            margin="normal"
                            required
                            size="small"
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{width: 350, mb: 1}}
                        />}
                        {isConfirmationCodeSent && (
                            <>
                                <Box sx={{pl: 7, pr: 7}}>
                                    <Typography variant="body2" sx={{color: "grey", mt: 2}}>
                                        We just sent you a verification code. Please check your inbox and paste the
                                        verification code below to reset the password.
                                    </Typography>
                                </Box>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="confirmationCode"
                                    size="small"
                                    label="Confirmation Code"
                                    name="confirmationCode"
                                    autoComplete="off"
                                    autoFocus
                                    value={confirmationCode}
                                    onChange={(e) => setConfirmationCode(e.target.value)}
                                    sx={{width: 350}}
                                />
                            </>
                        )}
                        {showPasswordInput && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                size="small"
                                label="Password"
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{width: 350, mb: 2}}
                            />
                        )}
                        {errorMessage && (
                            <Typography
                                variant="body2"
                                php

                                color="error"
                                sx={{mt: 0, mb: 1, textAlign: "center"}}
                            >
                                {errorMessage}
                            </Typography>
                        )}

                        {showPasswordInput &&
                            <>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{mt: 0, width: 350, mb: 1}}
                                >
                                    Continue with password
                                </Button>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="primary"
                                    onClick={() => setShowPasswordInput(false)}
                                    sx={{mt: 1, mb: 2, width: 350}}
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                        {!showPasswordInput && !showForgotPassword &&
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="primary"
                                sx={{mt: 1, mb: 1, width: 350}}
                                onClick={handleContinueWithEmail}
                            >
                                Continue with email
                            </Button>
                        }
                        {!showPasswordInput && showForgotPassword && !isConfirmationCodeSent &&
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="primary"
                                sx={{mt: 1, mb: 1, width: 350}}
                                onClick={handleSendResetLink}
                            >
                                Send reset link
                            </Button>

                        }
                        {!showPasswordInput && !showForgotPassword && !isConfirmationCodeSent &&
                            <>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="primary"
                                    onClick={handleForgotPassword}
                                    sx={{mt: 1, width: 350}}
                                >
                                    Forgot password?
                                </Button>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="primary"
                                    onClick={() => navigate('/signup')}
                                    sx={{width: 350}}
                                >
                                    Don't have an account?
                                </Button>
                            </>
                        }
                        {isConfirmationCodeSent && (
                            <>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="newPassword"
                                    size="small"
                                    label="New Password"
                                    type="password"
                                    name="newPassword"
                                    autoComplete="off"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    sx={{width: 350}}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    sx={{mt: 1, mb: 1, width: 350}}
                                    onClick={handleResetPassword}
                                >
                                    Reset password
                                </Button>
                            </>
                        )}
                        {!showPasswordInput && showForgotPassword &&
                            <Button
                                fullWidth
                                variant="text"
                                color="primary"
                                onClick={() => {
                                    setShowForgotPassword(false);
                                    setIsConfirmationCodeSent(false);
                                }}
                                sx={{mt: 1, width: 350}}
                            >
                                Cancel
                            </Button>
                        }
                    </Box>
                </Box>
            </Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{width: "100%"}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>

    );

};

export default SignInForm;