import React from 'react';
import {Container, Typography} from '@mui/material';

const CookiePolicy = () => (
    <Container maxWidth="sm" sx={{mt: 15}}>
        <Typography variant="h3" component="h1" gutterBottom>
            Cookie Policy
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
            Effective Date: May 22, 2023
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            1. Use of Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
            Phraiser.io itself does not use cookies directly. However, we use Google services, including Google
            Analytics, which do use cookies to improve user experience and analyze website traffic. By using the
            Application, you consent to the use of these cookies.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            2. What Are Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
            Cookies are small text files that can be used by websites to make a user's experience more efficient. They
            are stored on your device when the website is loaded on your browser.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            3. How Google Uses Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
            Google uses cookies for several purposes, including to verify your identity, remember your preferences, and
            help you view pages that are most relevant to you. Also, cookies allow Google to analyze how users interact
            with its services. For more information, see Google's privacy policy.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            4. Managing Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
            You can manage or disable cookies using your browser settings. However, this might affect certain
            functionalities of the Application.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            5. Changes to the Cookie Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
            We may update this policy from time to time. Any changes will be posted on this page with an updated
            effective date.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            6. Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
            For any queries regarding this Cookie Policy, please contact us at support@phraiser.io.
        </Typography>
    </Container>
);

export default CookiePolicy;
