const how_to_guide = {
    "id": -1,
    "title": "Enter your how-to guide title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "How-To-Guide", 'id': 'how_to_guide'},
        "language": "English",
        "styles": ["Informative", "Expository", "Conversational"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the task or goal and explain its importance or relevance.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Materials or Tools",
            "content": "List any materials or tools needed to complete the task or achieve the goal.",
            "context": "",
            "min_words": 20,
            "max_words": 50,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Step-by-Step Instructions",
            "content": "Provide clear and concise step-by-step instructions for completing the task or achieving the goal.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {
                "1": {
                    "id": "3.1.",
                    "name": "Step 1",
                    "content": "Describe the first step in the process.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                },
                "2": {
                    "id": "3.2.",
                    "name": "Step 2",
                    "content": "Describe the second step in the process.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                },
                "3": {
                    "id": "3.3.",
                    "name": "Step 3",
                    "content": "Describe the third step in the process.",
                    "context": "",
                    "min_words": 20,
                    "max_words": 50,
                    "subpoints": {}
                }
            }
        },
        "4": {
            "id": "4.",
            "name": "Tips and Tricks",
            "content": "Offer additional tips, tricks, or advice to help readers complete the task more efficiently or effectively.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Conclusion",
            "content": "Summarize the guide and encourage readers to put the steps into action.",
            "context": "",
            "min_words": 30,
            "max_words": 50,
            "subpoints": {}
        }
    }
}

export default how_to_guide;