import React, { useState } from 'react';
import './styles/feature_support.css';


const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = event => setEmail(event.target.value);
  const handleMessageChange = event => setMessage(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();
    console.log('Email:', email);
    console.log('Message:', message);
    setEmail('');
    setMessage('');
  };

  return (
    <div className="contact-us-container cont-licence">
      <h1 class="header1 integHeader">Support</h1>
      <h3 className='integDescript'>Want to know more about features and how your team can use PhrAISER.io in daily operations? <span style={{ fontWeight: 'normal' }}>Contact us to get practical use cases for your team, answer your questions, and share pricing information...</span></h3>
      <form onSubmit={handleSubmit} className='about-form'>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={handleEmailChange}
          className="contact-input"
        />
        <textarea
          placeholder="Your Message"
          value={message}
          onChange={handleMessageChange}
          className="contact-input contact-textarea"
        />
        <button type="submit" className="contact-submit-button">Send Message</button>
      </form>
    </div>
  );
};

export default ContactUs;