import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Container, Typography,} from '@mui/material';
import {styled} from '@mui/system';
import {API, Auth} from 'aws-amplify';

import {loadStripe} from '@stripe/stripe-js';

const ContainerStyled = styled(Container)({
    marginTop: 20,
});

const PremiumPage = () => {
    const [transactionStatus, setTransactionStatus] = useState('idle');
    const [userSubscription, setUserSubscription] = useState(null);

    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            const user = await Auth.currentAuthenticatedUser();
            const userId = user.username;
            const subscription = await API.post('phraiser', '/get-subscription', {
                body: {
                    user_id: userId,
                },
            });
            setUserSubscription(subscription);
        };

        fetchSubscriptionDetails();
    }, []);

    const isPremiumActive = () => {
        if (!userSubscription) return false;
        const {premium, premium_expiration_date} = userSubscription;
        const now = new Date();
        const expirationDate = new Date(premium_expiration_date);
        return premium && now <= expirationDate;
    };

    const handleGetPremium = async () => {
        setTransactionStatus('waiting');

        try {
            const user = await Auth.currentAuthenticatedUser();
            const cognitoUserId = user.username;

            const response = await API.post('phraiser', '/get-stripe-session', {
                body: {
                    user_email: cognitoUserId,
                },
            });

            // Load and initialize Stripe
            const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            const stripe = await stripePromise;

            // Update user attributes with session id

            const stripeSessionId = response.id;
            const response2 = await API.post('phraiser', '/save-session-id', {
                body: {
                    user_id: cognitoUserId,
                    stripe_session_id: stripeSessionId
                },
            });

            // Redirect to Stripe Checkout
            await stripe.redirectToCheckout({
                sessionId: response.id
            });

            setTransactionStatus('completed');

        } catch (error) {
            console.error('Error calling Lambda function:', error);
            setTransactionStatus('error');
        }
    };

    return (
        <ContainerStyled maxWidth="md">
            <Typography variant="h4" gutterBottom>
                Get Premium Subscription
            </Typography>
            {/* ... */}
            <Box mt={4} textAlign="right">
                {isPremiumActive() ? (
                    <Typography variant="body1" color="primary">
                        Your premium subscription is active until {userSubscription.premium_expiration_date}.
                    </Typography>
                ) : (
                    <>
                        {transactionStatus === 'idle' && (
                            <Button variant="contained" color="primary" onClick={handleGetPremium}>
                                Get Premium
                            </Button>
                        )}
                        {transactionStatus === 'waiting' && (
                            <>
                                <CircularProgress size={24}/>
                                <Typography variant="body1" style={{marginLeft: 8}}>
                                    Waiting for transaction...
                                </Typography>
                            </>
                        )}
                        {transactionStatus === 'completed' && (
                            <Typography variant="body1" color="primary">
                                You have purchased a premium plan!
                            </Typography>
                        )}
                        {transactionStatus === 'error' && (
                            <Typography variant="body1" color="error">
                                An error occurred during the transaction. Please try again.
                            </Typography>
                        )}
                    </>
                )}
            </Box>
        </ContainerStyled>
    );
};

export default PremiumPage;
