import React, {useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import '../styles/cookies_consent.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {Link} from "react-router-dom";
import {Typography} from "@mui/material";

const CookieBanner = () => {
    const [cookies, setCookie] = useCookies(['cookieAccepted']);
    const [isVisible, setIsVisible] = useState(false);
    const [isPageEnabled, setIsPageEnabled] = useState(false);

    useEffect(() => {
        const cookieAccepted = cookies.cookieAccepted;
        setIsVisible(cookieAccepted === undefined);
        setIsPageEnabled(cookieAccepted === true);
    }, [cookies]);

    const handleAcceptAll = () => {
        setCookie('cookieAccepted', true, {path: '/'});
        setIsPageEnabled(true);
    };

    const handleRejectAll = () => {
        setCookie('cookieAccepted', false, {path: '/'});
        setIsPageEnabled(true);
    };

    if (!isVisible || isPageEnabled) {
        return null; // Render nothing if the banner is not visible or page is enabled
    }

    return (
        <>
            <Box className='cookie-banner'>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'}, // Stack vertically on small screens, horizontally on larger
                    alignItems: 'center',
                    justifyContent: 'space-between', // Add space between elements
                    width: ['100%', 900],
                    mx: 'auto'
                }}>
                    <Box sx={{
                        flex: 1, // Allow this box to grow
                        textAlign: 'left', // Align text to the left
                        mr: {sm: 5}, // Add margin on larger screens
                        fontSize: 14
                    }}>
                        <Typography variant="h6" align="left">
                            Cookie Policy
                        </Typography>
                        <p>
                            We use cookies to improve your browsing experience on our website, to analyze our website
                            traffic, and to understand where our visitors are coming from. By clicking 'Accept', you
                            consent to our use of cookies. You can read more about how we use cookies in our Cookie
                            Policy
                            <br/>
                            <a href="https://www.phraiser.io/cookie-policy">https://www.phraiser.io/cookie-policy</a>
                        </p>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: {xs: 'row', sm: 'column'}, // Horizontal on small screens, vertical on larger
                        justifyContent: 'space-between',
                        mt: {xs: 2, sm: 4}, // Add top margin on larger screens
                    }}>
                        <Button
                            component={Link}
                            variant="contained"
                            color="secondary"
                            size="large"
                            sx={{
                                borderRadius: 2,
                                height: '2vw', /* Fallback for browsers that don't support clamp() */
                                'min-height': 35,
                                'max-height': 50,
                                'width': 100,
                                'font-size': 13,
                                mr: {xs: 2, sm: 0} // Add right margin on small screens
                            }}
                            onClick={handleAcceptAll}
                        >
                            Accept
                        </Button>
                        <Button
                            component={Link}
                            variant="outlined"
                            color="secondary"
                            size="large"
                            sx={{
                                borderRadius: 2,
                                mt: {xs: 0, sm: 2}, // Add top margin on larger screens
                                height: '2vw', /* Fallback for browsers that don't support clamp() */
                                'min-height': 35,
                                'max-height': 50,
                                'width': 100,
                                'font-size': 13
                            }}
                            onClick={handleRejectAll}
                        >
                            Decline
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CookieBanner;
