import React from 'react';
import {Container, Typography} from '@mui/material';

const PrivacyPolicy = () => (
    <Container maxWidth="sm">
        <Typography variant="h3" component="h1" sx={{mt: 15}} gutterBottom>
            Privacy Policy
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
            Effective Date: May 22, 2023
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            1. Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
            Welcome to Phraiser.io ("we", "us", "our"). We respect your privacy and are committed to protecting your
            personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your
            information when you use our AI writing assistant application ("Application").
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            2. Data Collection
        </Typography>
        <Typography variant="body1" gutterBottom>
            The Application collects several types of information from and about its users:
        </Typography>

        <Typography variant="h5" gutterBottom>
            2.1 Personal Information
        </Typography>
        <Typography variant="body1" gutterBottom>
            This includes data that can be used to identify you personally, such as your name, email address, and other
            contact details. You may provide this data to us directly when you register or use the Application.
        </Typography>

        <Typography variant="h5" gutterBottom>
            2.2 Usage Information
        </Typography>
        <Typography variant="body1" gutterBottom>
            We collect information about your usage of the Application, including the queries you input into the
            Application and the output generated by it. However, this information is anonymized and does not identify
            you directly.
        </Typography>

        <Typography variant="h5" gutterBottom>
            2.3 Device and Log Information
        </Typography>
        <Typography variant="body1" gutterBottom>
            We collect technical data about the device you use to access our Application, including the internet
            protocol (IP) address, operating system, browser type, and other similar information.
        </Typography>

        <Typography variant="h5" gutterBottom>
            2.4 Third-Party Data
        </Typography>
        <Typography variant="body1" gutterBottom>
            We use the OpenAI GPT API to provide the AI writing assistance service and Google Analytics to understand
            our Application usage. These services may collect data about your usage, which is subject to their
            respective privacy policies.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            3. Data Use
        </Typography>
        <Typography variant="body1" gutterBottom>
            We use your data for the following purposes:
            <ul>
                <li>To provide and improve our Application</li>
                <li>To personalize your user experience</li>
                <li>To send periodic emails, if you have opted in for this</li>
                <li>To monitor usage and trends to improve Application functionality</li>
                <li>To detect, prevent, and address technical issues</li>
            </ul>
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            4. Data Sharing
        </Typography>
        <Typography variant="body1" gutterBottom>
            We do not sell, trade, or otherwise transfer your personal information to outside parties without your
            consent, except as outlined in this policy or when required by law. We may share anonymized usage data with
            third parties for analytics, research, and improvement of the Application.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            5. Data Protection
        </Typography>
        <Typography variant="body1" gutterBottom>
            We strive to use acceptable means to protect your personal information. However, no method of transmission
            over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            6. Third-Party Services
        </Typography>
        <Typography variant="body1" gutterBottom>
            The Application utilizes the OpenAI GPT API and Google Analytics. These services have their own privacy
            policies, which we recommend you review.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            7. Changes to the Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
            We may update this policy from time to time. Any changes will be posted on this page with an updated
            effective date.
        </Typography>

        <Typography variant="h5" component="h4" gutterBottom>
            8. Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
            For any queries regarding this Privacy Policy, please contact us at [your contact email].
        </Typography>
        <Typography variant="body1" gutterBottom>
            By using the Application, you consent to our collection and use of your personal information as described in
            this Privacy Policy.
        </Typography>
        <Typography variant="body1" gutterBottom>
            This policy is governed by the laws of the country in which our company is based.
        </Typography>
        <Typography variant="body1" gutterBottom>
            Note: This is a sample privacy policy and may not cover all legal requirements. It's always a good idea to
            consult with a lawyer when drafting a privacy policy.
        </Typography>
    </Container>
);

export default PrivacyPolicy;
