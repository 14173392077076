const case_study = {
    "id": -1,
    "title": "Enter your case study title here",
    "messages": [],
    "parameters": {
        "articleType": {"type": "Case Study", 'id': 'case_study'},
        "language": "English",
        "styles": ["Analytical", "Informative", "Expository"],
        "model": "gpt-3.5-turbo"
    },
    "points": {
        "1": {
            "id": "1.",
            "name": "Introduction",
            "content": "Introduce the situation or subject of the case study and provide context.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "2": {
            "id": "2.",
            "name": "Background",
            "content": "Describe the background information relevant to the case study, including any relevant historical or contextual details.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "3": {
            "id": "3.",
            "name": "Problem",
            "content": "Identify the main problem or issue being examined in the case study.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        },
        "4": {
            "id": "4.",
            "name": "Analysis",
            "content": "Analyze the key factors, challenges, or dynamics of the situation, and discuss how they contributed to the outcome.",
            "context": "",
            "min_words": 200,
            "max_words": 400,
            "subpoints": {}
        },
        "5": {
            "id": "5.",
            "name": "Solution or Lessons Learned",
            "content": "Describe the solution that was implemented (if applicable), or discuss the key lessons learned from the case study.",
            "context": "",
            "min_words": 100,
            "max_words": 200,
            "subpoints": {}
        },
        "6": {
            "id": "6.",
            "name": "Conclusion",
            "content": "Summarize the main points of the case study and emphasize the importance or relevance of the analysis.",
            "context": "",
            "min_words": 50,
            "max_words": 100,
            "subpoints": {}
        }
    }
}

export default case_study;