import React, {useEffect, useState} from "react";
import {Auth} from "@aws-amplify/auth";
import {Box, Button, Divider, Drawer, List, ListItem, Typography} from "@mui/material";
import {styled} from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LoginIcon from "@mui/icons-material/Login";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {Scrollbars} from "react-custom-scrollbars";
import {Link} from "react-router-dom";
import {ReactComponent as MyLogo} from "../phrAIser.io.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import UserPanel from '../user/Account';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useNavigate} from "react-router-dom";

const DrawerContainer = styled(Drawer)({
    display: "flex",
    flexDirection: "column",
});

const SideMenu = ({
                      articles,
                      onArticleSelect,
                      startNewArticle,
                      onDeleteArticle,
                      loggedIn,
                      setLoggedIn
                  }) => {
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [showUserPanel, setShowUserPanel] = useState(false);

    const navigate = useNavigate();


    const handleArticleButtonClick = async (articleId) => {
        setSelectedArticle(articleId); // Update selectedArticle state
        setDeleteClicked(false); // Reset deleteClicked state
        if (onArticleSelect) {
            onArticleSelect(articleId);
        }
    };


    const handleDeleteClick = (e) => {
        e.stopPropagation();
        setDeleteClicked(true);
    };

    const handleCheckClick = (e) => {
        e.stopPropagation();
        onDeleteArticle(selectedArticle);
        setDeleteClicked(false);
    };

    const handleCancelClick = (e) => {
        e.stopPropagation();
        setDeleteClicked(false);
    };

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            setLoggedIn(false);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const handleOpenUserPanel = () => {
        setShowUserPanel(true);
    };

    const handleCloseUserPanel = () => {
        setShowUserPanel(false);
    };

    const handleNewArticle = () => {
        setSelectedArticle(null);
        startNewArticle()
    };


    return (
        <DrawerContainer variant="permanent">
            <Dialog
                open={showUserPanel}
                onClose={handleCloseUserPanel}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <UserPanel/>
                </DialogContent>
            </Dialog>
            <Box sx={{pl: 7.5, pt: 1.5, mb: 1.5, width: 220}}>
                <Box component={Link}
                     to="/" className="clickable-logo">
                    <MyLogo className="my-logo"/>
                </Box>
            </Box>
            <Divider sx={{mb: 0}}/>
            <List sx={{pt: 0, pb: 0, mb: -1, mt: 0}}>
                {loggedIn && (
                    <ListItem sx={{color: '#3f3f3f', p: 1.5}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon/>}
                            sx={{width: 255, borderRadius: 2}}
                            onClick={() => handleNewArticle()}
                        >
                            New Article
                        </Button>
                    </ListItem>
                )}
                {!loggedIn && (
                    <ListItem sx={{color: '#3f3f3f', p: 1.5, pt: 50, pl: 6, width: 240, textAlign: 'center'}}>
                        <Typography variant="body1">
                            Sign in to access the history of your articles.
                        </Typography>
                    </ListItem>
                )}
            </List>

            <Scrollbars style={{
                maxHeight: 'calc(100% - 214px)',
                'padding-top': 0,
                mt: 0,
                pr: -2
            }}>
                <List>
                    {[...articles].reverse().map((article) => (
                        <ListItem key={article.id}
                                  sx={{color: '#424248', mb: -1, pl: 1.5, pt: 0, pb: 2}}>
                            <Button
                                variant="text"
                                color="inherit"
                                startIcon={<ArticleOutlinedIcon/>}
                                onClick={() => handleArticleButtonClick(article.id)}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "flex-start",
                                    position: 'relative',
                                    width: 270,
                                    pl: 2,
                                    textAlign: 'left', // Add this line to align text content to the left
                                    transition: 'background-color 100ms ease-in-out',
                                    backgroundColor: selectedArticle === article.id ? 'rgba(63, 63, 63, 0.08)' : 'transparent',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        width: '30%',
                                        height: '100%',
                                        pointerEvents: 'none',
                                    },
                                }}
                                title={article.title}
                            >


                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        paddingRight: selectedArticle === article.id ? '24px' : '0px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {article.title}
                                </Box>
                                {selectedArticle === article.id && !deleteClicked && (
                                    <DeleteOutlineIcon
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            width: 20,
                                            height: 20,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            '&:hover': {
                                                color: 'rgba(87,87,87,0.7)',
                                            },
                                        }}
                                        onClick={handleDeleteClick}
                                    />
                                )}
                                {selectedArticle === article.id && deleteClicked && (
                                    <>
                                        <CheckIcon
                                            sx={{
                                                position: 'absolute',
                                                width: 20,
                                                height: 20,
                                                right: 16,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                '&:hover': {
                                                    color: 'rgba(87,87,87,0.7)',
                                                },
                                            }}
                                            onClick={handleCheckClick}
                                        />
                                        <CloseOutlinedIcon
                                            sx={{
                                                position: 'absolute',
                                                width: 20,
                                                height: 20,
                                                right: 0,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                '&:hover': {
                                                    color: 'rgba(87,87,87,0.7)',
                                                },
                                            }}
                                            onClick={handleCancelClick}
                                        />
                                    </>
                                )}
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </Scrollbars>
            <Box sx={{position: "absolute", bottom: 0, width: "100%"}}>
                <Divider sx={{mb: -1}}/>
                <List>
                    {loggedIn ? (
                        <>
                            <ListItem sx={{color: "#3f3f3f", p: 1}}>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    startIcon={<AccountBoxIcon/>}
                                    fullWidth
                                    sx={{width: "100%", mb: -1, justifyContent: "flex-start", pl: 2}}
                                    onClick={handleOpenUserPanel} // Add the onClick handler
                                >
                                    My Account
                                </Button>
                            </ListItem>
                            <ListItem sx={{color: "#3f3f3f", p: 1}}>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    startIcon={<LogoutIcon/>}
                                    fullWidth
                                    sx={{width: "100%", justifyContent: "flex-start", pl: 2}}
                                    onClick={handleLogout}
                                >
                                    Log Out
                                </Button>
                            </ListItem>
                        </>
                    ) : (
                        <>
                            <ListItem sx={{color: "#3f3f3f", p: 1}}>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    size="large"
                                    startIcon={<LoginIcon/>}
                                    fullWidth
                                    sx={{width: "100%", justifyContent: "flex-start", pl: 2}}
                                    onClick={() => navigate("/login")}
                                >
                                    Log In
                                </Button>
                            </ListItem>
                            <ListItem sx={{color: "#3f3f3f", p: 1}}>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    size="large"
                                    startIcon={<PersonAddIcon/>}
                                    fullWidth
                                    sx={{width: "100%", justifyContent: "flex-start", pl: 2, mt: -1}}
                                    onClick={() => navigate("/signup")}
                                >
                                    Sign Up
                                </Button>
                            </ListItem>
                        </>
                    )}
                </List>
            </Box>
        </DrawerContainer>
    );
};

export default SideMenu;